// src/components/layout/header/AppNavIcons.jsx

import { Flex, Link, useColorMode } from '@chakra-ui/react';
import { faCalculator, faSun, faMoon, faChartLine, faBell, faBook } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { ThemedNavIcon } from '../../../theme/ThemedNavIcon';
import { TradingViewModal } from '../../data-display/TradingViewModal';
import { NotificationModal } from '../../data-display/NotificationModal';
import { BitcoinCalculatorAdvanced } from '../../data-display/BitcoinCalculatorAdvanced';
import BtcLexiconModal from '../../data-display/BtcLexicon';
import { useState, useEffect } from 'react';
// import { Link as RouterLink } from 'react-router-dom';

export const AppNavIcons = ({
  data,
  bitcoinPrice,
  glossaryData,
  lastMempoolBlockData,
  formatNumber
}) => {
  const { colorMode, toggleColorMode, setColorMode } = useColorMode();
  const [isTradingViewModalOpen, setIsTradingViewModalOpen] = useState(false);
  const [isBitcoinCalculatorAdvancedOpen, setIsBitcoinCalculatorAdvancedOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);
  const ENABLE_NOTIFICATION = false;

  const handleOpenTradingViewModal = () => setIsTradingViewModalOpen(true);
  const handleCloseTradingViewModal = () => setIsTradingViewModalOpen(false);
  const handleOpenBitcoinCalculatorAdvanced = () => setIsBitcoinCalculatorAdvancedOpen(true);
  const handleCloseBitcoinCalculatorAdvanced = () => setIsBitcoinCalculatorAdvancedOpen(false);
  const handleOpenNotificationModal = () => setIsNotificationModalOpen(true);
  const handleCloseNotificationModal = () => setIsNotificationModalOpen(false);
  const handleOpenGlossary = () => setIsGlossaryOpen(true);
  const handleCloseGlossary = () => setIsGlossaryOpen(false);

  const saveColorModeInLocalStorage = (mode) => {
    localStorage.setItem('colorMode', mode);
  }

  const toggleAndSaveColorMode = () => {
    toggleColorMode();
    saveColorModeInLocalStorage(colorMode === 'dark' ? 'light' : 'dark');
  }

  useEffect(() => {
    const storedColorMode = localStorage.getItem('colorMode');
    if (storedColorMode) {
      setColorMode(storedColorMode);
    } else {
      setColorMode('dark');
    }
  }, [setColorMode]);

  return (
    <Flex gap={{ base: 4, md: 6 }}>
      {ENABLE_NOTIFICATION && (
        <Link onClick={handleOpenNotificationModal}>
          <ThemedNavIcon icon={faBell} fontSize={{ base: "18px", md: "20px" }} color="orange" />
        </Link>
      )}

      <Link onClick={handleOpenTradingViewModal}>
        <ThemedNavIcon icon={faChartLine} fontSize={{ base: "18px", md: "20px" }} />
      </Link>

      <Link onClick={handleOpenBitcoinCalculatorAdvanced}>
        <ThemedNavIcon icon={faCalculator} fontSize={{ base: "18px", md: "20px" }} />
      </Link>

      <Link onClick={handleOpenGlossary}>
        <ThemedNavIcon icon={faBook} fontSize={{ base: "18px", md: "20px" }} />
      </Link>

      {/* <Link as={RouterLink} to="/arcade">
        <ThemedNavIcon icon={faGamepad} fontSize={{ base: "18px", md: "20px" }} />
      </Link>

      <Link href='https://timechaincalendar.com' target="_blank" display={{ base: "none", sm: "inline" }}>
        <ThemedNavIcon icon={faCalendarAlt} fontSize={{ base: "18px", md: "20px" }} />
      </Link> */}

      <Link href="https://twitter.com/TimechainStats" target="_blank" display={{ base: "none", sm: "inline" }}>
        <ThemedNavIcon icon={faTwitter} fontSize={{ base: "18px", md: "20px" }} />
      </Link>

      <Link onClick={toggleAndSaveColorMode}>
        <ThemedNavIcon
          icon={colorMode === 'dark' ? faSun : faMoon}
          fontSize={{ base: "18px", md: "20px" }}
        />
      </Link>

      <TradingViewModal isOpen={isTradingViewModalOpen} onClose={handleCloseTradingViewModal} />

      <BitcoinCalculatorAdvanced
        isOpen={isBitcoinCalculatorAdvancedOpen}
        onClose={handleCloseBitcoinCalculatorAdvanced}
        bitcoinPrice={bitcoinPrice}
        data={data}
        formatNumber={formatNumber}
      />

      <BtcLexiconModal
        isOpen={isGlossaryOpen}
        onClose={handleCloseGlossary}
        glossaryData={glossaryData}
      />

      <NotificationModal isOpen={isNotificationModalOpen} onClose={handleCloseNotificationModal} />

    </Flex>
  );
};

// import { Flex, Link, useColorMode, Menu, MenuButton, MenuList, MenuItem, Text } from '@chakra-ui/react';
// import { faCalculator, faSun, faMoon, faCalendarAlt, faChartLine, faBell, faGamepad, faBook, faDice, faHammer, faGraduationCap, faDigging,
//   faRoute
//  } from '@fortawesome/free-solid-svg-icons';
// import { faTwitter } from '@fortawesome/free-brands-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { ThemedNavIcon } from '../../../theme/ThemedNavIcon';
// import { TradingViewModal } from '../../data-display/TradingViewModal';
// import { NotificationModal } from '../../data-display/NotificationModal';
// import { BitcoinCalculatorAdvanced } from '../../data-display/BitcoinCalculatorAdvanced';
// import BitcoinPriceGame from '../../games/BitcoinPriceGame';
// import BitcoinAdoptionSimulation from '../../games/BitcoinAdoptionSimulation';
// import TriviaNight from '../../games/TriviaNight';
// import MinerTycoon from '../../games/MinerTycoon';
// import LightningMazeRunner from '../../games/RoutingMan';
// import { useState, useEffect } from 'react';
// import BtcLexiconModal from '../../data-display/BtcLexicon';

// export const AppNavIcons = ({
//   data,
//   bitcoinPrice,
//   glossaryData,
//   lastMempoolBlockData,
//   formatNumber
// }) => {
//   const { colorMode, toggleColorMode, setColorMode } = useColorMode();
//   const [isTradingViewModalOpen, setIsTradingViewModalOpen] = useState(false);
//   const [isBitcoinCalculatorAdvancedOpen, setIsBitcoinCalculatorAdvancedOpen] = useState(false);
//   const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
//   const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);
//   const [isBitcoinPriceGameOpen, setIsBitcoinPriceGameOpen] = useState(false);
//   const [isAdoptionSimulationOpen, setIsAdoptionSimulationOpen] = useState(false);
//   const [isTriviaNightOpen, setIsTriviaNightOpen] = useState(false);
//   const [isMinerTycoonOpen, setIsMinerTycoonOpen] = useState(false);
//   const [isLightningMazeRunnerOpen, setIsLightningMazeRunnerOpen] = useState(false);
//   const ENABLE_NOTIFICATION = false;

//   const handleOpenTradingViewModal = () => setIsTradingViewModalOpen(true);
//   const handleCloseTradingViewModal = () => setIsTradingViewModalOpen(false);
//   const handleOpenBitcoinCalculatorAdvanced = () => setIsBitcoinCalculatorAdvancedOpen(true);
//   const handleCloseBitcoinCalculatorAdvanced = () => setIsBitcoinCalculatorAdvancedOpen(false);
//   const handleOpenNotificationModal = () => setIsNotificationModalOpen(true);
//   const handleCloseNotificationModal = () => setIsNotificationModalOpen(false);
//   const handleOpenGlossary = () => setIsGlossaryOpen(true);
//   const handleCloseGlossary = () => setIsGlossaryOpen(false);
//   const handleOpenBitcoinPriceGame = () => setIsBitcoinPriceGameOpen(true);
//   const handleCloseBitcoinPriceGame = () => setIsBitcoinPriceGameOpen(false);
//   const handleOpenAdoptionSimulation = () => setIsAdoptionSimulationOpen(true);
//   const handleCloseAdoptionSimulation = () => setIsAdoptionSimulationOpen(false);
//   const handleOpenTriviaNight = () => setIsTriviaNightOpen(true);
//   const handleCloseTriviaNight = () => setIsTriviaNightOpen(false);
//   const handleOpenMinerTycoon = () => setIsMinerTycoonOpen(true);
//   const handleCloseMinerTycoon = () => setIsMinerTycoonOpen(false);
//   const handleOpenLightningMazeRunner = () => setIsLightningMazeRunnerOpen(true);
//   const handleCloseLightningMazeRunner = () => setIsLightningMazeRunnerOpen(false);

//   const saveColorModeInLocalStorage = (mode) => {
//     localStorage.setItem('colorMode', mode);
//   }

//   const toggleAndSaveColorMode = () => {
//     toggleColorMode();
//     saveColorModeInLocalStorage(colorMode === 'dark' ? 'light' : 'dark');
//   }

//   useEffect(() => {
//     const storedColorMode = localStorage.getItem('colorMode');
//     if (storedColorMode) {
//       setColorMode(storedColorMode);
//     } else {
//       setColorMode('dark');
//     }
//   }, [setColorMode]);

//   return (
//     <Flex gap={{ base: 4, md: 6 }}>
//       {ENABLE_NOTIFICATION && (
//         <Link onClick={handleOpenNotificationModal}>
//           <ThemedNavIcon icon={faBell} fontSize={{ base: "18px", md: "20px" }} color="orange" />
//         </Link>
//       )}

//       <Link onClick={handleOpenTradingViewModal}>
//         <ThemedNavIcon icon={faChartLine} fontSize={{ base: "18px", md: "20px" }} />
//       </Link>

//       <Link onClick={handleOpenBitcoinCalculatorAdvanced}>
//         <ThemedNavIcon icon={faCalculator} fontSize={{ base: "18px", md: "20px" }} />
//       </Link>

//       <Link onClick={handleOpenGlossary}>
//         <ThemedNavIcon icon={faBook} fontSize={{ base: "18px", md: "20px" }} />
//       </Link>

//       <Menu>
//         <MenuButton as={Link}>
//           <ThemedNavIcon icon={faGamepad} fontSize={{ base: "18px", md: "20px" }} />
//         </MenuButton>
//         <MenuList
//           bg="#121212"
//           border="1px solid white"
//           borderRadius="3px"
//           p={0}
//         >
//           <MenuItem
//             onClick={handleOpenBitcoinPriceGame}
//             color="white"
//             bg="#121212"
//             _hover={{ bg: "#777777" }}
//             _focus={{ bg: "transparent" }}
//             _active={{ bg: "#777777" }}
//             transition="background-color 0.2s"
//           >
//             <FontAwesomeIcon icon={faDice} style={{ marginRight: '10px' }} />
//             <Text>"Get Rekt" Simulator</Text>
//           </MenuItem>
//           <MenuItem
//             onClick={handleOpenAdoptionSimulation}
//             color="white"
//             bg="#121212"
//             _hover={{ bg: "#777777" }}
//             _focus={{ bg: "transparent" }}
//             _active={{ bg: "#777777" }}
//             transition="background-color 0.2s"
//           >
//             <FontAwesomeIcon icon={faHammer} style={{ marginRight: '10px' }} />
//             <Text>BanHammer!</Text>
//           </MenuItem>
//           <MenuItem
//             onClick={handleOpenTriviaNight}
//             color="white"
//             bg="#121212"
//             _hover={{ bg: "#777777" }}
//             _focus={{ bg: "transparent" }}
//             _active={{ bg: "#777777" }}
//             transition="background-color 0.2s"
//           >
//             <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '10px' }} />
//             <Text>Trivia Night</Text>
//           </MenuItem>
//           <MenuItem
//             onClick={handleOpenMinerTycoon}
//             color="white"
//             bg="#121212"
//             _hover={{ bg: "#777777" }}
//             _focus={{ bg: "transparent" }}
//             _active={{ bg: "#777777" }}
//             transition="background-color 0.2s"
//           >
//             <FontAwesomeIcon icon={faDigging} style={{ marginRight: '10px' }} />
//             <Text>Mining Tycoon</Text>
//           </MenuItem>
//           <MenuItem
//             onClick={handleOpenLightningMazeRunner}
//             color="white"
//             bg="#121212"
//             _hover={{ bg: "#777777" }}
//             _focus={{ bg: "transparent" }}
//             _active={{ bg: "#777777" }}
//             transition="background-color 0.2s"
//           >
//             <FontAwesomeIcon icon={faRoute} style={{ marginRight: '10px' }} />
//             <Text>Node Runner</Text>
//           </MenuItem>
//         </MenuList>
//       </Menu>

//       <Link href='https://timechaincalendar.com' target="_blank" display={{ base: "none", sm: "inline" }}>
//         <ThemedNavIcon icon={faCalendarAlt} fontSize={{ base: "18px", md: "20px" }} />
//       </Link>

//       <Link href="https://twitter.com/TimechainStats" target="_blank" display={{ base: "none", sm: "inline" }}>
//         <ThemedNavIcon icon={faTwitter} fontSize={{ base: "18px", md: "20px" }} />
//       </Link>

//       <Link onClick={toggleAndSaveColorMode}>
//         <ThemedNavIcon
//           icon={colorMode === 'dark' ? faSun : faMoon}
//           fontSize={{ base: "18px", md: "20px" }}
//         />
//       </Link>

//       <TradingViewModal isOpen={isTradingViewModalOpen} onClose={handleCloseTradingViewModal} />

//       <BitcoinCalculatorAdvanced
//         isOpen={isBitcoinCalculatorAdvancedOpen}
//         onClose={handleCloseBitcoinCalculatorAdvanced}
//         bitcoinPrice={bitcoinPrice}
//         data={data}
//         formatNumber={formatNumber}
//       />

//       <BtcLexiconModal
//         isOpen={isGlossaryOpen}
//         onClose={handleCloseGlossary}
//         glossaryData={glossaryData}
//       />

//       <NotificationModal isOpen={isNotificationModalOpen} onClose={handleCloseNotificationModal} />

//       <BitcoinPriceGame
//         isOpen={isBitcoinPriceGameOpen}
//         onClose={handleCloseBitcoinPriceGame}
//         bitcoinPrice={bitcoinPrice}
//         lastMempoolBlockData={lastMempoolBlockData}
//       />

//       <BitcoinAdoptionSimulation
//         isOpen={isAdoptionSimulationOpen}
//         onClose={handleCloseAdoptionSimulation}
//       />

//       <TriviaNight
//         isOpen={isTriviaNightOpen}
//         onClose={handleCloseTriviaNight}
//         glossaryData={glossaryData}
//       />

//       <MinerTycoon
//         isOpen={isMinerTycoonOpen}
//         onClose={handleCloseMinerTycoon}
//       />

//       <LightningMazeRunner
//         isOpen={isLightningMazeRunnerOpen}
//         onClose={handleCloseLightningMazeRunner}
//       />

//     </Flex>
//   );
// };