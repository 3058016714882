// src/components/layout/content/AppContent.jsx
import { useState, useEffect, useRef, useCallback } from 'react';
import {
    Box,
    Text,
    Flex,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBasketShopping, faBitcoinSign, faBook, faCalendarAlt, faChartLine, faCircleQuestion, faDatabase, faDollar, faEye, faGem, faGlobeAsia, faKey, faLock, faPercent, faPieChart, faQuestionCircle, faRightLeft, faScissors, faSliders, faUpRightFromSquare, faUserSecret, faWrench, faTimeline, faPlay, faPenFancy, faCoins, faMoneyBill, faHistory, faHandFist, faCode, faCodePullRequest, faMoneyBills, faLegal, faDiceD20, faFireAlt, faGraduationCap, faSmile, faComputer, faMicrochip, faBarChart, faCube, faExternalLinkAlt, faChartPie, faGears, faMedal, faBoltLightning,
    faFastForward, faChartBar, faClock
} from '@fortawesome/free-solid-svg-icons'
import {
    AvgFeesOverTimeTile, ChartTile, DataTable, DifficultyAdjustmentTile, ImageTile, MarketCapTile, ProgressTile, QuoteTile, WordTile, VolumeTile, HashRateTile, PoolsTopTenTile, PoolsTopTenPieTile, RewardsToFeesTile, RewardsToFeesBarTile, AvgRewardsOverTimeTile, LightningNodesTile, LightningNodesByCountryTile, LightningCapacityByCountryTile, PoolsTopTenHashPieTile, TransactionsTile, TransactionsAndHashRateTile, HashRatePriceTile, HashRateMempoolSizeTile, DataTileTargetBlockTime, DataTileRealBlockTime, DataTileCirculatingSupply, DataTileMaximumSupply, DataTilePercentMined, DataTileLeftToMine, BitcoinBlocksChart, BitcoinTxVsTotalFeesChart
} from '../../data-display'
import { DataBanner } from '../../features/data-banner'
import { ContentWrapper } from './ContentWrapper'
import { Navbar } from '../header/Navbar'
import { faBtc } from '@fortawesome/free-brands-svg-icons';
import { ResourceTileWhitepaper } from '../../data-display/ResourceTileWhitepaper';
import { ResourceTileShellingOut } from '../../data-display/ResourceTileShellingOut';
import { ResourceTileDevGuides } from '../../data-display/ResourceTileDevGuides';
import { ResourceTileCypherpunkManifesto } from '../../data-display/ResourceTileCypherpunkManifesto';
import { ResourceTileBips } from '../../data-display/ResourceTileBips';
import { ResourceTileWiki } from '../../data-display/ResourceTileWiki';
import { MarketTileExchangeFlowIn } from '../../data-display/MarketTileExchangeFlowIn';
import { MarketTileExchangeFlowOut } from '../../data-display/MarketTileExchangeFlowOut';
import { MarketTileAllTimeHigh } from '../../data-display/MarketTileAllTimeHigh';
import { MarketTileAllTimeLow } from '../../data-display/MarketTileAllTimeLow';
import { MarketTileAthPercentage } from '../../data-display/MarketTileAthPercentage';
import { MarketTileBtcToGold } from '../../data-display/MarketTileBtcToGold';
import { MiningTileHashRate } from '../../data-display/MiningTileHashRate';
import { MiningTileDifficulty } from '../../data-display/MiningTileDifficulty';
import { MiningTileRewardBtc } from '../../data-display/MiningTileRewardBtc';
import { MiningTileRewardFees } from '../../data-display/MiningTileRewardUsd';
import { MiningTileMempoolTxs } from '../../data-display/MiningTileMempoolTxs';
import { MiningTileMempoolSize } from '../../data-display/MiningTileMempoolSize';
import { NetworkTileTimechainReachable } from '../../data-display/NetworkTileTimechainReachable';
import { NetworkTileAge } from '../../data-display/NetworkTileAge';
import { NetworkTileAvgUtxoAge } from '../../data-display/NetworkTileAvgUtxoAge';
import { NetworkTileBlocks24h } from '../../data-display/NetworkTileBlocks24h';
import { NetworkTileBtcPerCountry } from '../../data-display/NetworkTileBtcPerCountry';
import { BitcoinDivider } from '../../decorations/BitcoinDivider';
import { ChartTileTransactions24h } from '../../data-display/ChartTileTransactions24h';
import { CustomSpinner } from '../../data-display/CustomSpinner';
import { RecentBlocks } from '../../data-display/RecentBlocks';
import {
    formatNumber,
    formatLargeNumber,
    truncateMiddle,
    daysSince,
    daysLeft,
    // secondsLeft,
    formatDate,
} from '../../../utils';
import { TradingViewChart2 } from '../../data-display/TradingViewChart2';
import { createCurrencyConverter, currencySymbols } from '../../../utils';
import { useRecoilValue } from 'recoil';
import { selectedCurrencyState } from '../../../recoilState';
import BtcLexiconModal from '../../data-display/BtcLexicon';
// import NotificationManager from './NotificationManager';
// import NotificationSystem from './NotificationSystem';

// import { TcsAlert } from './TcsAlert';

const flagAR = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇦🇷</span>; // Argentina
const flagAU = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇦🇺</span>; // Australia
const flagBR = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇧🇷</span>; // Brazil
const flagCA = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇨🇦</span>; // Canada
const flagCH = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇨🇭</span>; // Switzerland
const flagCZ = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇨🇿</span>; // Czech Republic
const flagDK = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇩🇰</span>; // Denmark
const flagEU = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇪🇺</span>; // European Union
const flagIN = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇮🇳</span>; // India
const flagJP = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇯🇵</span>; // Japan
const flagKR = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇰🇷</span>; // South Korea
const flagMX = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇲🇽</span>; // Mexico
const flagNO = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇳🇴</span>; // Norway
const flagNZ = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇳🇿</span>; // New Zealand
const flagPH = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇵🇭</span>; // Philippines
const flagPL = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇵🇱</span>; // Poland
const flagSE = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇸🇪</span>; // Sweden
const flagSG = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇸🇬</span>; // Singapore
const flagUS = <span style={{ fontSize: '22px', marginRight: '6px' }}>🇺🇸</span>; // USA

const calculateBlocksToClear = (mempoolInfo) => {
    const BLOCK_SIZE_MB = 4; // 4MB max block size
    const mempoolMB = mempoolInfo.usage / 1000000; // Convert bytes to MB
    const estimatedBlocks = mempoolMB / BLOCK_SIZE_MB;
    
    return {
        blocks: estimatedBlocks,
        displayValue: `~${formatNumber(estimatedBlocks, 0)} Blocks`
    };
 };
export const AppContent = ({
    glossaryData,
    data, bitcoinPrice, lastMempoolBlockData, fees, mempoolInfo, da, blocks }) => {


    const [isLexiconOpen, setIsLexiconOpen] = useState(false);
    const [selectedLexiconTerm, setSelectedLexiconTerm] = useState(null);

    const handleOpenLexicon = (term = null) => {
        if (term && typeof term === 'string') {
            setSelectedLexiconTerm(term);
            setIsLexiconOpen(true);
        } else {
            console.warn('Invalid term provided to handleOpenLexicon:', term);
            setSelectedLexiconTerm(null);
            setIsLexiconOpen(true);
        }
    };

    const selectedCurrency = useRecoilValue(selectedCurrencyState);
    const [conversionRates, setConversionRates] = useState(null);
    useEffect(() => {
        if (data) {
            const rates = createCurrencyConverter(data);
            setConversionRates(rates);
        }
    }, [data, bitcoinPrice]);

    const calculateCurrentPopulation = () => {
        // More accurate growth rate from UN data
        const growthRate = 0.0109; // 1.09% annual growth
        const basePopulation = 8045311447; // UN estimate as of Jan 1, 2024
        const baseDate = new Date("2024-01-01");
        
        const now = new Date();
        const yearFraction = (now - baseDate) / (365.25 * 24 * 60 * 60 * 1000);
        
        return Math.round(basePopulation * Math.pow(1 + growthRate, yearFraction));
    };

    const [filter, setFilter] = useState("All");
    const [animateLastBlock, setAnimateLastBlock] = useState(false);
    const [dataTableTitle, setDataTableTitle] = useState(
        lastMempoolBlockData ? "Latest Block" : "Last Block"
    );

    const prevBlockHeightRef = useRef(null);
    const isFirstRender = useRef(true);

    const formatTimeRemaining = (seconds) => {
        const days = Math.floor(seconds / (3600 * 24));
        const hours = Math.floor((seconds % (3600 * 24)) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        const parts = [];
        if (days > 0) parts.push(`${days}d`);
        if (hours > 0) parts.push(`${hours}h`);
        if (minutes > 0) parts.push(`${minutes}m`);
        if (remainingSeconds > 0) parts.push(`${remainingSeconds}s`);

        return parts.join(' ');
    };


    const animateBorders = useCallback(() => {

        setAnimateLastBlock(true);

        setDataTableTitle(`Block ${formatNumber(lastMempoolBlockData.height, 0)} Found!`);


        setTimeout(() => {

            setAnimateLastBlock(false);

            setDataTableTitle("Last Block");
        }, 20000);
    }, [lastMempoolBlockData]);

    useEffect(() => {
        if (lastMempoolBlockData && (!prevBlockHeightRef.current || prevBlockHeightRef.current < lastMempoolBlockData.height)) {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                prevBlockHeightRef.current = lastMempoolBlockData.height;
            } else {
                animateBorders();
                prevBlockHeightRef.current = lastMempoolBlockData.height;
            }
        }
    }, [lastMempoolBlockData, animateBorders]);


    const currencies1Data = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagAR}<span> Argentine Peso</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['ars']} ${formatNumber(bitcoinPrice * conversionRates.ars, 0)}` : 'Loading...', // Add 1.96 - Check https://coinmonitor.info/ - Street rate is different than official rate
                tooltipText: "The price of Bitcoin in Argentine Peso",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagAU}<span> Australian Dollar</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['aud']} ${formatNumber(bitcoinPrice * conversionRates.aud, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Australian Dollar",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagBR} <span>Brazilian Real</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['brl']} ${formatNumber(bitcoinPrice * conversionRates.brl, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Brazilian Real",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagCA} <span>Canadian Dollar</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['cad']} ${formatNumber(bitcoinPrice * conversionRates.cad, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Canadian Dollar",
            },
        ]
        : [];

    const currencies2Data = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagCZ} <span>Czech Koruna</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['czk']} ${formatNumber(bitcoinPrice * conversionRates.czk, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Czech Koruna",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagDK} <span>Danish Krone</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['dkk']} ${formatNumber(bitcoinPrice * conversionRates.dkk, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Danish Krone",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagEU} <span>Euro</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['eur']} ${formatNumber(bitcoinPrice * conversionRates.eur, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Euro",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagIN} <span>Indian Rupee</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['inr']} ${formatNumber(bitcoinPrice * conversionRates.inr, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Indian Rupee",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagJP} <span>Japanese Yen</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['jpy']} ${formatNumber(bitcoinPrice * conversionRates.jpy, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Japanese Yen",
            },
        ]
        : [];

    const currencies3Data = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagKR} <span>Korean Won</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['krw']} ${formatNumber(bitcoinPrice * conversionRates.krw, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Korean Won",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagMX} <span>Mexican Peso</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['mxn']} ${formatNumber(bitcoinPrice * conversionRates.mxn, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Mexican Peso",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagNZ} <span>New Zealand Dollar</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['nzd']} ${formatNumber(bitcoinPrice * conversionRates.nzd, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in New Zealand Dollar",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagNO} <span>Norwegian Krone</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['nok']} ${formatNumber(bitcoinPrice * conversionRates.nok, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Norwegian Krone",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagPH} <span>Philippine Peso</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['php']} ${formatNumber(bitcoinPrice * conversionRates.php, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Philippine Peso",
            },
        ]
        : [];

    const currencies4Data = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagPL} <span>Polish Złoty</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['pln']} ${formatNumber(bitcoinPrice * conversionRates.pln, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Polish Złoty",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagSG} <span>Singapore Dollar</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['sgd']} ${formatNumber(bitcoinPrice * conversionRates.sgd, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Singapore Dollar",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagSE} <span>Swedish Krona</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['sek']} ${formatNumber(bitcoinPrice * conversionRates.sek, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Swedish Krona",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagCH} <span>Swiss Franc</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['chf']} ${formatNumber(bitcoinPrice * conversionRates.chf, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in Swiss Franc",
            },
            {
                label: <div style={{ display: 'flex', alignItems: 'center' }}>{flagUS} <span>US Dollar</span></div>,
                value: data && bitcoinPrice ? `${currencySymbols['usd']} ${formatNumber(bitcoinPrice, 0)}` : 'Loading...',
                tooltipText: "The price of Bitcoin in US Dollar",
            }
        ]
        : [];

    const lastBlockTableData = data && bitcoinPrice && conversionRates && selectedCurrency && lastMempoolBlockData
        ? [
            {
                label: lastMempoolBlockData ? 'Block Height' : 'Loading...',
                value: lastMempoolBlockData
                    ? `Block ${formatNumber(lastMempoolBlockData.height, 0)}`
                    : 'Loading...',
                tooltipText: "The latest block added to the Bitcoin blockchain, which contains a collection of confirmed transactions.",
                clickableTerm: "Block Height",
            },
            {
                label: lastMempoolBlockData ? 'Timestamp' : 'Loading...',
                value: lastMempoolBlockData
                    ? formatDate(lastMempoolBlockData.timestamp)
                    : 'Loading...',
                tooltipText: "The date and time when the latest block was mined and added to the blockchain.",
                clickableTerm: "Timestamp",
            },

            {
                label: lastMempoolBlockData ? 'Hash' : 'Loading...',
                value: lastMempoolBlockData
                    ? truncateMiddle(lastMempoolBlockData.id, 8)
                    : 'Loading...',
                tooltipText: lastMempoolBlockData ? `The hash is a unique identifier generated by applying a cryptographic hash function to the contents of the block. This hash serves as a fingerprint for the block, ensuring its integrity and authenticity within the blockchain. If any data within the block were to be altered, the hash would change, making it easy to detect any tampering. The hash for this block is ${lastMempoolBlockData.id}` : "Loading...",
                clickableTerm: "Hash",
            },
            {
                label: lastMempoolBlockData ? 'Mining Pool' : 'Loading...',
                value: lastMempoolBlockData
                    ? lastMempoolBlockData.extras.pool.name
                    : 'Loading...',
                tooltipText: "The mining pool that successfully mined the latest block and received the block reward.",
                clickableTerm: "Mining Pool",
            },
            {
                label: lastMempoolBlockData ? 'Block Reward' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${formatNumber(lastMempoolBlockData.extras.reward / 100000000, 2)} BTC`
                    : 'Loading...',
                tooltipText: "The total reward, in satoshis, mined in the latest block, including the subsidy and all fees.",
                clickableTerm: "Block Reward",
            },
            {
                label: lastMempoolBlockData ? 'Total Subsidy' : 'Loading...',
                value: lastMempoolBlockData
                    ? `3.125 BTC`
                    : 'Loading...',
                tooltipText: "The total subsidy, in satoshis, earned in the latest block.",
                clickableTerm: "Subsidy",
            },
            {
                label: lastMempoolBlockData ? 'Total Fees' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${formatNumber(lastMempoolBlockData.extras.totalFees / 100000000, 8)} BTC`
                    : 'Loading...',
                tooltipText: "The total fees, in satoshis, paid in the latest block.",
                clickableTerm: "Transaction Fee",
            },

            {
                label: lastMempoolBlockData ? 'Avg Fee Rate' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${formatNumber(lastMempoolBlockData.extras.avgFeeRate, 0)} Sats/vByte`
                    : 'Loading...',
                tooltipText: "The average fee rate, in satoshis per virtual byte (vByte), for transactions included in the latest block.",
                clickableTerm: "Average Fee Rate",
            },
            {
                label: lastMempoolBlockData ? 'Highest Fee Rate' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${formatNumber(lastMempoolBlockData.extras.feeRange[6], 0)} Sats/vByte`
                    : 'Loading...',
                tooltipText: "The fee range, in satoshis/vByte, for transactions included in the latest block (lowest - highest).",
                clickableTerm: "Highest Fee Rate",
            },
            {
                label: lastMempoolBlockData ? 'Lowest Fee Rate' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${formatNumber(lastMempoolBlockData.extras.feeRange[0], 0)} Sats/vByte`
                    : 'Loading...',
                tooltipText: "The fee range, in satoshis/vByte, for transactions included in the latest block (lowest - highest).",
                clickableTerm: "Lowest Fee Rate",
            },
            {
                label: lastMempoolBlockData ? 'Avg Fee' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${formatNumber(lastMempoolBlockData.extras.avgFee / 100000000, 8)} Sats`
                    : 'Loading...',
                tooltipText: "The average fee, in satoshis, for transactions included in the latest block.",
                clickableTerm: "Average Fee",
            },
            {
                label: lastMempoolBlockData ? 'Transaction Count' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${formatNumber(lastMempoolBlockData.tx_count, 0)} TXs`
                    : 'Loading...',
                tooltipText: "The total number of transactions included in the latest block.",
                clickableTerm: "Transaction Count",
            },
            {
                label: lastMempoolBlockData ? 'Bits' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${lastMempoolBlockData.bits}`
                    : 'Loading...',
                tooltipText: lastMempoolBlockData ? `The "bits" value is a compact representation of the target difficulty for mining a block. It is used to calculate the current mining difficulty of the network and is included in each block header.` : "Loading...",
                // clickableTerm: "Bits",
            },
            {
                label: lastMempoolBlockData ? 'Nonce' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${lastMempoolBlockData.nonce}`
                    : 'Loading...',
                tooltipText: lastMempoolBlockData ? `What was the nonce of the block?` : "Loading...",
                clickableTerm: "Nonce",
            },
            {
                label: lastMempoolBlockData ? 'Difficulty' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${formatLargeNumber(lastMempoolBlockData.difficulty, 2, true)}`
                    : 'Loading...',
                tooltipText: lastMempoolBlockData ? `How large was the mining difficulty of the last block?` : "Loading...",
                clickableTerm: "Difficulty",
            },
            {
                label: lastMempoolBlockData ? 'Version' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${lastMempoolBlockData.version}`
                    : 'Loading...',
                tooltipText: lastMempoolBlockData ? `What version was the block?` : "Loading...",
                // clickableTerm: "Version",
            },
            // {
            //     label: 'Block Weight',
            //     value: lastMempoolBlockData ? formatNumber(lastMempoolBlockData.weight, 0) : "Loading...",
            //     tooltipText: "The weight of the most recent block in weight units.",
            //     clickableTerm: "Block Weight",
            // },
            {
                label: lastMempoolBlockData ? 'Stale Block?' : 'Loading...',
                value: lastMempoolBlockData
                    ? `${lastMempoolBlockData.stale}`
                    : 'Loading...',
                tooltipText: lastMempoolBlockData ? `Was the block stale?` : "Loading...",
                clickableTerm: "Stale Block",
            },
            {
                label: lastMempoolBlockData ? 'Empty Block?' : 'Loading...',
                value: lastMempoolBlockData
                    ? (lastMempoolBlockData.tx_count === 1 ? 'true' : 'false')
                    : 'Loading...',
                tooltipText: lastMempoolBlockData ? `Was the block empty?` : "Loading...",
                clickableTerm: "Empty Block",
            },

        ]
        : [];

    const whatIsASatWorthData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: '1 Satoshi',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00000001, 6)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one Satoshi buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
                clickableTerm: "Satoshi",
            },
            {
                label: '10 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00000010, 5)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can ten Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
                clickableTerm: "Satoshi",
            },
            {
                label: '100 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00000100, 3)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one hundred Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
                clickableTerm: "Satoshi",
            },
            {
                label: '1,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00001000, 3)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one thousand Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
                clickableTerm: "Satoshi",
            },
            {
                label: '10,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00010000, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can ten thousand Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
                clickableTerm: "Satoshi",
            },
            {
                label: '100,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.00100000, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one hundred thousand Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
                clickableTerm: "Satoshi",
            },
            {
                label: '1,000,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.01000000, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one million Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
                clickableTerm: "Satoshi",
            },
            {
                label: '10,000,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 0.10000000, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can ten million Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
                clickableTerm: "Satoshi",
            },
            {
                label: '100,000,000 Satoshis',
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency]) * 1.0000000, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "How many dollars can one hundred million Satoshis buy? (A Satoshi, or 'Sat', is the smallest unit of Bitcoin. Each BTC can be divided into 100,000,000 units, or Satoshis... aka 'Sats'.)",
                clickableTerm: "Satoshi",
            },
        ]
        : [];

    const whatIsAUsdWorthData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: data && bitcoinPrice ? `1 ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((1 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 1.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
                clickableTerm: "Fiat Currency",
            },
            {
                label: data && bitcoinPrice ? `10 ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((10 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 10.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
                clickableTerm: "Fiat Currency",
            },
            {
                label: data && bitcoinPrice ? `100 ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((100 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 100.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
                clickableTerm: "Fiat Currency",
            },
            {
                label: data && bitcoinPrice ? `1k ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((1000 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 1,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
                clickableTerm: "Fiat Currency",
            },
            {
                label: data && bitcoinPrice ? `10k ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((10000 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 10,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
                clickableTerm: "Fiat Currency",
            },
            {
                label: data && bitcoinPrice ? `100k ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((100000 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 100,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
                clickableTerm: "Fiat Currency",
            },
            {
                label: data && bitcoinPrice ? `1M ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((1000000 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 1,000,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
                clickableTerm: "Fiat Currency",
            },
            {
                label: data && bitcoinPrice ? `10M ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((10000000 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 10,000,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
                clickableTerm: "Fiat Currency",
            },
            {
                label: data && bitcoinPrice ? `100M ${selectedCurrency.toUpperCase()} buys...` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber((100000000 / (bitcoinPrice * conversionRates[selectedCurrency])), 8)} BTC` : "Loading...",
                tooltipText: data && bitcoinPrice ? `How much Bitcoin does 100,000,000.00 ${selectedCurrency.toUpperCase()} buy?` : "Loading...",
                clickableTerm: "Fiat Currency",
            },
        ]
        : [];

    const bitcoinBreakdownData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: '21M BTC ÷ Fortune 500',
                value: `~${formatNumber(21000000 / 500, 0)} BTC/Company`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Number of Companies in the Fortune 500 (500), assuming the Fortune 500 are the only bitcoin holders for this exercise",
                clickableTerm: "BTC Per Fortune 500 Company",
            },
            {
                label: '21M BTC ÷ NYSE',
                value: `~${formatNumber(21000000 / 2272, 0)} BTC/Company`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Number of Companies traded on the New York Stock Exchange (2,385 in 2023), assuming the NYSE companies are the only bitcoin holders for this exercise",
                clickableTerm: "BTC Per NYSE Company",
            },
            {
                label: '21M BTC ÷ NYC',
                value: `~${formatNumber(21000000 / 8097282, 6)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of New York (8.468M in 2023), assuming the City of New York are the only bitcoin holders for this exercise",
                clickableTerm: "BTC Per Person",
            },
            {
                label: '21M BTC ÷ USA',
                value: `~${formatNumber(21000000 / 345426571, 6)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of USA (331.9M in 2023), assuming the USA population are the only bitcoin holders for this exercise",
                clickableTerm: "BTC Per Person",
            },
            {
                label: '21M BTC ÷ Europe',
                value: `~${formatNumber(21000000 / 744980559, 6)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of Europe (746.4M in 2023), assuming the European population are the only bitcoin holders for this exercise",
                clickableTerm: "BTC Per Person",
            },
            {
                label: '21M BTC ÷ Japan',
                value: `~${formatNumber(21000000 / 122631432, 6)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of Japan (125.7M in 2023), assuming the Japanese population are the only bitcoin holders for this exercise",
                clickableTerm: "BTC Per Person",
            },
            {
                label: '21M BTC ÷ Country',
                value: `~${formatNumber(21000000 / 195, 0)} BTC/Country`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Number of Countries (195)",
                clickableTerm: "21M Divided By Everything",
            },
            {
                label: '21M BTC ÷ Continent',
                value: `~${formatLargeNumber(21000000 / 7, 0, false)} BTC/Continent`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Number of Continents (7)",
                clickableTerm: "21M Divided By Everything",
            },
            {
                label: '21M BTC ÷ Solar System',
                value: `~${formatLargeNumber(21000000 / 8, 0, false)} BTC/Planet`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Number of Planets in our Solar System (8)",
                clickableTerm: "21M Divided By Everything",
            }
        ]
        : [];

    const bitcoinNetworkData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Timechain Explorer',
                value: 'https://mempool.space/',
                displayValue: 'Mempool.space',
                type: 'link',
                tooltipText: "To provide some basic terms, a block explorer is a timechain search engine that allows you to search for a particular piece of information on the timechain. The activities carried out on bitcoin's timechain are known as transactions, which occur when sats are sent to and from wallet addresses.",
                clickableTerm: "Block Explorer",
            },
            {
                label: 'New Blocks (24h)',
                value: data && data.s12 && data.s12.metrics && data.s12.metrics.data && data.s12.metrics.data.blockchain_stats_24_hours
                    ? `${formatNumber(data.s12.metrics.data.blockchain_stats_24_hours.count_of_blocks_added)} Blocks`
                    : "Loading...",
                tooltipText: "The count of new blocks added to the timechain in the last 24 hours.",
                // highlighted: true,
                clickableTerm: "Block",
            },
            {
                label: 'Active Addresses (24h)',
                value: data && data.s12 && data.s12.metrics && data.s12.metrics.data
                    ? formatNumber(data.s12.metrics.data.on_chain_data.active_addresses, 0)
                    : "Loading...",
                tooltipText: "Number of unique addresses that were active in the network (either as a sender or receiver) in the last 24 hours",
                clickableTerm: "Active Address",
            },
            {
                label: 'New Addresses (24h)',
                value: data && data.s12 && data.s12.metrics && data.s12.metrics.data
                    ? formatNumber(data.s12.metrics.data.on_chain_data.addresses_count, 0)
                    : "Loading...",
                tooltipText: "Number of new addresses created in the last 24 hours",
                clickableTerm: "New Address",
            },
            {
                label: 'UTXO Count',
                value: data && data.s12 && data.s12.metrics && data.s12.metrics.data
                    ? `${formatLargeNumber(data.s12.metrics.data.on_chain_data.utxo_count_last_24_hours, 2, false)} UTXOs`
                    : "Loading...",
                tooltipText: "The total number of valid unspent transaction outputs (UTXOs).",
                clickableTerm: "UTXO Count",
            },
            {
                label: 'Avg UTXO Age',
                value: data && data.s12 && data.s12.metrics && data.s12.metrics.data
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_utxo_age / 365, 1)}y (${formatNumber(data.s12.metrics.data.on_chain_data.average_utxo_age, 0)}d)`
                    : "Loading...",
                tooltipText: "The average age of a UTXO since its creation, in days.",
                clickableTerm: "UTXO",
            },

            {
                label: 'Timechain Reachable?',
                value: 'TRUE',
                tooltipText: "Checks the Bitcoin timechain uptime. Timechain is a distributed database existing on multiple computers at the same time. It is constantly growing as new sets of recordings, or 'blocks', are added to it. Each block contains a timestamp and a link to the previous block, so they actually form a chain. Satoshi's whitepaper never mentioned 'blockchain,' however. Satoshi called it 'Timechain.'",
                clickableTerm: "Timechain",
            },
            {
                label: 'Miners Active?',
                value: 'TRUE',
                tooltipText: "Bitcoin mining is the process of creating new bitcoin by solving a computational puzzle. Bitcoin mining is necessary to maintain the ledger of transactions upon which bitcoin is based. Miners have become very sophisticated over the last several years using complex machinery to speed up mining operations.",
                clickableTerm: "Mining",
            },
            {
                label: 'Network Age',
                value: `${formatNumber(daysSince("2009-01-03"))} Days`,
                tooltipText: "The count of days the Bitcoin blockchain has been online",
                clickableTerm: "Network",
            },
            {
                label: 'Nodes Found',
                value: data && data.s01 && data.s01.latest_snapshot
                    ? `${formatNumber(data.s01.latest_snapshot.total_nodes, 0)} Nodes`
                    : "Loading...",
                tooltipText: "In the Bitcoin network, nodes fulfill a very important role. A node is a computer connected to other computers which follows rules and shares information. A 'full node' is a computer in Bitcoin's peer-to-peer network which hosts and synchronises a copy of the entire Bitcoin timechain.",
                clickableTerm: "Node",
            },

            {
                label: 'Lightning Channels',
                value: data && data.s11 && data.s11.lightning && data.s11.lightning.latest
                    ? `${formatNumber(data.s11.lightning.latest.channel_count, 0)} Channels`
                    : "Loading...",
                tooltipText: "Number of Lightning channels found on the Lightning network.",
                clickableTerm: "Lightning Channel",
            },
            {
                label: 'Mempool Transactions',
                value: mempoolInfo ? formatNumber(mempoolInfo.size, 0) : "Loading...",
                tooltipText: "The current number of unconfirmed transactions in the mempool.",
                clickableTerm: "Mempool Transaction",
            },
            {
                label: 'Mempool Size',
                value: mempoolInfo ? `${formatNumber(mempoolInfo.usage / 1000000, 2)} MB / ${formatNumber(mempoolInfo.maxmempool / 1000000, 0)} MB` : "Loading...",
                tooltipText: "The current size of the mempool in megabytes.",
                clickableTerm: "Mempool Size",
            },
            {
                label: 'Mempool Usage',
                value: mempoolInfo ? `${formatNumber((mempoolInfo.usage / mempoolInfo.maxmempool) * 100, 2)}%` : "Loading...",
                tooltipText: "The current mempool usage as a percentage of the maximum allowed size.",
                // clickableTerm: "Mempool Usage Per",
            },
            // {
            //     label: 'Unbroadcasted Transactions',
            //     value: mempoolInfo ? formatNumber(mempoolInfo.unbroadcastcount, 0) : "Loading...",
            //     tooltipText: "The number of transactions that have not yet been broadcast to the network.",
            //     clickableTerm: "Unbroadcasted Transactions",
            // },
            {
                label: 'Incremental Relay Fee',
                value: mempoolInfo ? `${formatNumber(mempoolInfo.incrementalrelayfee * 100000000, 0)} sats/vB` : "Loading...",
                tooltipText: "The minimum fee rate increment for replacement transactions in sats/vB.",
                clickableTerm: "Incremental Relay Fee",
            },
            {
                label: 'Minimum Relay TX Fee',
                value: mempoolInfo ? `${formatNumber(mempoolInfo.minrelaytxfee * 100000000, 0)} sats/vB` : "Loading...",
                tooltipText: "The minimum fee rate for relay of transactions in sats/vB.",
                clickableTerm: "Minimum Relay TX Fee",
            },
            // {
            //     label: 'Full RBF Enabled',
            //     value: mempoolInfo ? (mempoolInfo.fullrbf ? "Yes" : "No") : "Loading...",
            //     tooltipText: "Indicates whether full Replace-By-Fee (RBF) is enabled for transactions.",
            //     clickableTerm: "Replace By Fee (RBF)",
            // },




            // {
            //     label: '0-Balance Addresses',
            //     value: data ? `${formatLargeNumber(data.zero_balance_addresses_all_time, 2, false)} Addresses` : "Loading...",
            //     tooltipText: "Number of addresses with a zero balance found on the Bitcoin network.",
            // },
            // {
            //     label: 'Unique Addresses',
            //     value: data ? `${formatLargeNumber(data.unique_addresses_all_time, 2, false)} Addresses` : "Loading...",
            //     tooltipText: "Number of unique addresses found on the Bitcoin network.",
            // },
        ]
        : [];

    const networkSecurityData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            // {
            //     label: 'Avg Hash Rate 30d',
            //     value: data?.s12?.metrics?.data?.mining_stats?.hash_rate_30d_average
            //         ? `${formatLargeNumber(data.s12.metrics.data.mining_stats.hash_rate_30d_average, 2, true)}H/s`
            //         : "Loading...",
            //     tooltipText: "30-day moving average of the Bitcoin network hash rate",
            //     clickableTerm: "Hash Rate",
            // },
            {
                label: 'Mining Algorithm',
                value: 'SHA-256',
                tooltipText: "The SHA-256 hash algorithm takes input blocks of 512 bits (i.e. 64 bytes), combines the data cryptographically, and generates a 256-bit (32 byte) output. The SHA-256 algorithm consists of a relatively simple round repeated 64 times.",
                clickableTerm: "Mining Algorithm",
            },
            {
                label: 'Bitcoin Dominance',
                value: 'Avoid This Metric',
                tooltipText: '"Bitcoin dominance" is a scam metric devised by shitcoiners in order to steal relevance from Bitcoin. Go throw a gold bar into a pool of ever-increasing shit. Now, what is the gold dominance in the pool? Answer: It does not matter!',
                clickableTerm: "Bitcoin Dominance",
            },
            {
                label: 'Hash Rate',
                value: data?.ts04?.hashrate?.currentHashrate != null
                    ? `${formatLargeNumber(data.ts04.hashrate.currentHashrate, 0, true)}H/s`
                    : "Loading...",
                tooltipText: "The hash rate is the measuring unit of the processing power of the Bitcoin network. The Bitcoin network must make intensive mathematical operations for security purposes. When the network reached a hash rate of 10 Th/s, it meant it could make 10 trillion calculations per second.",
                clickableTerm: "Hash Rate",
            },
            {
                label: 'Mining Revenue 24h',
                value: data?.s12?.metrics?.data?.mining_stats?.mining_revenue_usd && bitcoinPrice && conversionRates && selectedCurrency
                    ? `${currencySymbols[selectedCurrency]}${formatLargeNumber(data.s12.metrics.data.mining_stats.mining_revenue_usd * conversionRates[selectedCurrency], 1, false)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: "Value of the total mining revenue (subsidy + fees) over the last 24h",
                clickableTerm: "Mining Revenue",
            },
            {
                label: 'Mining Revenue Fees %',
                value: data?.s12?.metrics?.data?.mining_stats?.mining_revenue_from_fees_percent_last_24_hours !== undefined
                    ? `${formatNumber(data.s12.metrics.data.mining_stats.mining_revenue_from_fees_percent_last_24_hours, 2)}%`
                    : "Loading...",
                tooltipText: "Percentage of mining revenue that comes from transaction fees in the last 24 hours",
                clickableTerm: "Mining Revenue",
            },
            {
                label: 'Unconfirmed TXs Fees',
                value: data && data.s03?.bitcoin_stats?.bitcoin_data && bitcoinPrice ?
                    `${currencySymbols[selectedCurrency]}${formatLargeNumber((data.s03.bitcoin_stats.bitcoin_data.mempool_total_fee_usd * conversionRates[selectedCurrency]), 2, false)} ${selectedCurrency.toUpperCase()}` :
                    "Loading...",
                tooltipText: data && data.s03?.bitcoin_stats?.bitcoin_data ?
                    `Total value of all unconfirmed transactions in the mempool in ${selectedCurrency.toUpperCase()}` :
                    "Loading...",
                clickableTerm: "Unconfirmed Transaction Fees",
            },
            {
                label: 'Last TX Double-Spent?',
                value: 'FALSE',
                tooltipText: "Double-spending is a potential flaw in a digital cash scheme in which the same single digital token can be spent more than once. Unlike physical cash, a digital token consists of a digital file that can be duplicated or falsified. This metric tracks whether or not the last Bitcoin transaction was double-spent somehow.",
                clickableTerm: "Double-Spending Problem",
            },
            {
                label: 'High Fee per vByte',
                value: fees ? `${formatNumber((fees.fastestFee), 0)} Sats/vByte` : "Loading...",
                tooltipText: 'Recommended High Fee per vByte',
                clickableTerm: "Transaction Fee",
            },
            {
                label: 'Medium Fee per vByte',
                value: fees ? `${formatNumber((fees.hourFee), 0)} Sats/vByte` : "Loading...",
                tooltipText: 'Recommended Medium Fee per vByte',
                clickableTerm: "Transaction Fee",
            },

            {
                label: 'Low Fee per vByte',
                value: fees ? `${formatNumber((fees.minimumFee), 0)} Sats/vByte` : "Loading...",
                tooltipText: 'Recommended Low Fee per vByte',
                clickableTerm: "Transaction Fee",
            },

            {
                label: 'Timechain Size',
                value: data?.s03?.bitcoin_stats?.bitcoin_data?.blockchain_size !== null
                    ? `${formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.blockchain_size, 1, true)}B`
                    : "Loading...",
                tooltipText: "Size of the Bitcoin blockchain in Bytes",
                clickableTerm: "Timechain",
            },
            {
                label: 'Average Block Time',
                value: data?.s12?.metrics?.data?.on_chain_data?.average_block_interval !== null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_block_interval / 60, 1)} Minutes`
                    : "Loading...",
                tooltipText: "The average block time in the last 24 hours.",
                clickableTerm: "Average Block Time",
            },

            {
                label: 'Target Block Time',
                value: '10 Minutes',
                tooltipText: "Block time defines the time it takes to mine a block. In bitcoin timechain, there is an expected block time, and an average block time. In bitcoin, the expected block time is 10 minutes.",
                clickableTerm: "Target Block Time",
            },

            {
                label: 'Max Supply Still 21M?',
                value: 'TRUE',
                tooltipText: "Bitcoin's maximum supply remains hard-capped at 21M BTC.",
                clickableTerm: "Scarcity",
            },
            {
                label: 'Victim of 51% Attack?',
                value: 'FALSE',
                tooltipText: "A 51% attack is a potential attack on a timechain network, where a single entity or organization is able to control the majority of the hash rate, potentially causing a network disruption. In such a scenario, the attacker would have enough mining power to intentionally exclude or modify the ordering of transactions. This metric tracks whether or not the Bitcoin network has been successfully 51% attacked.",
                clickableTerm: "51% Attack",
            },


            // {
            //     label: 'Thermocap Multiple',
            //     value: data ? formatNumber(data.market_cap, 2) : "Loading...",
            //     tooltipText: "Ratio of Bitcoin's market cap to the total miner revenue, indicating the market's premium over mining costs",
            //     clickableTerm: "Thermocap",
            // },
            // {
            //     label: 'MVRV Ratio',
            //     value: data ? formatNumber(data.market_cap_to_realized_cap_ratio, 2) : "Loading...",
            //     tooltipText: "The ratio between market capitalization and realized capitalization",
            //     clickableTerm: "MVRV",
            // },



        ]
        : [];

    const marketStatsData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Exchange Flow-In 24h',
                value: data?.s12?.metrics?.data?.exchange_flows?.flow_in_exchange_native_units !== null
                    ? `${formatNumber(data.s12.metrics.data.exchange_flows.flow_in_exchange_native_units, 0)} BTC In`
                    : "Loading...",
                tooltipText: "The amount of Bitcoins flowing into top exchanges",
                clickableTerm: "Exchange Flow-In",
            },
            {
                label: 'Exchange Flow-Out 24h',
                value: data?.s12?.metrics?.data?.exchange_flows?.flow_out_exchange_native_units !== null
                    ? `${formatNumber(data.s12.metrics.data.exchange_flows.flow_out_exchange_native_units, 0)} BTC Out`
                    : "Loading...",
                tooltipText: "The amount of Bitcoins flowing out of top exchanges",
                clickableTerm: "Exchange Flow-Out",
            },
            {
                label: 'Exchange Net Flow 24h',
                value: data?.s12?.metrics?.data?.exchange_flows?.flow_in_exchange_native_units !== null &&
                    data?.s12?.metrics?.data?.exchange_flows?.flow_out_exchange_native_units !== null
                    ? `${formatNumber(data.s12.metrics.data.exchange_flows.flow_in_exchange_native_units -
                        data.s12.metrics.data.exchange_flows.flow_out_exchange_native_units, 2)} BTC`
                    : "Loading...",
                tooltipText: "Net flow of Bitcoin to/from exchanges in the last 24 hours. Positive values indicate net inflows, negative values indicate net outflows.",
                clickableTerm: "Exchange Net Flow",
            },
            {
                label: 'Supply on Exchanges',
                value: data?.s12?.metrics?.data?.exchange_flows?.supply_exchange_native_units !== null
                    ? `${formatNumber(data.s12.metrics.data.exchange_flows.supply_exchange_native_units, 0)} BTC`
                    : "Loading...",
                tooltipText: "Total amount of Bitcoin held on exchanges",
                clickableTerm: "Exchange Supply",
            },
            {
                label: '% Supply on Exchanges',
                value: data?.s12?.metrics?.data?.exchange_flows?.supply_exchange_native_units !== null &&
                    data?.s12?.metrics?.data?.supply?.circulating !== null
                    ? `${formatNumber((data.s12.metrics.data.exchange_flows.supply_exchange_native_units / data.s12.metrics.data.supply.circulating) * 100, 2)}%`
                    : "Loading...",
                tooltipText: "Percentage of circulating Bitcoin supply held on exchanges",
                clickableTerm: "Exchange Supply",
            },
            {
                label: `Price (BTC/${selectedCurrency.toUpperCase()})`,
                value: bitcoinPrice && conversionRates
                    ? `${currencySymbols[selectedCurrency]}${formatNumber(bitcoinPrice * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: `The current price of Bitcoin in ${selectedCurrency.toUpperCase()}.`,
                clickableTerm: "Bitcoin Fiat Price",
            },
            {
                label: 'Realized Price',
                value: data?.s12?.metrics?.data?.marketcap?.realized_marketcap_usd !== undefined &&
                    data?.s12?.metrics?.data?.supply?.circulating !== undefined &&
                    bitcoinPrice && conversionRates
                    ? `${currencySymbols[selectedCurrency]}${formatNumber(((data.s12.metrics.data.marketcap.realized_marketcap_usd * conversionRates[selectedCurrency]) / data.s12.metrics.data.supply.circulating), 2)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: "The realized price is calculated by dividing the realized cap by the current supply",
                clickableTerm: "Realized Price",
            },
            {
                label: 'Realized Cap',
                value: data?.s12?.metrics?.data?.marketcap?.realized_marketcap_usd !== undefined &&
                    bitcoinPrice && conversionRates
                    ? `${currencySymbols[selectedCurrency]}${formatLargeNumber(data.s12.metrics.data.marketcap.realized_marketcap_usd * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: "The realized capitalization values different part of the supplies at different prices (instead of using the current daily close). Specifically, it is computed by valuing each UTXO by the price when it was last moved.",
                clickableTerm: "Realized Cap",
            },
            {
                label: 'Price in Sats',
                value: bitcoinPrice && conversionRates
                    ? `${currencySymbols[selectedCurrency]} ${formatNumber(100000000 / (bitcoinPrice * conversionRates[selectedCurrency]), 0)} sats`
                    : "Loading...",
                tooltipText: "The number of satoshis equivalent to one US dollar at the current price.",
                clickableTerm: "Satoshi",
            },






            {
                label: data?.s09?.exchanges?.Data?.[0]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[0]?.price
                    ? `${formatNumber(data.s09.exchanges.Data[0].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[0]?.exchange
                    ? `The Bitcoin price on ${data.s09.exchanges.Data[0].exchange} in USD`
                    : "Loading...",
            },
            {
                label: data?.s09?.exchanges?.Data?.[1]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[1]?.price
                    ? `${formatNumber(data.s09.exchanges.Data[1].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[1]?.exchange
                    ? `The Bitcoin price on ${data.s09.exchanges.Data[1].exchange} in USD`
                    : "Loading...",
            },
            {
                label: data?.s09?.exchanges?.Data?.[2]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[2]?.price
                    ? `${formatNumber(data.s09.exchanges.Data[2].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[2]?.exchange
                    ? `The Bitcoin price on ${data.s09.exchanges.Data[2].exchange} in USD`
                    : "Loading...",
            },
            {
                label: data?.s09?.exchanges?.Data?.[3]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[3]?.price
                    ? `${formatNumber(data.s09.exchanges.Data[3].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[3]?.exchange
                    ? `The Bitcoin price on ${data.s09.exchanges.Data[3].exchange} in USD`
                    : "Loading...",
            },
            {
                label: data?.s09?.exchanges?.Data?.[4]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[4]?.price
                    ? `${formatNumber(data.s09.exchanges.Data[4].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[4]?.exchange
                    ? `The Bitcoin price on ${data.s09.exchanges.Data[4].exchange} in USD`
                    : "Loading...",
            },
            {
                label: data?.s09?.exchanges?.Data?.[5]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[5]?.price
                    ? `${formatNumber(data.s09.exchanges.Data[5].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[5]?.exchange
                    ? `The Bitcoin price on ${data.s09.exchanges.Data[5].exchange} in USD`
                    : "Loading...",
            },
            {
                label: 'Selected Currency',
                value: `${currencySymbols[selectedCurrency]} ${selectedCurrency.toUpperCase()}`,
                tooltipText: "The currently selected currency for price display.",
            },
            {
                label: 'Conversion Rate',
                value: conversionRates ? formatNumber(conversionRates[selectedCurrency], 3) : "Loading...",
                tooltipText: `The current conversion rate from USD to ${selectedCurrency.toUpperCase()}.`,
            },
        ]
        : [];

    const changePercentageData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [

            {
                label: 'Price Change % 1h',
                value: data && data.s06?.bitcoin ? `${formatNumber(data.s06.bitcoin.market_data?.price_change_percentage_1h_in_currency?.[selectedCurrency] ?? 0, 2)}%` : "Loading...",
                tooltipText: "Price change percentage (1h)",
                clickableTerm: "Percent Change",
            },
            {
                label: 'Price Change 24h',
                value: data && data.s06?.bitcoin ? `${currencySymbols[selectedCurrency]}${formatNumber(data.s06.bitcoin.market_data?.price_change_24h_in_currency?.[selectedCurrency] ?? 0, 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: data && data.s06?.bitcoin ? `Price Change (24h) in ${selectedCurrency.toUpperCase()}` : "Loading...",
                clickableTerm: "Price Change",
            },
            {
                label: 'Price Change % 24h',
                value: data && data.s06?.bitcoin ? `${formatNumber(data.s06.bitcoin.market_data?.price_change_percentage_24h_in_currency?.[selectedCurrency] ?? 0, 2)}%` : "Loading...",
                tooltipText: "Price change percentage (24h)",
                clickableTerm: "Percent Change",
            },
            {
                label: 'Price Change % 7d',
                value: data && data.s06?.bitcoin ? `${formatNumber(data.s06.bitcoin.market_data?.price_change_percentage_7d_in_currency?.[selectedCurrency] ?? 0, 2)}%` : "Loading...",
                tooltipText: "Price change percentage (7d)",
                clickableTerm: "Percent Change",
            },
            {
                label: 'Price Change % 14d',
                value: data && data.s06?.bitcoin ? `${formatNumber(data.s06.bitcoin.market_data?.price_change_percentage_14d_in_currency?.[selectedCurrency] ?? 0, 2)}%` : "Loading...",
                tooltipText: "Price change percentage (14d)",
                clickableTerm: "Percent Change",
            },
            {
                label: 'Price Change % 30d',
                value: data && data.s06?.bitcoin ? `${formatNumber(data.s06.bitcoin.market_data?.price_change_percentage_30d_in_currency?.[selectedCurrency] ?? 0, 2)}%` : "Loading...",
                tooltipText: "Price change percentage (30d)",
                clickableTerm: "Percent Change",
            },
            {
                label: 'Price Change % 60d',
                value: data && data.s06?.bitcoin ? `${formatNumber(data.s06.bitcoin.market_data?.price_change_percentage_60d_in_currency?.[selectedCurrency] ?? 0, 2)}%` : "Loading...",
                tooltipText: "Price change percentage (60d)",
                clickableTerm: "Percent Change",
            },
            {
                label: 'Price Change % 200d',
                value: data && data.s06?.bitcoin ? `${formatNumber(data.s06.bitcoin.market_data?.price_change_percentage_200d_in_currency?.[selectedCurrency] ?? 0, 2)}%` : "Loading...",
                tooltipText: "Price change percentage (200d)",
                clickableTerm: "Percent Change",
            },
            {
                label: 'Price Change % 1y',
                value: data && data.s06?.bitcoin ? `${formatNumber(data.s06.bitcoin.market_data?.price_change_percentage_1y_in_currency?.[selectedCurrency] ?? 0, 2)}%` : "Loading...",
                tooltipText: "Price change percentage (1y)",
                clickableTerm: "Percent Change",
            },
            {
                label: 'Price Change % YTD',
                value: data && data.s12?.metrics?.data ? `${formatNumber(data.s12.metrics.data.roi_data.percent_change_year_to_date, 2)}%` : "Loading...",
                tooltipText: "Price change percentage (Year-to-Date)",
                clickableTerm: "Percent Change",
            },
            {
                label: 'Market Cap Change % 24h',
                value: data && data.s06?.bitcoin ? `${formatNumber(data.s06.bitcoin.market_data.market_cap_change_percentage_24h, 2)}%` : "Loading...",
                tooltipText: "Market Cap Change Percentage 24h",
                clickableTerm: "Market Capitalization",
            },
            {
                label: 'All-Time High',
                value: data && data.s12?.metrics?.data ? `${currencySymbols[selectedCurrency]}${formatNumber(data.s12.metrics.data.all_time_high.price * conversionRates[selectedCurrency], 0)}` : "Loading...",
                tooltipText: "All-time High",
                clickableTerm: "All Time High (ATH)",
            },


            {

                label: `Price (BTC/${selectedCurrency.toUpperCase()})`,
                value: bitcoinPrice && conversionRates ? `${currencySymbols[selectedCurrency]}${formatNumber(bitcoinPrice * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: `The current price of Bitcoin in ${selectedCurrency.toUpperCase()}.`,
                clickableTerm: "Bitcoin Fiat Price",
            },



            {
                label: 'Price Change Since ATH',
                value: data && data.s12?.metrics?.data ? `${currencySymbols[selectedCurrency]}${formatNumber((data.s12.metrics.data.market_data.price_usd - data.s12.metrics.data.all_time_high.price) * conversionRates[selectedCurrency], 2)}` : "Loading...",
                tooltipText: "Change in Bitcoin's price since its all-time high, in absolute terms",
            },
            {
                label: 'All-time High Change %',
                value: data && data.s12?.metrics?.data ? `${formatNumber(data.s12.metrics.data.all_time_high.percent_down, 2)}%` : "Loading...",
                tooltipText: "All-Time High change percentage",
            },
            {
                label: 'Days Since ATH',
                value: data && data.s12?.metrics?.data ? formatNumber(data.s12.metrics.data.all_time_high.days_since, 0) : "Loading...",
                tooltipText: "Number of days since Bitcoin reached its all-time high price",
                clickableTerm: "All Time High (ATH)",
            },


        ]
        : [];

    const miningInfoData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Genesis Block Date',
                value: data && data.s06?.bitcoin ? data.s06.bitcoin.genesis_date : "Loading...",
                tooltipText: "Genesis Block is the name of the first block of Bitcoin ever mined—thus called 'Genesis.' The Genesis Block forms the foundation of the entire Bitcoin trading system and is the prototype of all other blocks in the timechain.",
                clickableTerm: "Genesis Block",
            },
            {
                label: 'Mining Algorithm',
                value: data && data.s06?.bitcoin ? data.s06.bitcoin.hashing_algorithm : "SHA-256",
                tooltipText: "The SHA-256 hash algorithm takes input blocks of 512 bits (i.e. 64 bytes), combines the data cryptographically, and generates a 256-bit (32 byte) output. The SHA-256 algorithm consists of a relatively simple round repeated 64 times.",
                clickableTerm: "Mining Algorithm",
            },
            {
                label: 'Difficulty',
                value: data && data.s03?.bitcoin_stats?.bitcoin_data ? formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.difficulty, 1, true) : "Loading...",
                tooltipText: "Difficulty is a value used to show how hard is it to find a hash that will be lower than target defined by system. The Bitcoin network has a global block difficulty. Valid blocks must have a hash below this target. Mining pools also have a pool-specific share difficulty setting a lower limit for shares.",
                clickableTerm: "Difficulty",
            },
            {
                label: 'Predicted Next Diff.',
                value: data && data.s03?.bitcoin_stats?.bitcoin_data ? formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.next_difficulty_estimate, 1, true) : "Loading...",
                tooltipText: "Estimated next Difficulty Adjustment based on current hash rate, real block time, and other factors",
                clickableTerm: "Next Difficulty",
            },
            {
                label: 'Realtime Next Diff. Change',
                value: data && data.s03?.bitcoin_stats?.bitcoin_data ?
                    `${((data.s03.bitcoin_stats.bitcoin_data.next_difficulty_estimate - data.s03.bitcoin_stats.bitcoin_data.difficulty) / data.s03.bitcoin_stats.bitcoin_data.difficulty * 100).toFixed(2)}%` :
                    "Loading...",
                tooltipText: "Percentage change between the current difficulty and the predicted next difficulty",
                // clickableTerm: "Difficulty Change",
            },
            {
                label: 'Unconfirmed TXs',
                value: mempoolInfo ? `${formatNumber((mempoolInfo.size), 0)} TXs` : "Loading...",
                tooltipText: 'Number of unconfirmed transactions in the Bitcoin mempool',
                clickableTerm: "Unconfirmed Transaction",
                // highlighted: true,
            },
            {
                label: 'Unconfirmed TXs Fees',
                value: data && data.s03?.bitcoin_stats?.bitcoin_data && bitcoinPrice ?
                    `${currencySymbols[selectedCurrency]}${formatLargeNumber((data.s03.bitcoin_stats.bitcoin_data.mempool_total_fee_usd * conversionRates[selectedCurrency]), 2, false)} ${selectedCurrency.toUpperCase()}` :
                    "Loading...",
                tooltipText: data && data.s03?.bitcoin_stats?.bitcoin_data ?
                    `Total value of all unconfirmed transactions in the mempool in ${selectedCurrency.toUpperCase()}` :
                    "Loading...",
                clickableTerm: "Unconfirmed Transaction Fees",
            },
            {
                label: 'Low Fee per vByte',
                value: fees ? `${formatNumber((fees.minimumFee), 0)} Sats/vByte` : "Loading...",
                tooltipText: 'Recommended Low Fee per vByte',
                clickableTerm: "Transaction Fee",
            },
            {
                label: 'Medium Fee per vByte',
                value: fees ? `${formatNumber((fees.hourFee), 0)} Sats/vByte` : "Loading...",
                tooltipText: 'Recommended Medium Fee per vByte',
                clickableTerm: "Transaction Fee",
            },
            {
                label: 'High Fee per vByte',
                value: fees ? `${formatNumber((fees.fastestFee), 0)} Sats/vByte` : "Loading...",
                tooltipText: 'Recommended High Fee per vByte',
                clickableTerm: "Transaction Fee",
            },

            {
                label: 'Target Block Time',
                value: '10 Minutes',
                tooltipText: "Block time defines the time it takes to mine a block. In bitcoin timechain, there is an expected block time, and an average block time. In bitcoin, the expected block time is 10 minutes.",
                clickableTerm: "Target Block Time",
            },
            {
                label: 'Average Block Time 24h',
                value: data && data.s08?.blockchain?.Data ? `${formatNumber(data.s08.blockchain.Data.block_time / 60, 2)} Minutes` : "Loading...",
                tooltipText: "Average minutes between blocks (24h).",
                clickableTerm: "Average Block Time",
            },

            // {
            //     label: 'Mining Revenue per Hash',
            //     value: data ? `${formatNumber(data.mining_revenue_per_hash_usd, 8)} USD/TH/s` : "Loading...",
            //     tooltipText: "Mining revenue per terahash per second",
            //     clickableTerm: "",
            // },


            {
                label: 'Last Block Reward Value',
                value: data && data.ts11?.rewards1 && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatLargeNumber((data.ts11.rewards1.totalReward * (bitcoinPrice * conversionRates[selectedCurrency])) / 100000000, 2, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                tooltipText: "Current value of the block reward",
                clickableTerm: "Block Reward",
            },
            {
                label: 'Previous Adjustment',
                value: da ? `${formatNumber(da.previousRetarget, 2)}%` : "Loading...",
                tooltipText: "Percentage change of the previous mining difficulty adjustment.",
                clickableTerm: "Difficulty Adjustment",
            },
            {

                label: 'Next Adjustment',
                value: da ? `${formatNumber(da.difficultyChange, 2)}%` : "Loading...",
                tooltipText: "Estimated percentage change for the next mining difficulty adjustment.",
                clickableTerm: "Difficulty Adjustment",
            },
            {
                label: 'Blocks Until Adjustment',
                value: da ? formatNumber(da.remainingBlocks, 0) : "Loading...",
                tooltipText: "Number of blocks remaining until the next difficulty adjustment.",
                clickableTerm: "Blocks Until Retarget",
            },

            {
                label: 'Adjustment Progress',
                value: da ? `${formatNumber(da.progressPercent, 2)}%` : "Loading...",
                tooltipText: "Percentage progress towards the next difficulty adjustment.",
                clickableTerm: "Difficulty Adjustment",
            },
            {
                label: 'Diff Epoch Avg Block Time',
                value: da ? `${formatNumber(da.timeAvg / 60000, 2)} minutes` : "Loading...",
                tooltipText: "Average time between blocks in the current difficulty period.",
                clickableTerm: "Difficulty Epoch Avg Block Time",
            },


        ]
        : [];

    const bitcoinSupplyData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Percentage Issued',
                value: data?.s12?.metrics?.data?.supply?.circulating !== undefined ? `${formatNumber((data.s12.metrics.data.supply.circulating / 21000000) * 100, 2)}% of Supply` : "Loading...",
                tooltipText: "Total amount of bitcoins distributed to the network, represented as a percentage of the overall total supply.",
                clickableTerm: "Distribution Schedule",
            },
            {
                label: 'Left to Mine',
                value: data?.s12?.metrics?.data?.supply?.circulating !== undefined ? `${formatNumber(21000000 - data.s12.metrics.data.supply.circulating, 0)} BTC` : "Loading...",
                tooltipText: "Total amount of bitcoins left to mine until all coins are distributed.",
                clickableTerm: "Distribution Schedule",
            },


            {
                label: 'Supply Held by Exchanges',
                value: data?.s12?.metrics?.data?.exchange_flows ? `${formatNumber(data.s12.metrics.data.exchange_flows.supply_exchange_native_units, 0)} BTC` : "Loading...",
                tooltipText: "Amount of Bitcoin held on exchanges",
                clickableTerm: "Exchange Supply",
            },
            {
                label: 'Supply Held by Miners',
                value: data?.s12?.metrics?.data?.miner_flows ? `${formatNumber(data.s12.metrics.data.miner_flows.supply_miners_native_units, 0)} BTC` : "Loading...",
                tooltipText: "Amount of Bitcoin held by miners",
                clickableTerm: "Bitcoin Supply",
            },
            {
                label: 'Liquid Supply',
                value: data?.s12?.metrics?.data?.miner_flows && data?.s12?.metrics?.data?.supply ? `${formatNumber(data.s12.metrics.data.supply.circulating - data.s12.metrics.data.miner_flows.supply_1hop_miners_native_units, 0)} BTC` : "Loading...",
                tooltipText: "Estimated number of bitcoins available for trading (excluding coins held by miners)",
                // clickableTerm: "Liquid Supply",
            },
            // {
            //     label: 'Est. Patoshi Stack',
            //     value: '~1M coins',
            //     tooltipText: "Timechain analysts estimate that Nakamoto had mined about one million bitcoins (equivalent to 100,000,000,000,000 sats) before disappearing in 2010, when he handed the network alert key and control of the code repository over to Gavin Andresen. This metric has been contested and is difficult to verify.",
            // },
            {
                label: '25% Supply',
                value: '22 April 2010',
                tooltipText: "Date when 25% of the Bitcoin supply was mined.",
                clickableTerm: "Bitcoin Supply",
            },
            {
                label: '50% Supply',
                value: '14 December 2011',
                tooltipText: "Date when 50% of the Bitcoin supply was mined.",
                clickableTerm: "Bitcoin Supply",
            },
            {
                label: '75% Supply',
                value: '29 July 2015',
                tooltipText: "Date when 75% of the Bitcoin supply was mined.",
                clickableTerm: "Bitcoin Supply",
            },
            {
                label: '87.5% Supply',
                value: '24 May 2019',
                tooltipText: "Date when 87.5% of the Bitcoin supply was mined.",
                clickableTerm: "Bitcoin Supply",
            },
            {
                label: '90% Supply',
                value: '13 December 2021',
                tooltipText: "Date when 90% of the Bitcoin supply was mined.",
                clickableTerm: "Bitcoin Supply",
            },
            {
                label: '95% Supply',
                value: '~3 December 2025',
                tooltipText: "Date when 95% of the Bitcoin supply was mined.",
                clickableTerm: "Bitcoin Supply",
                // highlighted: true,
            },
            {
                label: '99% Supply',
                value: '~20 February 2035',
                tooltipText: "Date when 99% of the Bitcoin supply was mined.",
                clickableTerm: "Bitcoin Supply",
            },
            {
                label: '99.9% Supply',
                value: '~28 January 2048',
                tooltipText: "Date when 99.9% of the Bitcoin supply was mined.",
                clickableTerm: "Bitcoin Supply",
            },
            {
                label: 'Last Full BTC to Mine',
                value: '~2 May 2105',
                tooltipText: "Estimated date when the last full Bitcoin will have been mined.",
                clickableTerm: "Distribution Schedule",
            },
            {
                label: 'All Bitcoins Issued',
                value: '~Between 2138-2140',
                tooltipText: "Estimated year when all Bitcoin supply will have been mined.",
                clickableTerm: "Distribution Schedule",
            },


            // START HERE
            {
                label: 'Last Active 7+ Days',
                value: data && data.s12?.metrics?.data ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_revived_7d, 8)} BTC` : "Loading...",
                tooltipText: "Amount of Bitcoin supply that has moved in the last 7 days after being dormant for 7+ days",
            },
            {
                label: 'Last Active 30+ Days',
                value: data && data.s12?.metrics?.data ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_revived_30d, 8)} BTC` : "Loading...",
                tooltipText: "Amount of Bitcoin supply that has moved in the last 30 days after being dormant for 30+ days",
            },
            {
                label: 'Last Active 90+ Days',
                value: data && data.s12?.metrics?.data ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_revived_90d, 8)} BTC` : "Loading...",
                tooltipText: "Amount of Bitcoin supply that has moved in the last 90 days after being dormant for 90+ days",
            },
            {
                label: 'Last Active 1+ Year',
                value: data && data.s12?.metrics?.data ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_revived_1y, 8)} BTC` : "Loading...",
                tooltipText: "Amount of Bitcoin supply that has moved in the last year after being dormant for 1+ years",
            },
            {
                label: 'Last Active 2+ Years',
                value: data && data.s12?.metrics?.data ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_revived_2y, 8)} BTC` : "Loading...",
                tooltipText: "Amount of Bitcoin supply that has moved in the last year after being dormant for 2+ years",
            },
            {
                label: 'Last Active 3+ Years',
                value: data && data.s12?.metrics?.data ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_revived_3y, 8)} BTC` : "Loading...",
                tooltipText: "Amount of Bitcoin supply that has moved in the last year after being dormant for 3+ years",
            },
            {
                label: 'Last Active 4+ Years',
                value: data && data.s12?.metrics?.data ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_revived_4y, 8)} BTC` : "Loading...",
                tooltipText: "Amount of Bitcoin supply that has moved in the last year after being dormant for 4+ years",
            },
            {
                label: 'Last Active 5+ Years',
                value: data && data.s12?.metrics?.data ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_revived_5y, 8)} BTC` : "Loading...",
                tooltipText: "Amount of Bitcoin supply that has moved in the last year after being dormant for 5+ years",
            },
            {
                label: 'Addresses with Balance',
                value: data && data.s12?.metrics?.data ? formatNumber(data.s12.metrics.data.on_chain_data.addresses_count, 0) : "Loading...",
                tooltipText: "Total number of Bitcoin addresses with a non-zero balance",
                clickableTerm: "Address",
            },
            {
                label: 'Addresses with No Balance',
                value: data && data.s08?.blockchain?.Data ? formatNumber(data.s08.blockchain.Data.zero_balance_addresses_all_time, 0) : "Loading...",
                tooltipText: "Total number of Bitcoin addresses with a zero balance",
                clickableTerm: "Address",
            },
            {
                label: '% in Top 100 Addresses',
                value: data && data.s12?.metrics?.data ? `${formatNumber((data.s12.metrics.data.supply_distribution.supply_in_top_100_addresses / data.s12.metrics.data.supply.circulating) * 100, 2)}% of Supply` : "Loading...",
                tooltipText: "Percentage of total Bitcoin supply held by the top 100 addresses",
                clickableTerm: "Supply Distribution",
            },
            {
                label: '% Supply in Profit',
                value: data && data.s12?.metrics?.data ? `${formatNumber((data.s12.metrics.data.supply_distribution.supply_in_utxo_in_profit / data.s12.metrics.data.supply.circulating) * 100, 2)}%` : "Loading...",
                tooltipText: "Percentage of Bitcoin supply currently in profit",
            },



        ]
        : [];

    const bitcoinHalvingData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [

            {
                label: 'Blocks Per Halving',
                value: data ? `210,000 Blocks` : "Loading...",
                tooltipText: "Number of blocks for each halving cycle.",
                clickableTerm: "Bitcoin Halving",
            },
            // Good checks
            {
                label: 'Blocks to Next Halving',
                value: data?.s03?.halvening?.bitcoin_data?.blocks_left != null
                    ? `${formatNumber(data.s03.halvening.bitcoin_data.blocks_left, 0)} Blocks`
                    : "Loading...",
                tooltipText: "Number of blocks to go until the next Bitcoin Halving event. New blocks are mined roughly every 10 minutes.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: 'Seconds to Next Halving',
                value: data?.s03?.halvening?.bitcoin_data?.seconds_left != null
                    ? `${formatNumber(data.s03.halvening.bitcoin_data.seconds_left, 0)} Seconds`
                    : "Loading...",
                tooltipText: "Number of seconds left until the next Bitcoin Halving event. New blocks are mined roughly every 10 minutes.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: 'Days Until Halving',
                value: data?.s03?.halvening?.bitcoin_data?.halvening_time != null
                    ? `~${formatNumber(daysLeft(data.s03.halvening.bitcoin_data.halvening_time), 0)} Days`
                    : "Loading...",
                tooltipText: "Estimated number of days left until the next Bitcoin Halving event. New blocks are mined roughly every 10 minutes.",
                clickableTerm: "Bitcoin Halving",
                // highlighted:true,
            },
            {
                label: 'Est. Date / Next Halving',
                value: data?.s03?.halvening?.bitcoin_data?.halvening_time != null
                    ? `${data.s03.halvening.bitcoin_data.halvening_time}`
                    : "Loading...",
                tooltipText: "Estimated date of the next Bitcoin Halving in UTC",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: 'Current Subsidy',
                value: data ? `3.125 BTC / Block` : "Loading...",
                tooltipText: "Current block reward for successfully mining a Bitcoin block. This number is automatically halved every 210,000 blocks... or about every 4 years... in an event called a 'Bitcoin Halving.'",
                clickableTerm: "Subsidy",
            },
            {
                label: 'Post-Halving Subsidy',
                value: data ? `1.5625 BTC / Block` : "Loading...",
                tooltipText: "The number of bitcoins a miner who successfully mines a block will receive after the next Bitcoin Halving",
                clickableTerm: "Block Reward",
            },
            {
                label: 'BTC Added 4th Epoch',
                value: '1,312,500 BTC',
                tooltipText: 'Total number of Bitcoins mined in the fourth Reward Era (2020-2024)',
                // clickableTerm: "Distribution Schedule",
            },
            {
                label: 'BTC Added 3rd Epoch',
                value: '2,625,000 BTC',
                tooltipText: 'Total number of Bitcoins mined in the third Reward Era (2016-2020)',
                // clickableTerm: "Distribution Schedule",
            },
            {
                label: 'BTC Added 2nd Epoch',
                value: '5,250,000 BTC',
                tooltipText: 'Total number of Bitcoins mined in the third Reward Era (2012-2016)',
                // clickableTerm: "Distribution Schedule",
            },
            {
                label: 'BTC Added 1st Epoch',
                value: '10,500,000 BTC',
                tooltipText: 'Total number of Bitcoins mined in the third Reward Era (2009-2012)',
                // clickableTerm: "Distribution Schedule",
            },
            {
                label: 'Bitcoins Mined/Hour',
                value: '~18.75 BTC / Hour',
                tooltipText: "Total amount of Bitcoins mined every hour.",
                clickableTerm: "Mining",
            },
            {
                label: 'Bitcoins Mined/Day',
                value: '~450 BTC / Day',
                tooltipText: "Total amount of Bitcoins mined in a single day.",
                clickableTerm: "Mining",
            },
            {
                label: 'Bitcoins Mined/Week',
                value: '~3,150 BTC / Day',
                tooltipText: "Total amount of Bitcoins mined in one week.",
                clickableTerm: "Mining",
            },
            {
                label: 'Bitcoins Mined/Month',
                value: '~312,600 BTC / Day',
                tooltipText: "Total amount of Bitcoins mined in one month.",
                clickableTerm: "Mining",
            },
            {
                label: 'Bitcoins Mined/Year',
                value: '~164.3k BTC / Year',
                tooltipText: "Total amount of Bitcoins mined in one year.",
                clickableTerm: "Mining",
            },
            {
                label: 'Final Halving Year',
                value: 'Year 2140',
                tooltipText: "The estimated year when the last Bitcoin will be mined, based on the halving schedule.",
                clickableTerm: "Final Halving Year",
            },
            {
                label: 'Total Number of Halvings',
                value: '33',
                tooltipText: "The total number of halvings that will occur before Bitcoin reaches its maximum supply.",
                clickableTerm: "Number of Halvings",
            },
            {
                label: 'Halvings Passed',
                value: '4 so far',
                tooltipText: "The total number of halvings that have happened in Bitcoin's history.",
                // clickableTerm: "Halvings Passed",
            },
            {
                label: 'Genesis Block Date',
                value: 'January 3, 2009',
                tooltipText: "The date when the first Bitcoin block (Genesis block) was mined by Satoshi Nakamoto.",
                clickableTerm: "2009 Genesis Block",
            },
            {
                label: 'First Halving Date',
                value: 'November 28, 2012',
                tooltipText: "The date when the first Bitcoin halving occurred, reducing the block reward from 50 BTC to 25 BTC.",
                clickableTerm: "First Halving Date",
            },
            {
                label: 'Second Halving Date',
                value: 'July 9, 2016',
                tooltipText: "The date when the second Bitcoin halving occurred, reducing the block reward from 25 BTC to 12.5 BTC.",
                clickableTerm: "Second Halving Date",
            },
            {
                label: 'Third Halving Date',
                value: 'May 11, 2020',
                tooltipText: "The date when the third Bitcoin halving occurred, reducing the block reward from 12.5 BTC to 6.25 BTC.",
                clickableTerm: "Third Halving Date",
            },
            {
                label: 'Fourth Halving Date',
                value: 'April 18, 2024',
                tooltipText: "The date when the fourth Bitcoin halving occurred, reducing the block reward from 6.25 BTC to 3.125 BTC.",
                clickableTerm: "Fourth Halving Date",
            },
            {
                label: 'Halving Cycle',
                value: data?.s12?.metrics?.data?.on_chain_data?.block_height != null
                    ? `${Math.floor(data.s12.metrics.data.on_chain_data.block_height / 210000) + 1}th Epoch`
                    : "Loading...",
                tooltipText: "The current halving cycle number. Each cycle lasts 210,000 blocks.",
                clickableTerm: "Halving Cycle",
            },
            {
                label: 'Epoch Coins Left',
                value: data?.s12?.metrics?.data?.on_chain_data?.block_height != null
                    ? formatNumber((210000 - (data.s12.metrics.data.on_chain_data.block_height % 210000)) * 3.125, 3) + ' BTC'
                    : "Loading...",
                tooltipText: "The amount of Bitcoin that will be mined before the next halving occurs.",
                clickableTerm: "Epoch Coins Left",
            },
            {
                label: 'Epoch Coins Left Value',
                value: data?.s12?.metrics?.data?.on_chain_data?.block_height != null &&
                    data?.s12?.metrics?.data?.market_data?.price_usd != null
                    ? `${currencySymbols[selectedCurrency]}${formatLargeNumber((data.s12.metrics.data.market_data.price_usd * conversionRates[selectedCurrency]) * ((210000 - (data.s12.metrics.data.on_chain_data.block_height % 210000)) * 3.125), 2, false)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: "The estimated value of Bitcoin that will be mined before the next halving occurs.",
                clickableTerm: "Epoch Coins Left",
            },
        ]
        : [];

    const futureBitcoinHalvingData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [

            {
                label: '2028 - Est. Block Subsidy',
                value: '1.5625 BTC',
                tooltipText: "In 2028, the block subsidy is estimated to drop from 3.125 BTC to 1.5625 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2032 - Est. Block Subsidy',
                value: '0.78125 BTC',
                tooltipText: "In 2032, the block subsidy is estimated to drop from 1.5625 BTC to 0.78125 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2036 - Est. Block Subsidy',
                value: '0.390625 BTC',
                tooltipText: "In 2036, the block subsidy is estimated to drop from 0.78125 BTC to 0.390625 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2040 - Est. Block Subsidy',
                value: '0.195313 BTC',
                tooltipText: "In 2040, the block subsidy is estimated to drop from 0.390625 BTC to 0.195313 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2044 - Est. Block Subsidy',
                value: '0.097656 BTC',
                tooltipText: "In 2044, the block subsidy is estimated to drop from 0.195313 BTC to 0.097656 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2048 - Est. Block Subsidy',
                value: '0.048828 BTC',
                tooltipText: "In 2048, the block subsidy is estimated to drop from 0.097656 BTC to 0.048828 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2052 - Est. Block Subsidy',
                value: '0.024414 BTC',
                tooltipText: "In 2052, the block subsidy is estimated to drop from 0.048828 BTC to 0.024414 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2056 - Est. Block Subsidy',
                value: '0.012207 BTC',
                tooltipText: "In 2056, the block subsidy is estimated to drop from 0.024414 BTC to 0.012207 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2060 - Est. Block Subsidy',
                value: '0.0061035 BTC',
                tooltipText: "In 2060, the block subsidy is estimated to drop from 0.012207 BTC to 0.0061035 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2064 - Est. Block Subsidy',
                value: '0.00305175 BTC',
                tooltipText: "In 2064, the block subsidy is estimated to drop from 0.0061035 BTC to 0.00305175 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2068 - Est. Block Subsidy',
                value: '0.00152587 BTC',
                tooltipText: "In 2068, the block subsidy is estimated to drop from 0.00305175 BTC to 0.00152587 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2072 - Est. Block Subsidy',
                value: '0.00076293 BTC',
                tooltipText: "In 2072, the block subsidy is estimated to drop from 0.00152587 BTC to 0.00076293 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2076 - Est. Block Subsidy',
                value: '0.00038146 BTC',
                tooltipText: "In 2076, the block subsidy is estimated to drop from 0.00076293 BTC to 0.00038146 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2080 - Est. Block Subsidy',
                value: '0.00019073 BTC',
                tooltipText: "In 2080, the block subsidy is estimated to drop from 0.00038146 BTC to 0.00019073 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2084 - Est. Block Subsidy',
                value: '0.00009536 BTC',
                tooltipText: "In 2084, the block subsidy is estimated to drop from 0.00019073 BTC to 0.00009536 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2088 - Est. Block Subsidy',
                value: '0.00004768 BTC',
                tooltipText: "In 2088, the block subsidy is estimated to drop from 0.00009536 BTC to 0.00004768 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2092 - Est. Block Subsidy',
                value: '0.00002384 BTC',
                tooltipText: "In 2092, the block subsidy is estimated to drop from 0.00004768 BTC to 0.00002384 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2096 - Est. Block Subsidy',
                value: '0.00001192 BTC',
                tooltipText: "In 2096, the block subsidy is estimated to drop from 0.00002384 BTC to 0.00001192 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2100 - Est. Block Subsidy',
                value: '0.00000596 BTC',
                tooltipText: "In 2100, the block subsidy is estimated to drop from 0.00001192 BTC to 0.00000596 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2104 - Est. Block Subsidy',
                value: '0.00000298 BTC',
                tooltipText: "In 2104, the block subsidy is estimated to drop from 0.00000596 BTC to 0.00000298 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2108 - Est. Block Subsidy',
                value: '0.00000149 BTC',
                tooltipText: "In 2108, the block subsidy is estimated to drop from 0.00000298 BTC to 0.00000149 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2112 - Est. Block Subsidy',
                value: '0.00000074 BTC',
                tooltipText: "In 2112, the block subsidy is estimated to drop from 0.00000149 BTC to 0.00000074 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2116 - Est. Block Subsidy',
                value: '0.00000037 BTC',
                tooltipText: "In 2116, the block subsidy is estimated to drop from 0.00000074 BTC to 0.00000037 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2120 - Est. Block Subsidy',
                value: '0.00000018 BTC',
                tooltipText: "In 2120, the block subsidy is estimated to drop from 0.00000037 BTC to 0.00000018 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2124 - Est. Block Subsidy',
                value: '0.00000009 BTC',
                tooltipText: "In 2124, the block subsidy is estimated to drop from 0.00000018 BTC to 0.00000009 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },
            {
                label: '2128 - Est. Block Subsidy',
                value: '0.00000004 BTC',
                tooltipText: "In 2128, the block subsidy is estimated to drop from 0.00000009 BTC to 0.00000004 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Subsidy",
            },
            {
                label: '2132 - Est. Block Subsidy',
                value: '0.00000002 BTC',
                tooltipText: "In 2132, the block subsidy is estimated to drop from 0.00000004 BTC to 0.00000002 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
                clickableTerm: "Bitcoin Halving",
            },


        ]
        : [];

    const difficultyAdjustmentData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [

            {
                label: 'Diff. Adj. Schedule',
                value: '2016 blocks',
                tooltipText: "The amount of blocks between Difficulty Adjustments",
                clickableTerm: "Difficulty Adjustment",
            },
            {
                label: 'Diff. Adj. Frequency',
                value: '~2 weeks',
                tooltipText: "The estimated number of days between Difficulty Adjustments",
                clickableTerm: "Difficulty Adjustment",
            },

            {
                label: 'Min. Difficulty',
                value: '1',
                tooltipText: "The minimum difficulty value possible in the Bitcoin codebase",
                clickableTerm: "Minimum Difficulty",
            },
            {
                label: 'Theoretical Max. Diff.',
                value: '~2^224',
                tooltipText: "This is a theoretical maximum difficulty value",
                clickableTerm: "Theoretical Maximum Difficulty",
            },

            {
                label: 'Difficulty Formula',
                value: 'Diff = D1T / CT',
                tooltipText: 'The formula for calculating the mining difficulty of the Bitcoin network. "Diff" represents the new difficulty target, "D1T" denotes the previous difficulty target, and "CT" stands for the cumulative time taken to mine the most recent set of blocks. This formula ensures that the difficulty adjusts to maintain the desired average block time, which is approximately 10 minutes for Bitcoin.',
                clickableTerm: "Difficulty Formula",
            },
            {
                label: 'Current Difficulty',
                value: data?.s03?.bitcoin_stats?.bitcoin_data?.difficulty != null
                    ? formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.difficulty, 1, true)
                    : "Loading...",
                tooltipText: "Current mining difficulty",
                clickableTerm: "Difficulty",
            },
            {
                label: 'Estimated Next Difficulty',
                value: data?.s03?.bitcoin_stats?.bitcoin_data?.next_difficulty_estimate != null
                    ? formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.next_difficulty_estimate, 1, true)
                    : "Loading...",
                tooltipText: "Estimated difficulty for the next adjustment",
                clickableTerm: "Next Difficulty",
            },
            {
                label: 'Realtime Next Diff. Change',
                value: data?.s03?.bitcoin_stats?.bitcoin_data?.next_difficulty_estimate != null &&
                    data?.s03?.bitcoin_stats?.bitcoin_data?.difficulty != null
                    ? `${((data.s03.bitcoin_stats.bitcoin_data.next_difficulty_estimate - data.s03.bitcoin_stats.bitcoin_data.difficulty) / data.s03.bitcoin_stats.bitcoin_data.difficulty * 100).toFixed(2)}%`
                    : "Loading...",
                tooltipText: "Percentage change between the current difficulty and the predicted next difficulty",
                // clickableTerm: "Difficulty Change",
            },
            {
                label: 'Time Since Adjustment',
                value: data?.s03?.bitcoin_stats?.bitcoin_data?.best_block_height != null
                    ? formatTimeRemaining((600 * (data.s03.bitcoin_stats.bitcoin_data.best_block_height % 2016)))
                    : "Loading...",
                tooltipText: "Time elapsed since the last difficulty adjustment",
                clickableTerm: "Time Since Difficulty Adjustment",
            },
            {
                label: 'Blocks Since Adjustment',
                value: data?.s03?.bitcoin_stats?.bitcoin_data?.best_block_height != null
                    ? `${formatNumber(data.s03.bitcoin_stats.bitcoin_data.best_block_height % 2016, 0)} Blocks`
                    : "Loading...",
                tooltipText: "Number of blocks mined since the last difficulty adjustment",
                clickableTerm: "Blocks Since Difficulty Adjustment",
            },
            {
                label: 'Next Adjustment',
                value: da ? `Block ${formatNumber(da.nextRetargetHeight, 0)}` : "Loading...",
                tooltipText: "Bitcoin's difficulty is designed to adjust every 2016 blocks — or approximately every two weeks. This adjustment is based on changes in the network's hashrate, and occurs regularly in an attempt to ensure that the network continues to solve new blocks at a rate of one every 10 minutes.",
                clickableTerm: "Difficulty Adjustment",
            },
            {
                label: 'Blocks Until Retarget',
                value: da ? `${formatNumber((da.remainingBlocks), 0)} Blocks to go` : "Loading...",
                tooltipText: "Count of remaining blocks before the next Difficulty Adjustment",
                clickableTerm: "Difficulty Adjustment",
                // highlighted: true,
            },
            {
                label: 'Next Adjustment Date',
                value: da ? formatDate(da.estimatedRetargetDate, true) : "Loading...",
                tooltipText: "Estimated date of the next Difficulty Adjustment",
                // clickableTerm: "Difficulty Adjustment",
            },
            {
                label: 'Target Block Time',
                value: "10 minutes",
                tooltipText: "The target time between blocks that the difficulty adjustment aims to maintain",
                clickableTerm: "Target Block Time",
            },
            {
                label: 'Average Block Time',
                value: data?.s12?.metrics?.data?.on_chain_data?.average_block_interval != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_block_interval / 60, 2)} minutes`
                    : "Loading...",
                tooltipText: "Average time between blocks in the current difficulty period",
                clickableTerm: "Average Block Time",
            },



            {
                label: 'Difficulty Explainer',
                value: 'https://en.bitcoin.it/wiki/Difficulty',
                tooltipText: 'Learn more about the Bitcoin difficulty',
                type: 'link',
                displayValue: 'en.bitcoin.it',
                clickableTerm: "Difficulty Adjustment",
            },
            {
                label: 'Difficulty Estimator',
                value: 'https://www.bitrawr.com/difficulty-estimator',
                tooltipText: 'Estimate the Bitcoin difficulty',
                type: 'link',
                displayValue: 'Bitrawr.com',
                clickableTerm: "Difficulty Estimator",
            },
            {
                label: 'Mining Profitability',
                value: 'https://insights.braiins.com/en/profitability-calculator/',
                tooltipText: 'Calculate Bitcoin mining profitability',
                type: 'link',
                displayValue: 'Calculator',
                clickableTerm: "Mining Profitability Calculator",
            },

        ]
        : [];

    const ApiProvidersData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: data?.s09?.exchanges?.Data?.[0]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[0]?.price
                    ? `$${formatNumber(data.s09.exchanges.Data[0].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[0]?.exchange
                    ? `${data.s09.exchanges.Data[0].exchange} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.`
                    : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s09?.exchanges?.Data?.[1]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[1]?.price
                    ? `$${formatNumber(data.s09.exchanges.Data[1].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[1]?.exchange
                    ? `${data.s09.exchanges.Data[1].exchange} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.`
                    : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s09?.exchanges?.Data?.[2]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[2]?.price
                    ? `$${formatNumber(data.s09.exchanges.Data[2].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[2]?.exchange
                    ? `${data.s09.exchanges.Data[2].exchange} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.`
                    : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s09?.exchanges?.Data?.[3]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[3]?.price
                    ? `$${formatNumber(data.s09.exchanges.Data[3].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[3]?.exchange
                    ? `${data.s09.exchanges.Data[3].exchange} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.`
                    : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s09?.exchanges?.Data?.[4]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[4]?.price
                    ? `$${formatNumber(data.s09.exchanges.Data[4].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[4]?.exchange
                    ? `${data.s09.exchanges.Data[4].exchange} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.`
                    : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s09?.exchanges?.Data?.[5]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[5]?.price
                    ? `$${formatNumber(data.s09.exchanges.Data[5].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[5]?.exchange
                    ? `${data.s09.exchanges.Data[5].exchange} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.`
                    : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s09?.exchanges?.Data?.[6]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[6]?.price
                    ? `$${formatNumber(data.s09.exchanges.Data[6].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[6]?.exchange
                    ? `${data.s09.exchanges.Data[6].exchange} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.`
                    : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s09?.exchanges?.Data?.[7]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[7]?.price
                    ? `$${formatNumber(data.s09.exchanges.Data[7].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[7]?.exchange
                    ? `${data.s09.exchanges.Data[7].exchange} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.`
                    : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s09?.exchanges?.Data?.[8]?.exchange || "Loading...",
                value: data?.s09?.exchanges?.Data?.[8]?.price
                    ? `$${formatNumber(data.s09.exchanges.Data[8].price, 2)} USD`
                    : "Loading...",
                tooltipText: data?.s09?.exchanges?.Data?.[8]?.exchange
                    ? `${data.s09.exchanges.Data[8].exchange} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.`
                    : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s04?.bitcoin?.data ? `Coincap` : "Loading...",
                value: data?.s04?.bitcoin?.data?.priceUsd != null ? `$${formatNumber(parseFloat(data.s04.bitcoin.data.priceUsd), 2)} USD` : "Loading...",
                tooltipText: data?.s04?.bitcoin?.data ? `Coincap is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s07?.price?.RAW?.BTC?.USD ? `CryptoCompare` : "Loading...",
                value: data?.s07?.price?.RAW?.BTC?.USD?.PRICE != null ? `$${formatNumber(data.s07.price.RAW.BTC.USD.PRICE, 2)} USD` : "Loading...",
                tooltipText: data?.s07?.price?.RAW?.BTC?.USD ? `CryptoCompare is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: data?.s12?.metrics?.data?.market_data ? `Messari` : "Loading...",
                value: data?.s12?.metrics?.data?.market_data?.price_usd != null ? `$${formatNumber(data.s12.metrics.data.market_data.price_usd, 2)} USD` : "Loading...",
                tooltipText: data?.s12?.metrics?.data?.market_data ? `Messari is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries. It defines the kinds of calls or requests that can be made and other data communication technicals.` : "Loading...",
                clickableTerm: "API Provider",
            },
            {
                label: 'Provider Count',
                value: data?.s09?.exchanges?.Data != null ? formatNumber(data.s09.exchanges.Data.length + 2, 0) : "Loading...",
                tooltipText: "Number of price data providers",
                clickableTerm: "API Provider",
            },
            {
                label: 'Lowest Provider',
                value: data.s09?.exchanges?.Data ? `${findLowestPriceProvider(data)}` : "Loading...",
                tooltipText: "The provider showing the lowest Bitcoin price",
                clickableTerm: "API Provider",
            },
            {
                label: 'Highest Provider',
                value: data.s09?.exchanges?.Data ? `${findHighestPriceProvider(data)}` : "Loading...",
                tooltipText: "The provider showing the highest Bitcoin price",
                clickableTerm: "API Provider",
            },
            {
                label: 'Average Price',
                value: data.s09?.exchanges?.Data ? `$${formatNumber(calculateAveragePrice(data), 2)} USD` : "Loading...",
                tooltipText: "The average Bitcoin price across all providers",
                clickableTerm: "Average Price",
            },
            {
                label: 'Price Range',
                value: data.s09?.exchanges?.Data
                    ? `$${formatNumber(Math.min(...data.s09.exchanges.Data.map(exchange => exchange.price)), 2)} - $${formatNumber(Math.max(...data.s09.exchanges.Data.map(exchange => exchange.price)), 2)}`
                    : "Loading...",
                tooltipText: "Range of prices across all providers",
                clickableTerm: "Price Range",
            },
            {
                label: 'Standard Deviation',
                value: data.s09?.exchanges?.Data
                    ? `$${formatNumber(calculateStandardDeviation(data.s09.exchanges.Data.map(exchange => exchange.price)), 2)}`
                    : "Loading...",
                tooltipText: "Standard deviation of prices across all providers",
                clickableTerm: "Standard Deviation",
            },
        ]
        : [];

    function findLowestPriceProvider(data) {
        if (!data.s09?.exchanges?.Data) return "N/A";
        const lowestPrice = Math.min(...data.s09.exchanges.Data.map(exchange => exchange.price));
        const lowestProvider = data.s09.exchanges.Data.find(exchange => exchange.price === lowestPrice);
        return lowestProvider ? lowestProvider.exchange : "N/A";
    }

    function findHighestPriceProvider(data) {
        if (!data.s09?.exchanges?.Data) return "N/A";
        const highestPrice = Math.max(...data.s09.exchanges.Data.map(exchange => exchange.price));
        const highestProvider = data.s09.exchanges.Data.find(exchange => exchange.price === highestPrice);
        return highestProvider ? highestProvider.exchange : "N/A";
    }

    function calculateAveragePrice(data) {
        if (!data.s09?.exchanges?.Data || data.s09.exchanges.Data.length === 0) return 0;
        const prices = data.s09.exchanges.Data.map(exchange => exchange.price);
        return prices.reduce((sum, price) => sum + price, 0) / prices.length;
    }

    function calculateStandardDeviation(values) {
        if (!values || values.length === 0) return 0;
        const avg = values.reduce((a, b) => a + b) / values.length;
        const squareDiffs = values.map(value => Math.pow(value - avg, 2));
        const avgSquareDiff = squareDiffs.reduce((a, b) => a + b) / squareDiffs.length;
        return Math.sqrt(avgSquareDiff);
    }

    //   // Helper functions
    //   const isValidNumber = (value) => typeof value === 'number' && !isNaN(value) && value !== 0;

    const formatDataItem = (labelFn, valueFn, tooltipFn) => {
        try {
            const label = labelFn();
            const value = valueFn();
            const tooltipText = tooltipFn();
            return {
                label,
                value,
                tooltipText,
                clickableTerm: "1 BTC Can Buy",
            };
        } catch (error) {
            return {
                label: "Loading...",
                value: "Loading...",
                tooltipText: "Loading...",
                clickableTerm: "1 BTC Can Buy",
            };
        }
    };


    // Main data array
    const oneBtcCanBuyData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: data && bitcoinPrice ? `Lambos @ ${formatLargeNumber((546847 * conversionRates[selectedCurrency]), 0, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (546847), 2)} Lambos` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of a 2020 Lamborghini Aventador (~${formatLargeNumber((546847 * conversionRates[selectedCurrency]), 0, false)} ${selectedCurrency.toUpperCase()})` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: data && bitcoinPrice ? `Houses @ ${formatLargeNumber(431000 * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (431000), 2)} Houses` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the average cost of a median U.S. existing house for all home types (~${formatLargeNumber(431000 * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()})` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: 'Tesla Model 3',
                value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 42990, 2)} Cars` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the base price of a Tesla Model 3 (Starting at $42,990 in 2024)` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: data && bitcoinPrice ? `Used Cars @ ${formatLargeNumber(27266 * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (27266), 2)} Used Cars` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the average cost of a used car in the U.S. (~${formatLargeNumber(27266 * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()})` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: 'MtG: Black Lotus',
                value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 3000000, 2)} Card` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one (1) Beta edition 'Black Lotus' card from the Magic: the Gathering trading card game (Sold for ~$3M in 2024)` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },


            {
                label: data?.s06?.bitcoin?.market_data?.current_price?.xau != null && bitcoinPrice != null
                    ? `Gold @ ${formatNumber(bitcoinPrice / (data.s06.bitcoin.market_data.current_price.xau * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                value: data?.s06?.bitcoin?.market_data?.current_price?.xau != null
                    ? `${formatNumber((data.s06.bitcoin.market_data.current_price.xau * conversionRates[selectedCurrency]), 2)} oz.`
                    : "Loading...",
                tooltipText: data?.s06?.bitcoin?.market_data?.current_price?.xau != null && bitcoinPrice != null
                    ? `Dividing one (1) Bitcoin by the cost of 1oz of gold as reported by SwissQuotes Forex Data Feed (Currently trading at ${formatNumber(bitcoinPrice / (data.s06.bitcoin.market_data.current_price.xau * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()})`
                    : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: data?.s06?.bitcoin?.market_data?.current_price?.xag != null && bitcoinPrice != null
                    ? `Silver @ ${formatNumber(bitcoinPrice / (data.s06.bitcoin.market_data.current_price.xag * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                value: data?.s06?.bitcoin?.market_data?.current_price?.xag != null
                    ? `${formatNumber((data.s06.bitcoin.market_data.current_price.xag * conversionRates[selectedCurrency]), 2)} oz.`
                    : "Loading...",
                tooltipText: data?.s06?.bitcoin?.market_data?.current_price?.xag != null && bitcoinPrice != null
                    ? `Dividing one (1) Bitcoin by the cost of 1oz of silver as reported by SwissQuotes Forex Data Feed (Currently trading at ${formatNumber(bitcoinPrice / (data.s06.bitcoin.market_data.current_price.xag * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()})`
                    : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },

            // Oil
            formatDataItem(
                () => {
                    if (!data?.s15?.oil_usd?.[0]?.spreadProfilePrices?.[0]?.ask) throw new Error("Invalid oil data");
                    return `Oil @ ${formatNumber(data.s15.oil_usd[0].spreadProfilePrices[0].ask, 2)} ${selectedCurrency.toUpperCase()}`;
                },
                () => {
                    if (!data?.s15?.oil_usd?.[0]?.spreadProfilePrices?.[0]?.ask || !bitcoinPrice) throw new Error("Invalid oil or Bitcoin data");
                    return `${formatNumber(bitcoinPrice / data.s15.oil_usd[0].spreadProfilePrices[0].ask, 2)} Barrels`;
                },
                () => {
                    if (!data?.s15?.oil_usd?.[0]?.spreadProfilePrices?.[0]?.ask) throw new Error("Invalid oil data");
                    return `Dividing one (1) Bitcoin by the cost of one (1) barrel of oil, as reported by SwissQuotes Forex Data Feed (Currently trading at $${formatNumber(data.s15.oil_usd[0].spreadProfilePrices[0].ask, 2)} ${selectedCurrency.toUpperCase()} per barrel)`;
                }
            ),

            // Natural Gas
            formatDataItem(
                () => {
                    if (!data?.s15?.ngc_usd?.[0]?.spreadProfilePrices?.[0]?.ask) throw new Error("Invalid natural gas data");
                    return `Nat. Gas @ ${formatNumber(data.s15.ngc_usd[0].spreadProfilePrices[0].ask, 2)} ${selectedCurrency.toUpperCase()}`;
                },
                () => {
                    if (!data?.s15?.ngc_usd?.[0]?.spreadProfilePrices?.[0]?.ask || !bitcoinPrice) throw new Error("Invalid natural gas or Bitcoin data");
                    return `${formatNumber(bitcoinPrice / data.s15.ngc_usd[0].spreadProfilePrices[0].ask, 2)} MCF`;
                },
                () => {
                    if (!data?.s15?.ngc_usd?.[0]?.spreadProfilePrices?.[0]?.ask) throw new Error("Invalid natural gas data");
                    return `Dividing one (1) Bitcoin by the cost of one MCF of natural gas, as reported by SwissQuotes Forex Data Feed (Currently trading at $${formatNumber(data.s15.ngc_usd[0].spreadProfilePrices[0].ask, 2)} ${selectedCurrency.toUpperCase()} per MCF)`;
                }
            ),
            {
                label: data && bitcoinPrice ? `Big Macs @ ${formatNumber(5.15 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (5.15), 0)} Big Macs` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one (1) Big Mac hamburger in the U.S. (~${formatNumber(5.15 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: data && bitcoinPrice ? `Eggs @ ${formatNumber(0.17 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (0.17), 0)} Eggs` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the average cost of an egg in the U.S. (~${formatNumber(0.17 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: data && bitcoinPrice ? `Milk @ ${formatNumber(4.34 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (4.34), 0)} Gallons` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the average cost of a gallon of milk in the U.S. (~${formatNumber(4.34 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: 'Amazing Spiderman #300',
                value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 3000, 2)} Copies` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one (1)copy of Amazing Spiderman #300 (Sold for ~$3k in 2024)` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: 'iPhones @ $999 USD',
                value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 999, 2)} Phones` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the price of an iPhone 15 Pro (Starting at $999 in 2024)` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: 'Pizzas @ $18 USD',
                value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 18, 2)} Pizzas` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one (1) large pizza (~$18 in 2024)` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: data && bitcoinPrice ? `Top Ramen @ ${formatNumber(0.49 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (0.49), 0)} pks Ramen` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing 1 Bitcoin by the cost of one (1) package of Ramen noodles in the U.S. (~${formatNumber(0.49 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: 'Steaks @ $15/lb USD',
                value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 15.00, 2)} lbs` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the price of one pound of steak ($15.00 per pound)` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },
            {
                label: 'Cheese @ $5.55/lb USD',
                value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 5.55, 2)} lbs` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the price of one pound of cheese ($5.55 per pound)` : "Loading...",
                clickableTerm: "1 BTC Can Buy",
            },

        ]
        : [];

    // ADD CHECKS HERE
    const ownershipEconomicsData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoin Symbol',
                value: '₿ | BC | XBT | BTC',
                tooltipText: "There are many other cryptocurrency projects but none are Bitcoin, except for BTC... some are forked versions of bitcoin itself (like Litecoin (LTC)), others are completely separate systems and tokens (like Ethereum (ETH)), others are centralized protocols trying to work with banks (XRP), others are cheap clones of Bitcoin (Bitcoin Cash (BCH aka 'BCash' or 'BTrash') and/or Bitcoin SV (BSV aka 'Bitcoin Shit Version' or 'Bitcoin Scam Version')), but the vast majority of so-called 'altcoins' or 'shitcoins' are scams. There is only one true Bitcoin, and the name is 'Bitcoin' or 'BTC'. Anything else (like LTC, ETH, BCH or BSV) is not Bitcoin. Bitcoin = BTC!",
                // clickableTerm: "Bitcoin Symbol",
            },
            {
                label: '1 BTC Contains',
                value: '100,000,000 Sats',
                tooltipText: "The general unit structure of bitcoins has 1 bitcoin (BTC) equivalent to 1,000 millibitcoins (mBTC), 1,000,000 microbitcoins (μBTC), or 100,000,000 satoshis (commonly known as 'sats'). Every one (1) Bitcoin contains 100,000,000 individual units ('sats') and can be expressed with 8 decimal places (1.00000000 BTC).",
                clickableTerm: "Fungibility",
            },
            {
                label: 'Max. Units of BTC (Sats)',
                value: '2.1 Quadrillion',
                tooltipText: "Multiplying Bitcoin's max supply (20,999,999) by the number of individual units ('sats') in every bitcoin (100,000,000)... The resultng number of 2.09 quadrillion units is more money than is currently on Earth and therefore more than enough to use as a functional global currency... even with lost bitcoins.",
                clickableTerm: "Fungibility",
            },
            {
                label: 'Est. World Population',
                value: `~${formatLargeNumber(calculateCurrentPopulation(), 2, false)} humans`,
                tooltipText: "Estimated World Population calculated from UN data",
                clickableTerm: "Human Population",
            },
            {
                label: '21M BTC ÷ Top 0.1%',
                value: `${formatNumber((21000000 / (calculateCurrentPopulation() / 1000)), 8)} BTC`,
                tooltipText: "Dividing Bitcoin Max Supply (20,999,999) by (2022 World Population ÷ 1000)",
                clickableTerm: "Supply Distribution",
            },
            {
                label: '21M BTC ÷ Top 1%',
                value: `${formatNumber((21000000 / (calculateCurrentPopulation() / 100)), 8)} BTC`,
                tooltipText: "Dividing Bitcoin Max Supply (20,999,999) by (2022 World Population ÷ 100)",
                clickableTerm: "Supply Distribution",
            },
            {
                label: '21M BTC ÷ Top 10%',
                value: `${formatNumber((21000000 / (calculateCurrentPopulation() / 10)), 8)} BTC`,
                tooltipText: "Dividing Bitcoin Max Supply (20,999,999) by (2022 World Population ÷ 10)",
                clickableTerm: "Supply Distribution",
            },
            {
                label: '21M BTC ÷ Person',
                value: `${formatNumber((21000000 / calculateCurrentPopulation()), 8)} BTC`,
                tooltipText: "Dividing Bitcoin Max Supply (20,999,999) by (2022 World Population ÷ 1)",
                clickableTerm: "BTC Per Person",
                // highlighted: true,
            },
            {
                label: data && bitcoinPrice ? `${formatNumber((21000000 / calculateCurrentPopulation()), 4)} BTC in ${selectedCurrency.toUpperCase()}` : "Loading...",
                value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber(((21000000 / calculateCurrentPopulation()) * bitcoinPrice * conversionRates[selectedCurrency]), 2)}` : "Loading...",
                tooltipText: data && bitcoinPrice ? `Multiplying Bitcoins per Person ${formatNumber((21000000 / calculateCurrentPopulation()), 4)} by Bitcoin Price $${formatNumber(bitcoinPrice, 2)} and then converting to ${selectedCurrency.toUpperCase()}` : "Loading...",
                clickableTerm: "BTC Per Person",
            },
            {
                label: '21M BTC ÷ Fortune 500',
                value: `${formatNumber((21000000 / 500), 0)} BTC / Company`,
                tooltipText: `Dividing Bitcoin's maximum supply (20,999,999) by the number of companies in the Fortune 500 list (currently 500), resulting in an allocation of approximately ${formatNumber((21000000 / 500), 0)} BTC / Company`,
                clickableTerm: "BTC Per Fortune 500 Company",
            },
            {
                label: '21M BTC ÷ NYSE',
                value: `${formatNumber((21000000 / 2385), 0)} BTC / Company`,
                tooltipText: `Dividing Bitcoin's maximum supply (20,999,999) by the number of companies listed on the New York Stock Exchange (currently around 2400), resulting in an allocation of approximately ${formatNumber((21000000 / 2385), 0)} BTC / Company`,
                clickableTerm: "BTC Per NYSE Company",
            },
            {
                label: '21M BTC ÷ NYC',
                value: `~${formatNumber(21000000 / 8097282, 6)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of New York (8.468M in 2023), assuming the City of New York are the only bitcoin holders for this exercise",
                clickableTerm: "21M Divided By Everything",
            },
            {
                label: '21M BTC ÷ USA',
                value: `~${formatNumber(21000000 / 345426571, 6)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of USA (331.9M in 2023), assuming the USA population are the only bitcoin holders for this exercise",
                clickableTerm: "21M Divided By Everything",
            },
            {
                label: '21M BTC ÷ Europe',
                value: `~${formatNumber(21000000 / 744980559, 6)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of Europe (746.4M in 2023), assuming the European population are the only bitcoin holders for this exercise",
                clickableTerm: "21M Divided By Everything",
            },
            {
                label: '21M BTC ÷ Japan',
                value: `~${formatNumber(21000000 / 122631432, 6)} BTC/Person`,
                tooltipText: "Dividing Total Bitcoin Supply (21M) by Estimated Population of Japan (125.7M in 2023), assuming the Japanese population are the only bitcoin holders for this exercise",
                clickableTerm: "21M Divided By Everything",
            },
            {
                label: '21M BTC ÷ Country',
                value: `${formatNumber((21000000 / 195), 0)} BTC / Country`,
                tooltipText: `Dividing Bitcoin's maximum supply (20,999,999) by the number of countries in the world (currently 195), resulting in an allocation of approximately ${formatNumber((21000000 / 195), 0)} BTC / Country`,
                clickableTerm: "21M Divided By Everything",
            },
            {
                label: 'UTXOs in Profit',
                value: data?.s12?.metrics?.data?.on_chain_data?.utxo_in_profit_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.utxo_in_profit_count, 0)}`
                    : "Loading...",
                tooltipText: "Number of UTXOs currently in profit",
                clickableTerm: "UTXO",
            },
            {
                label: 'UTXOs in Loss',
                value: data?.s12?.metrics?.data?.on_chain_data?.utxo_in_loss_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.utxo_in_loss_count, 0)}`
                    : "Loading...",
                tooltipText: "Number of UTXOs currently in loss",
                clickableTerm: "UTXO",
            },




        ]
        : [];

    const lightningData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Lightning Network Capacity',
                value: data?.s11?.lightning?.latest?.total_capacity != null
                    ? `${formatNumber(data.s11.lightning.latest.total_capacity / 100000000, 2)} BTC`
                    : "Loading...",
                tooltipText: "Total capacity of payment channels in the Lightning Network",
                clickableTerm: "Lightning Capacity",
            },
            {
                label: 'Lightning Nodes',
                value: data?.s11?.lightning?.latest?.node_count != null
                    ? `${formatNumber(data.s11.lightning.latest.node_count, 0)} Nodes`
                    : "Loading...",
                tooltipText: "Total number of nodes in the Lightning Network",
                clickableTerm: "Lightning Nodes",
            },
            {
                label: 'Lightning Channels',
                value: data?.s11?.lightning?.latest?.channel_count != null
                    ? `${formatNumber(data.s11.lightning.latest.channel_count, 0)} Channels`
                    : "Loading...",
                tooltipText: "Total number of open channels in the Lightning Network",
                clickableTerm: "Lightning Channels",
            },
            {
                label: 'Avg Channel Capacity',
                value: data?.s11?.lightning?.latest?.total_capacity != null && data?.s11?.lightning?.latest?.channel_count != null
                    ? `${formatNumber((data.s11.lightning.latest.total_capacity / data.s11.lightning.latest.channel_count) / 100000000, 8)} BTC`
                    : "Loading...",
                tooltipText: "Average capacity per channel in the Lightning Network",
                clickableTerm: "Channel Capacity",
            },
            {
                label: 'Tor Nodes',
                value: data?.s11?.lightning?.latest?.tor_nodes != null
                    ? `${formatNumber(data.s11.lightning.latest.tor_nodes, 0)} Nodes`
                    : "Loading...",
                tooltipText: "Number of Lightning Network nodes running on the Tor network",
                clickableTerm: "Node",
            },
            {
                label: 'Clearnet Nodes',
                value: data?.s11?.lightning?.latest?.clearnet_nodes != null
                    ? `${formatNumber(data.s11.lightning.latest.clearnet_nodes, 0)} Nodes`
                    : "Loading...",
                tooltipText: "Number of Lightning Network nodes running on clearnet",
                clickableTerm: "Node",
            },
            {
                label: 'Avg Fee Rate',
                value: data?.s11?.lightning?.latest?.avg_fee_rate != null
                    ? `${formatNumber(data.s11.lightning.latest.avg_fee_rate, 0)} sats`
                    : "Loading...",
                tooltipText: "Average fee rate for Lightning Network transactions",
                clickableTerm: "Fee Rate",
            },
            {
                label: 'Clearnet-Tor Nodes',
                value: data?.s11?.lightning?.latest?.clearnet_tor_nodes != null
                    ? `${formatNumber(data.s11.lightning.latest.clearnet_tor_nodes, 0)} Nodes`
                    : "Loading...",
                tooltipText: "The number of nodes available both on the clearnet and Tor.",
                clickableTerm: "Node",
            },
            {
                label: 'Unannounced Nodes',
                value: data?.s11?.lightning?.latest?.unannounced_nodes != null
                    ? `${formatNumber(data.s11.lightning.latest.unannounced_nodes, 0)} Nodes`
                    : "Loading...",
                tooltipText: "The number of nodes that are not publicly announced.",
                clickableTerm: "Node",
            },
        ]
        : [];

    const feesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Total Fees BTC 24h',
                value: data?.s12?.metrics?.data?.on_chain_data?.total_fees_last_24_hours != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.total_fees_last_24_hours, 8)} BTC`
                    : "Loading...",
                tooltipText: 'Total transaction fees paid on the network in BTC in the last 24 hours.',
                clickableTerm: "Transaction Fee",
            },
            {
                label: `Total Fees ${selectedCurrency.toUpperCase()} 24h`,
                value: data?.s12?.metrics?.data?.on_chain_data?.total_fees_last_24_hours != null && bitcoinPrice != null
                    ? `${currencySymbols[selectedCurrency]}${formatNumber((data.s12.metrics.data.on_chain_data.total_fees_last_24_hours * bitcoinPrice) * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: `Total transaction fees paid on the network in ${selectedCurrency.toUpperCase()} in the last 24 hours.`,
                clickableTerm: "Transaction Fee",
            },
            {
                label: 'Mining Fee Rev % (24h)',
                value: data?.s12?.metrics?.data?.mining_stats?.mining_revenue_from_fees_percent_last_24_hours != null
                    ? `${formatNumber(data.s12.metrics.data.mining_stats.mining_revenue_from_fees_percent_last_24_hours, 2)} %`
                    : "Loading...",
                tooltipText: 'Percentage of mining revenue that comes from transaction fees in the last 24 hours.',
                clickableTerm: "Mining Fee Revenue",
            },
            {
                label: 'Avg Fee BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.average_fee_native_units != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_fee_native_units, 8)} BTC`
                    : "Loading...",
                tooltipText: 'Average transaction fee on the network in BTC.',
                clickableTerm: "Transaction Fee",
            },
            {
                label: `Avg Fee ${selectedCurrency.toUpperCase()}`,
                value: data?.s12?.metrics?.data?.on_chain_data?.average_fee_native_units != null && bitcoinPrice != null
                    ? `${currencySymbols[selectedCurrency]}${formatNumber((data.s12.metrics.data.on_chain_data.average_fee_native_units * bitcoinPrice) * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: `Average transaction fee on the network in ${selectedCurrency.toUpperCase()}.`,
                clickableTerm: "Transaction Fee",
            },
            {
                label: 'Median Fee BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.median_fee_native_units != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.median_fee_native_units, 8)} BTC`
                    : "Loading...",
                tooltipText: 'Median transaction fee on the network in BTC.',
                clickableTerm: "Transaction Fee",
            },
            {
                label: 'Avg Transfer Value BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.average_transfer_value_native_units != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_transfer_value_native_units, 8)} BTC`
                    : "Loading...",
                tooltipText: 'Average value of transfers on the network in BTC.',
                clickableTerm: "Transfer Value",
            },
            {
                label: `Avg Transfer Value ${selectedCurrency.toUpperCase()}`,
                value: data?.s12?.metrics?.data?.on_chain_data?.average_transfer_value_native_units != null && bitcoinPrice != null
                    ? `${currencySymbols[selectedCurrency]}${formatNumber((data.s12.metrics.data.on_chain_data.average_transfer_value_native_units * bitcoinPrice) * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: `Average value of transfers on the network in ${selectedCurrency.toUpperCase()}.`,
                clickableTerm: "Transfer Value",
            },
            {
                label: 'Median Transaction Fee',
                value: data?.s12?.metrics?.data?.on_chain_data?.median_fee_native_units != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.median_fee_native_units * 100000000, 2)} sats`
                    : "Loading...",
                tooltipText: "Median transaction fee in satoshis",
                clickableTerm: "Transaction Fee",
            },
            //   {
            //     label: 'Median Transfer Value',
            //     value: data?.s12?.metrics?.data?.on_chain_data?.median_transfer_value_usd != null && bitcoinPrice != null
            //       ? `${currencySymbols[selectedCurrency]}${formatNumber(data.s12.metrics.data.on_chain_data.median_transfer_value_usd * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}`
            //       : "Loading...",
            //     tooltipText: "Median value of Bitcoin transfers in the last 24 hours",
            //     clickableTerm: "Transaction Fee",
            //   },

        ]
        : [];


    const btcAddressData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Addresses > 0.001 BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_0_001_native_units_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_0_001_native_units_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than 0.001 BTC.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > 0.01 BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_0_01_native_units_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_0_01_native_units_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than 0.01 BTC.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > 0.1 BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_0_1_native_units_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_0_1_native_units_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than 0.1 BTC.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > 1 BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_1_native_units_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_1_native_units_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than 1 BTC.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > 10 BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_10_native_units_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_10_native_units_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than 10 BTC.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > 100 BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_100_native_units_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_100_native_units_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than 100 BTC.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > 1k BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_1k_native_units_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_1k_native_units_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than 1,000 BTC.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > 10k BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_10k_native_units_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_10k_native_units_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than 10,000 BTC.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > 100k BTC',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_100k_native_units_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_100k_native_units_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than 100,000 BTC.',
                clickableTerm: "Address",
            },




        ]
        : [];

    const usdAddressData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Total Addresses',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > $1',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_1_usd_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_1_usd_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than $1.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > $10',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_10_usd_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_10_usd_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than $10.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > $100',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_100_usd_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_100_usd_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than $100.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > $1k',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_1k_usd_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_1k_usd_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than $1,000.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > $10k',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_10k_usd_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_10k_usd_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than $10,000.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > $100k',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_100k_usd_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_100k_usd_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than $100,000.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > $1M',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_1m_usd_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_1m_usd_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than $1,000,000.',
                clickableTerm: "Address",
            },
            {
                label: 'Addresses > $10M',
                value: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_10m_usd_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_10m_usd_count, 0)}`
                    : "Loading...",
                tooltipText: 'Number of addresses with a balance greater than $10,000,000.',
                clickableTerm: "Address",
            },
        ]
        : [];

    const velocityData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Transaction Volume 24h',
                value: data?.s12?.metrics?.data?.on_chain_data?.txn_volume_last_24_hours_usd != null && bitcoinPrice != null
                    ? `${currencySymbols[selectedCurrency]}${formatLargeNumber(data.s12.metrics.data.on_chain_data.txn_volume_last_24_hours_usd * conversionRates[selectedCurrency], 2, false)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: "Total value of Bitcoin transactions in the last 24 hours",
                clickableTerm: "Volume",
            },
            {
                label: 'Velocity of Money 24h',
                value: data?.s12?.metrics?.data?.on_chain_data?.txn_volume_last_24_hours_usd != null && data?.s12?.metrics?.data?.supply_activity?.supply_active_1d != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.txn_volume_last_24_hours_usd / data.s12.metrics.data.supply_activity.supply_active_1d, 0)}x`
                    : "Loading...",
                tooltipText: "Applying the velocity of money formula: Velocity = Total Transaction Volume / Average Money Supply",
                clickableTerm: "Velocity of Money",
            },
            {
                label: 'Transactions/s 24h',
                value: data?.s12?.metrics?.data?.on_chain_data?.txn_per_second_count != null
                    ? `${formatNumber(data.s12.metrics.data.on_chain_data.txn_per_second_count, 2)} TX/s`
                    : "Loading...",
                tooltipText: "Average number of transactions per second in the last 24 hours",
                clickableTerm: "Transactions Per Second",
            },
            {
                label: 'Active Supply 24h',
                value: data?.s12?.metrics?.data?.supply_activity?.supply_active_1d != null
                    ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_active_1d, 0)} BTC`
                    : "Loading...",
                tooltipText: "Amount of Bitcoin that has moved in the last 24 hours",
                clickableTerm: "Active Supply",
            },
            {
                label: 'Active Supply % 24h',
                value: data?.s12?.metrics?.data?.supply_activity?.supply_active_1d != null && data?.s12?.metrics?.data?.supply?.circulating != null
                    ? `${formatNumber((data.s12.metrics.data.supply_activity.supply_active_1d / data.s12.metrics.data.supply.circulating) * 100, 2)}%`
                    : "Loading...",
                tooltipText: "Percentage of circulating supply that has moved in the last 24 hours",
                clickableTerm: "Active Supply",
            },
            {
                label: 'Active Supply 30d',
                value: data?.s12?.metrics?.data?.supply_activity?.supply_active_30d != null
                    ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_active_30d, 0)} BTC`
                    : "Loading...",
                tooltipText: "Amount of Bitcoin that has moved in the last 30 days",
                clickableTerm: "Active Supply",
            },
            {
                label: 'Active Supply % 30d',
                value: data?.s12?.metrics?.data?.supply_activity?.supply_active_30d != null && data?.s12?.metrics?.data?.supply?.circulating != null
                    ? `${formatNumber((data.s12.metrics.data.supply_activity.supply_active_30d / data.s12.metrics.data.supply.circulating) * 100, 2)}%`
                    : "Loading...",
                tooltipText: "Percentage of circulating supply that has moved in the last 30 days",
                clickableTerm: "Active Supply",
            },
            {
                label: 'TX > $100k USD 24h',
                value: data?.s08?.blockchain?.Data?.large_transaction_count != null
                    ? `${formatNumber(data.s08.blockchain.Data.large_transaction_count, 0)} TX`
                    : "Loading...",
                tooltipText: "Number of transactions greater than $100,000 in the last 24 hours",
                clickableTerm: "Transaction",
            },
            {
                label: 'Largest TX Value 24h',
                value: data?.s03?.bitcoin_stats?.bitcoin_data?.largest_transaction_24h?.value_usd != null && bitcoinPrice != null
                    ? `${currencySymbols[selectedCurrency]}${formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.largest_transaction_24h.value_usd * conversionRates[selectedCurrency], 2, false)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: "Value of the largest single transaction in the last 24 hours",
                clickableTerm: "Transaction",
            },
            {
                label: 'Median TX Value 24h',
                value: data?.s12?.metrics?.data?.blockchain_stats_24_hours?.median_tx_value != null && bitcoinPrice != null
                    ? `${currencySymbols[selectedCurrency]}${formatNumber(data.s12.metrics.data.blockchain_stats_24_hours.median_tx_value * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}`
                    : "Loading...",
                tooltipText: "Median value of Bitcoin transactions in the last 24 hours",
                clickableTerm: "Median Transaction Value",
            },
            {
                label: 'Coin Days Destroyed 24h',
                value: data?.s03?.bitcoin_stats?.bitcoin_data?.cdd_24h != null
                    ? `${formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.cdd_24h, 0, true)} Coin Days`
                    : "Loading...",
                tooltipText: "The total number of coin days destroyed in the last 24 hours. This measures the movement of coins weighted by the number of days they have been held. Calculated by taking the number of coins in a transaction and multiplying it by the number of days it has been since those coins were last spent. For example: A UTXO for 5 BTC that has not moved for 100 days has accumulated 500 coin days.",
                clickableTerm: "Coin Days Destroyed",
            },
            {
                label: 'Adjusted NVT Ratio',
                value: data?.s12?.metrics?.data?.on_chain_data?.adjusted_nvt != null
                    ? formatNumber(data.s12.metrics.data.on_chain_data.adjusted_nvt, 2)
                    : "Loading...",
                tooltipText: "The Network Value to Transactions (NVT) Ratio adjusted for coin velocity",
                clickableTerm: "Adjusted NVT Ratio",
            },
        ]
        : [];

    const mempoolData = data && bitcoinPrice && conversionRates && selectedCurrency && mempoolInfo
        ? [
            {
                label: 'Mempool Size',
                value: mempoolInfo ? `${formatLargeNumber(mempoolInfo.usage, 2, true)}B / 300 MB` : "Loading...",
                tooltipText: "Current size of the mempool in megabytes",
                clickableTerm: "Mempool Size",
            },
            {
                label: 'Mempool Usage',
                value: mempoolInfo ? `${formatNumber((mempoolInfo.usage / mempoolInfo.maxmempool) * 100, 2)}%` : "Loading...",
                tooltipText: "The current mempool usage as a percentage of the maximum allowed size.",
                clickableTerm: "Mempool Usage",
            },
            {
                label: 'Mempool Transactions',
                value: data ? `${formatNumber(mempoolInfo.size, 0)} TX` : "Loading...",
                tooltipText: "Number of unconfirmed transactions in the mempool",
                clickableTerm: "Mempool Transactions",
            },
            {
                label: 'Blocks to Clear',
                value: mempoolInfo ? calculateBlocksToClear(mempoolInfo).displayValue : "Loading...",
                tooltipText: "Estimated number of blocks needed to clear current mempool",
                clickableTerm: "Blocks to Clear",
            },
            {
                label: 'Mempool Fees BTC',
                value: mempoolInfo ? `${formatNumber(mempoolInfo.total_fee, 8)} BTC` : "Loading...",
                tooltipText: "Total fees in the mempool in BTC.",
                clickableTerm: "Mempool Fees",
            },
            {
                label: 'Value of Mempool Fees',
                value: mempoolInfo ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency] * mempoolInfo.total_fee), 2)}` : "Loading...",
                tooltipText: "Total fees in the mempool in BTC.",
                clickableTerm: "Mempool Fees",
            },
           

            {
                label: 'Incremental Relay Fee',
                value: mempoolInfo ? `${formatNumber(mempoolInfo.incrementalrelayfee * 100000000, 0)} sats/vB` : "Loading...",
                tooltipText: "The minimum fee rate increment for replacement transactions in sats/vB.",
                clickableTerm: "Incremental Relay Fee",
            },
            {
                label: 'Minimum Relay TX Fee',
                value: mempoolInfo ? `${formatNumber(mempoolInfo.minrelaytxfee * 100000000, 0)} sats/vB` : "Loading...",
                tooltipText: "The minimum fee rate for relay of transactions in sats/vB.",
                clickableTerm: "Minimum Relay TX Fee",
            },
            {
                label: 'High Fee per vByte',
                value: fees ? `${formatNumber((fees.fastestFee), 0)} Sats/vByte` : "Loading...",
                tooltipText: 'Recommended High Fee per vByte',
                clickableTerm: "Fees Per vByte",
            },
            {
                label: 'Mempool TPS',
                value: data?.s03?.bitcoin_stats?.bitcoin_data?.mempool_tps != null ? `~${formatNumber(data.s03.bitcoin_stats.bitcoin_data.mempool_tps, 1)} TX/s` : "Loading...",
                tooltipText: "Transactions per second entering the mempool",
                clickableTerm: "Mempool Transactions Per Second",
            },

            {
                label: 'Average Block Time',
                value: data?.s12?.metrics?.data?.on_chain_data?.average_block_interval != null ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_block_interval / 60, 1)} Minutes` : "Loading...",
                tooltipText: "The average block time in the last 24 hours.",
                clickableTerm: "Average Block Time",
            },
            {
                label: 'Est. Hash Rate',
                value: data?.ts04?.hashrate?.currentHashrate != null
                    ? `${formatLargeNumber(data.ts04.hashrate.currentHashrate, 0, true)}H/s` : "Loading...",
                tooltipText: "The hash rate is the measuring unit of the processing power of the Bitcoin network. The Bitcoin network must make intensive mathematical operations for security purposes. When the network reached a hash rate of 10 Th/s, it meant it could make 10 trillion calculations per second.",
                clickableTerm: "Hash Rate",
            },
            

            // {
            //     label: 'Unbroadcasted Transactions',
            //     value: mempoolInfo ? formatNumber(mempoolInfo.unbroadcastcount, 0) : "Loading...",
            //     tooltipText: "The number of transactions that have not yet been broadcast to the network.",
            //     clickableTerm: "Unbroadcasted Transactions",
            // },

            // {
            //     label: 'Full RBF Enabled',
            //     value: mempoolInfo ? (mempoolInfo.fullrbf ? "Yes" : "No") : "Loading...",
            //     tooltipText: "Indicates whether full Replace-By-Fee (RBF) is enabled for transactions.",
            //     clickableTerm: "Replace By Fee (RBF)",
            // },

        ]
        : [];

    const faqData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'General Questions',
                value: 'https://bitcoin.org/en/faq#what-is-bitcoin',
                displayValue: 'What is Bitcoin?',
                type: 'link',
                tooltipText: 'Find the answer to What is Bitcoin and more at Bitcoin.org',
            },
            {
                label: 'Legal',
                value: 'https://bitcoin.org/en/faq#is-bitcoin-legal',
                displayValue: 'Is Bitcoin legal?',
                type: 'link',
                tooltipText: 'Discover the legal status of Bitcoin around the world at Bitcoin.org',
            },
            {
                label: 'Economy',
                value: 'https://bitcoin.org/en/faq#how-are-bitcoins-created',
                displayValue: 'How are Bitcoins made?',
                type: 'link',
                tooltipText: 'Learn about the process of Bitcoin creation at Bitcoin.org',
            },
            {
                label: 'Security',
                value: 'https://bitcoin.org/en/faq#is-bitcoin-secure',
                displayValue: 'Is Bitcoin secure?',
                type: 'link',
                tooltipText: 'Explore the security features of Bitcoin at Bitcoin.org',
            },
            {
                label: 'Bitcoin Mining',
                value: 'https://bitcoin.org/en/faq#what-is-bitcoin-mining',
                displayValue: 'What is BTC mining?',
                type: 'link',
                tooltipText: 'Uncover the concept of Bitcoin mining and its role in the network at Bitcoin.org',
                clickableTerm: "Mining",
            },
        ]
        : [];

    const popularResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Monetary History',
                value: 'https://saifedean.com/tbs',
                displayValue: "Bitcoin Standard",
                type: 'link',
                tooltipText: 'The Bitcoin Standard by Saifedean Ammous',
            },
            {
                label: 'Bitcoin Privacy',
                value: 'https://bitcoinprivacy.guide/',
                displayValue: "Guide to Privacy",
                type: 'link',
                tooltipText: "The Beginner's Guide to Privacy by BitcoinQ&A",
            },
            {
                label: 'Bitcoin Education',
                value: 'https://21lessons.com/',
                displayValue: "21 Lessons",
                type: 'link',
                tooltipText: '21 Lessons by Gigi',
            },
            {
                label: 'Recommended',
                value: 'https://www.amazon.com/Bullish-Case-Bitcoin-Vijay-Boyapati/dp/1737204118',
                displayValue: "Bullish Case for Bitcoin",
                type: 'link',
                tooltipText: 'The Bullish Case for Bitcoin by Vijay Boyapati',
            },
            {
                label: 'Bitcoin History',
                value: 'https://www.amazon.com/dp/B08YQMC2WM',
                displayValue: "Blocksize War",
                type: 'link',
                tooltipText: 'The Blocksize War by Johnathan Bier',
            },
            {
                label: 'Financial Freedom',
                value: 'https://www.amazon.com/Sovereign-Individual-Mastering-Transition-Information/dp/0684832720/',
                displayValue: "Sovereign Individual",
                type: 'link',
                tooltipText: 'The Sovereign Individual by James Dale Davidson',
            },
            {
                label: 'Bitcoin Privacy',
                value: 'https://www.lopp.net/bitcoin-information/privacy.html',
                displayValue: "Lopp Privacy Guide",
                type: 'link',
                tooltipText: 'Lopp.net Privacy Guide by Jameson Lopp',
            },
            {
                label: 'Origin of Money',
                value: 'https://nakamotoinstitute.org/shelling-out/',
                displayValue: "Shelling Out",
                type: 'link',
                tooltipText: 'Shelling Out: Origin of Money by Nick Szabo',
            },
        ]
        : [];

    const recommendedResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Start Here',
                value: 'https://bitcoin.org/bitcoin.pdf',
                displayValue: "Bitcoin Whitepaper",
                type: 'link',
                tooltipText: "Read the original Bitcoin whitepaper by Satoshi Nakamoto on the official Bitcoin.org website",
            },
            {
                label: 'Website',
                value: 'https://bitcoincore.org/',
                displayValue: "BitcoinCore.org",
                type: 'link',
                tooltipText: "Visit the official BitcoinCore.org website for news, resources, and information about Bitcoin",
            },
            {
                label: 'Code Repository',
                value: 'https://github.com/bitcoin/bitcoin',
                displayValue: "Bitcoin Core Github",
                type: 'link',
                tooltipText: "Visit the Github repository",
            },
            {
                label: 'Run a Full Node (Bitcoin Core)',
                value: 'https://bitcoincore.org/en/download/',
                displayValue: "Download Bitcoin Core",
                type: 'link',
                tooltipText: "Learn how to run a full Bitcoin node and support the network on the official BitcoinCore.org website",
            },
            {
                label: 'Mining Guide',
                value: 'https://www.bitcoinmining.com/',
                displayValue: "BitcoinMining.com",
                type: 'link',
                tooltipText: "Find resources and information about Bitcoin mining on BitcoinMining.com",
            },
            {
                label: 'Key Management',
                value: 'https://blog.keys.casa/the-dos-and-donts-of-bitcoin-key-management/',
                displayValue: "Dos and Don'ts",
                type: 'link',
                tooltipText: "Learn about the best practices for managing your Bitcoin keys on the Keys.Casa blog",
            },
            {
                label: 'Cold Storage',
                value: 'https://sparrowwallet.com/docs/coldcard-wallet.html',
                displayValue: "Sparrow + Coldcard",
                type: 'link',
                tooltipText: "Learn how to securely store your Bitcoin using the Sparrow wallet and Coldcard hardware wallet",
            },
            {
                label: 'Bitcoin Development',
                value: 'https://bitcoincore.org/en/contribute/',
                displayValue: "Contribute",
                type: 'link',
                tooltipText: "Explore the Bitcoin source code and get involved in Bitcoin development on the official BitcoinCore.org website",
            },
        ]
        : [];

    const satoshiResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/addresses/',
                displayValue: "Addresses",
                type: 'link',
                tooltipText: 'What did Satoshi say about Bitcoin Addresses?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/banks/',
                displayValue: "Banks",
                type: 'link',
                tooltipText: 'What did Satoshi say about Banks',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/bitcoin-design/',
                displayValue: "Bitcoin Design",
                type: 'link',
                tooltipText: 'What did Satoshi say about Bitcoin Design?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/economics/',
                displayValue: "Economics",
                type: 'link',
                tooltipText: 'What did Satoshi say about Economics?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/government/',
                displayValue: "Government",
                type: 'link',
                tooltipText: 'What did Satoshi say about Government?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/micropayments/',
                displayValue: "Micropayments",
                type: 'link',
                tooltipText: 'What did Satoshi say about Micropayments?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/mining/',
                displayValue: "Mining",
                type: 'link',
                tooltipText: 'What did Satoshi say about Mining?',
            },
            {
                label: 'Satoshi Nakamoto on...',
                value: 'https://satoshi.nakamotoinstitute.org/quotes/proof-of-work/',
                displayValue: "Proof-of-Work",
                type: 'link',
                tooltipText: 'What did Satoshi say about Proof-of-Work?',
            },
        ]
        : [];

    const educationResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Questions and Answers',
                value: 'https://qabitcoin.davidcoen.it/product/q-a-about-bitcoin-pay-in-btc/',
                displayValue: "Q/A About Bitcoin",
                type: 'link',
                tooltipText: 'A resource with a curated list of questions and answers about Bitcoin and its various aspects.',
            },
            {
                label: 'First Principles',
                value: 'https://voskuil.org/cryptoeconomics/cryptoeconomics.pdf',
                displayValue: "Cryptoeconomics",
                type: 'link',
                tooltipText: 'An in-depth resource on the fundamentals of cryptoeconomics and how it relates to Bitcoin.',
            },
            {
                label: 'Illustrated BTC Q&A',
                value: 'https://www.manning.com/books/grokking-bitcoin',
                displayValue: "Grokking Bitcoin",
                type: 'link',
                tooltipText: 'A comprehensive guide with illustrated answers to commonly asked questions about Bitcoin.',
            },
        ]
        : [];

    const programmingResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Developer Guides',
                value: 'https://developer.bitcoin.org/devguide/',
                displayValue: "Bitcoin.org Devs",
                type: 'link',
                tooltipText: 'A collection of developer guides and documentation for working with Bitcoin.',
            },
            {
                label: 'Programming Textbook',
                value: 'https://github.com/karask/bitcoin-textbook/blob/master/bitcoin-textbook.pdf',
                displayValue: "Bitcoin Programming",
                type: 'link',
                tooltipText: 'A textbook for learning how to program with Bitcoin, with a focus on practical application.',
            },
            {
                label: 'Mastering Bitcoin',
                value: 'https://github.com/bitcoinbook/bitcoinbook/blob/develop/book.asciidoc',
                displayValue: "Understanding Concepts",
                type: 'link',
                tooltipText: 'A guide for understanding the technical concepts behind Bitcoin and how it works.',
            },
        ]
        : [];

    const miningResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Getting Started',
                value: 'https://www.bitcoinmining.com/',
                displayValue: "BitcoinMining.com",
                type: 'link',
                tooltipText: 'A resource for beginners to learn about Bitcoin mining and how to get started.',
            },
            {
                label: 'Mining & Consensus',
                value: 'https://github.com/bitcoinbook/bitcoinbook/blob/develop/ch10.asciidoc',
                displayValue: "Bitcoin Book",
                type: 'link',
                tooltipText: 'A chapter from the "Mastering Bitcoin" book that provides an in-depth look at the mining process and its role in the Bitcoin consensus mechanism.',
            },
            {
                label: 'Timestamp Security',
                value: 'https://blog.lopp.net/bitcoin-timestamp-security/',
                displayValue: "Lopp Mining Guide",
                type: 'link',
                tooltipText: 'A guide by Jameson Lopp that provides an overview of timestamp security in Bitcoin mining and how it relates to the overall security of the network.',
            },
        ]
        : [];


    const nodeResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Core Node',
                value: 'https://bitcoincore.org/en/download/',
                displayValue: "Download Bitcoin Core",
                type: 'link',
                tooltipText: 'Bitcoin Core is the reference implementation of the Bitcoin network. Running a full node allows users to verify transactions and blocks, as well as participate in network consensus and help secure the network.',
            },
            {
                label: 'Lightning Node',
                value: 'https://github.com/lightning-power-users/node-launcher',
                displayValue: "Node Launcher",
                type: 'link',
                tooltipText: 'Node Launcher is a desktop application for easily setting up a Lightning node on the Bitcoin network. Lightning nodes facilitate off-chain transactions, which can be faster and cheaper than on-chain transactions.',
            },
            {
                label: 'Node Comparisons',
                value: 'https://bitcoiner.guide/node/',
                displayValue: "Bitcoiner.Guide",
                type: 'link',
                tooltipText: 'Bitcoiner.Guide is a comprehensive guide to running a Bitcoin node. The site provides comparisons of different node implementations, as well as detailed instructions for setting up and configuring a node.',
            },
        ]
        : [];


    const bipsResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'BIP Process, revised',
                value: 'https://github.com/bitcoin/bips/blob/master/bip-0002.mediawiki',
                displayValue: "BIP-2",
                type: 'link',
                tooltipText: 'BIP stands for Bitcoin Improvement Proposal. BIP-2 outlines the process for submitting, reviewing, and accepting proposals for changes to the Bitcoin network. Anyone can submit a proposal, and the community evaluates it for technical soundness and community support.',
            },
            {
                label: 'Mnemonic Seed Phrases',
                value: 'https://github.com/bitcoin/bips/blob/master/bip-0039.mediawiki',
                displayValue: "BIP-39",
                type: 'link',
                tooltipText: 'BIP-39 defines a standard way of generating a seed phrase to back up a Bitcoin wallet. A seed phrase is a list of words that can be used to restore a wallet in case of loss or theft. BIP-39 ensures that seed phrases can be generated and recovered in a standardized way across different wallet software and hardware.',
            },
            {
                label: 'Finite Monetary Supply',
                value: 'https://github.com/bitcoin/bips/blob/master/bip-0042.mediawiki',
                displayValue: "BIP-42",
                type: 'link',
                tooltipText: 'BIP-42 proposes a change to the Bitcoin network to make the maximum supply of Bitcoin finite. Currently, the maximum supply of Bitcoin is 21 million, but this is not explicitly stated in the protocol. BIP-42 aims to make this limit explicit and immutable.',
            },
        ]
        : [];


    const moneyResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Monetary Types',
                value: 'https://www.layeredmoney.com/',
                displayValue: "Layered Money",
                type: 'link',
                tooltipText: 'A book by Nik Bhatia that explores the history and development of money and monetary systems, and how Bitcoin fits into this picture.',
            },
            {
                label: 'About BTC',
                value: 'https://www.magicbitcoinbook.com/',
                displayValue: "Magic Internet Money",
                type: 'link',
                tooltipText: 'A book by Josep Busquet that aims to explain Bitcoin in a simple and intuitive way.',
            },
            {
                label: 'Bitcoin & Money',
                value: 'http://bitcoinmoneybook.com/',
                displayValue: "Bitcoin Money Book",
                type: 'link',
                tooltipText: 'A book by Michael Caras that examines the history and properties of money and how Bitcoin is different from traditional forms of currency.',
            },
        ]
        : [];


    const economicsResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Value Ecomonics',
                value: 'https://konsensus.network/product/bitcoin-everything-divided-by-21-million/?ref=knutsvanholm',
                displayValue: "Everything ÷ 21M",
                type: 'link',
                tooltipText: 'An exploration of the economic implications of Bitcoin and its finite supply.',
            },
            {
                label: 'Monetary Theory',
                value: 'https://www.amazon.com/gp/product/1999257405/',
                displayValue: "Price of Tomorrow",
                type: 'link',
                tooltipText: 'An analysis of the impact of technological progress on the economy and the monetary system.',
            },
            {
                label: 'Ethics in Money',
                value: 'https://www.lopp.net/pdf/books/The-Ethics-of-Money-Production.pdf',
                displayValue: "Monetary Production",
                type: 'link',
                tooltipText: 'A discussion of the ethical implications of money production and the history of monetary systems.',
            },
        ]
        : [];

    const historyResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoin History',
                value: 'https://www.amazon.com/dp/B08YQMC2WM',
                displayValue: "Blocksize War",
                type: 'link',
                tooltipText: 'A comprehensive history of the block size debate in Bitcoin.',
            },
            {
                label: 'Rest in Pieces',
                value: 'https://99bitcoins.com/deadcoins/',
                displayValue: "Dead Shitcoins",
                type: 'link',
                tooltipText: 'A list of cryptocurrencies that have failed and are no longer operational.',
            },
            {
                label: 'Meme Archives',
                value: 'https://bitcoinmemes.info/',
                displayValue: "BitcoinMemes.info",
                type: 'link',
                tooltipText: 'A collection of Bitcoin-related memes.',
            },
        ]
        : [];

    const freedomResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Personal Freedom',
                value: 'https://www.amazon.com/Sovereign-Individual-Mastering-Transition-Information/dp/0684832720/',
                displayValue: "Sovereign Individual",
                type: 'link',
                tooltipText: 'A book about the transition to a digital age and its impact on personal freedom and sovereignty.',
            },
            {
                label: 'Why Freedom Matters',
                value: 'https://www.amazon.com/dp/1641990503',
                displayValue: "Little Bitcoin Book",
                type: 'link',
                tooltipText: 'A collection of essays about why freedom matters and how Bitcoin promotes it.',
            },
            {
                label: 'Individualism',
                value: 'https://www.gutenberg.org/files/1250/1250-h/1250-h.htm',
                displayValue: "Anthem",
                type: 'link',
                tooltipText: 'A novella about individualism and the importance of personal freedom.',
            },
        ]
        : [];


    const tradingResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Technical Analysis 101',
                value: 'https://www.babypips.com/learn/forex',
                displayValue: "BabyPips.com",
                type: 'link',
                tooltipText: 'A beginner-friendly resource for learning technical analysis and trading strategies.',
            },
            {
                label: 'Fundamental Analysis 101',
                value: 'https://www.babypips.com/learn/forex/what-is-fundamental-analysis',
                displayValue: "BabyPips.com",
                type: 'link',
                tooltipText: 'A beginner-friendly resource for learning fundamental analysis and trading strategies.',
            },
            {
                label: "Don't Trade Bitcoin",
                value: 'https://www.fool.com/cryptocurrency/2022/04/23/why-i-dont-trade-bitcoin-in-the-short-term/',
                displayValue: "MotleyFool.com",
                type: 'link',
                tooltipText: 'An article that argues against short-term trading of Bitcoin and offers alternative investment strategies.',
            },
        ]
        : [];


    const investingResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Investment Theses',
                value: 'https://www.lopp.net/bitcoin-information/investment-theses.html',
                displayValue: 'Lopp.net',
                type: 'link',
                tooltipText: 'A collection of essays and analyses on Bitcoin investment theses by Jameson Lopp.',
            },
            {
                label: 'BTC Investing',
                value: 'https://pierre-rochard.medium.com/bitcoin-investment-theses-part-1-e97670b5389b',
                displayValue: 'Investment Theses',
                type: 'link',
                tooltipText: 'An essay on the investment theses for Bitcoin by Pierre Rochard.',
            },
            {
                label: 'MSTR Case for BTC',
                value: 'https://www.hope.com/',
                displayValue: 'Hope.com',
                type: 'link',
                tooltipText: 'A website created by Michale Saylor and MicroStrategy that presents a bullish case for Bitcoin.',
            },
        ]
        : [];


    const legalResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Global Regulations',
                value: 'https://tile.loc.gov/storage-services/service/ll/llglrd/2021687419/2021687419.pdf',
                displayValue: 'Congress Law Library',
                type: 'link',
                tooltipText: 'A report by the Law Library of Congress providing an overview of cryptocurrency regulations around the world.',
            },
            {
                label: 'Legality by Country',
                value: 'https://en.wikipedia.org/wiki/Legality_of_cryptocurrency_by_country_or_territory',
                displayValue: 'Wikipedia.org',
                type: 'link',
                tooltipText: 'A Wikipedia page providing information on the legality of cryptocurrency in various countries and territories.',
            },
            {
                label: 'Is Bitcoin Legal?',
                value: 'https://bitcoin.org/en/faq#is-bitcoin-legal',
                displayValue: 'Bitcoin.org FAQ',
                type: 'link',
                tooltipText: 'A frequently asked question on the official Bitcoin website regarding the legality of Bitcoin in various countries.',
            },
        ]
        : [];


    const bullishnessResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'High Level View',
                value: 'https://www.amazon.com/Bullish-Case-Bitcoin-Vijay-Boyapati/dp/1737204118',
                displayValue: "Bullish Case for BTC",
                type: 'link',
                tooltipText: 'A book that presents a compelling argument for the long-term bullish case for Bitcoin.',
            },
            {
                label: 'Understanding BTC',
                value: 'https://yakes.io/book/',
                displayValue: "The 7th Property",
                type: 'link',
                tooltipText: 'A book that explores the unique properties of Bitcoin and why it is so important.',
            },
            {
                label: 'Reasons Why',
                value: 'https://whybitcoinbook.com/',
                displayValue: "Why Bitcoin?",
                type: 'link',
                tooltipText: 'A book that explains why Bitcoin matters and why it has the potential to change the world.',
            },
            {
                label: 'First Principles',
                value: 'https://voskuil.org/cryptoeconomics/cryptoeconomics.pdf',
                displayValue: "Cryptoeconomics",
                type: 'link',
                tooltipText: 'A book that provides an introduction to the principles of cryptoeconomics, which underpin Bitcoin and other cryptocurrencies.',
            },
            {
                label: 'Illustrated BTC Q&A',
                value: 'https://www.manning.com/books/grokking-bitcoin',
                displayValue: "Grokking Bitcoin",
                type: 'link',
                tooltipText: 'A book that explains Bitcoin using easy-to-understand illustrations and real-world examples.',
            },
        ]
        : [];

    const gameTheoryResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoin Adoption',
                value: 'https://medium.com/@beautyon_/bitcoin-adoption-and-conway-s-game-of-life-54ca71db7b88',
                displayValue: "Game of Life",
                type: 'link',
                tooltipText: `An article that explores the adoption of Bitcoin in the context of Conway's Game of Life, a cellular automaton that demonstrates how simple rules can lead to complex, emergent behavior.`,
            },
            {
                label: 'Banning Bitcoin',
                value: 'https://blogs.cornell.edu/info2040/2021/09/19/the-global-prisoners-dilemma-of-crypto/',
                displayValue: "Prisoner's Dilemma",
                type: 'link',
                tooltipText: `An article discussing the global prisoner's dilemma of banning cryptocurrencies like Bitcoin, highlighting the game theory implications for countries considering regulation.`,
            },
            {
                label: 'Game Theory: Bitcoin',
                value: 'https://bitcoinmagazine.com/culture/the-bitcoin-dilemma',
                displayValue: "BitcoinMagazine.com",
                type: 'link',
                tooltipText: 'An article examining Bitcoin through the lens of game theory, including the incentives that drive its adoption and how its decentralized nature impacts various players in the ecosystem.',
            },
            {
                label: 'BTC Game Theory',
                value: 'https://www.nasdaq.com/articles/a-look-at-the-game-theory-of-bitcoin-2021-09-13',
                displayValue: "Nasdaq.com",
                type: 'link',
                tooltipText: 'An article that provides an overview of the game theory principles behind Bitcoin, discussing its consensus mechanism, mining, and the role of incentives in its design.',
            },
            {
                label: 'BTC Possibilities',
                value: 'https://bitcoinmagazine.com/culture/bitcoin-has-endless-possibilities',
                displayValue: "BitcoinMagazine.com",
                type: 'link',
                tooltipText: 'An article that delves into the numerous possibilities of Bitcoin, touching upon its potential to disrupt traditional financial systems and its role as a catalyst for innovation.',
            },
        ]
        : [];

    const securityResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoin Support',
                value: 'https://guides.bitcoinsupport.com/guides/',
                displayValue: "Bull Bitcoin",
                type: 'link',
                tooltipText: 'Bull Bitcoin offers a range of guides to help you secure your bitcoin, including how to create and manage your own cold storage wallet.',
            },
            {
                label: 'Beginner Security',
                value: 'https://www.lopp.net/pdf/Jolly_Rogers_Security_Guide_for_Beginners.pdf',
                displayValue: "Lopp.net",
                type: 'link',
                tooltipText: 'Lopp.net offers a beginner-friendly guide to bitcoin security, covering everything from securing your computer to safely storing your private keys.',
            },
            {
                label: 'Security Guides',
                value: 'https://www.lopp.net/bitcoin-information/security.html',
                displayValue: "Lopp.net",
                type: 'link',
                tooltipText: 'Lopp.net also provides a range of advanced security guides for bitcoin, including securing multisignature wallets and using hardware wallets.',
            },
            {
                label: 'Security & Hardening',
                value: 'https://github.com/decalage2/awesome-security-hardening',
                displayValue: "Awesome Security",
                type: 'link',
                tooltipText: 'Awesome Security is a collection of security resources and guides, including those specifically related to hardening your computer and online accounts.',
            },
            {
                label: 'Deep Dive',
                value: 'https://blog.lopp.net/bitcoins-security-model-a-deep-dive/',
                displayValue: "BTC Security Model",
                type: 'link',
                tooltipText: 'Lopp.net also provides an in-depth look at the security model of bitcoin, covering everything from its decentralized network to its cryptography and incentives.',
            },
        ]
        : [];

    const privacyResourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'About PGP',
                value: 'https://www.philzimmermann.com/EN/essays/WhyIWrotePGP.html',
                displayValue: "PhilZimmermann.com",
                type: 'link',
                tooltipText: 'Find out why Phil Zimmermann wrote PGP',
            },
            {
                label: 'Privacy Matters',
                value: 'https://www.schneier.com/blog/archives/2006/05/the_value_of_pr.html',
                displayValue: "Value of Privacy",
                type: 'link',
                tooltipText: 'An article on the importance of privacy.',
            },
            {
                label: 'Recommended Reading',
                value: 'https://www.reddit.com/r/privacy/wiki/recommended_reading',
                displayValue: "Reddit.com",
                type: 'link',
                tooltipText: 'A list of recommended reading on privacy.',
            },
            {
                label: 'Privacy Guides',
                value: 'https://www.lopp.net/bitcoin-information/privacy.html',
                displayValue: "Lopp.net",
                type: 'link',
                tooltipText: 'Guides on how to improve your privacy.',
            },
            {
                label: 'Privacy Guides',
                value: 'https://bitcoin.org/en/protect-your-privacy',
                displayValue: "Bitcoin.org",
                type: 'link',
                tooltipText: 'Guides on how to protect your privacy with Bitcoin.',
            },
        ]
        : [];

    const readingData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Start Here',
                value: 'https://bitcoin.org/bitcoin.pdf',
                displayValue: 'Bitcoin Whitepaper',
                type: 'link',
                tooltipText: 'The original whitepaper written by Satoshi Nakamoto describing the concept of Bitcoin',
            },
            {
                label: 'Understanding Money',
                value: 'https://saifedean.com/book/',
                displayValue: 'The Bitcoin Standard',
                type: 'link',
                tooltipText: 'A book that explains Bitcoin in the context of monetary history and economics',
            },
            {
                label: 'History of Money',
                value: 'https://nakamotoinstitute.org/shelling-out/',
                displayValue: 'Shelling Out',
                type: 'link',
                tooltipText: 'A book that explores the history of money and how Bitcoin fits into it',
            },
            {
                label: 'Technical Resources',
                value: 'https://aantonop.com/product/mastering-bitcoin-2nd-edition-ebook/',
                displayValue: 'Mastering Bitcoin',
                type: 'link',
                tooltipText: 'A book that provides a technical introduction to Bitcoin and the underlying technology',
            },
            {
                label: 'List of Dead Shitcoins',
                value: 'https://99bitcoins.com/deadcoins/',
                displayValue: 'DeadCoins.com',
                type: 'link',
                tooltipText: 'A website that lists dead and abandoned cryptocurrencies',
            },
        ]
        : [];

    const resourcesData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Official Website',
                value: 'https://bitcoincore.org',
                displayValue: 'BitcoinCore.org',
                type: 'link',
                tooltipText: 'The official website of the Bitcoin project',
            },
            {
                label: 'Information',
                value: 'https://www.lopp.net/bitcoin-information.html',
                displayValue: 'Lopp Resources',
                type: 'link',
                tooltipText: 'A collection of resources about Bitcoin curated by Jameson Lopp',
            },
            {
                label: 'Current Events',
                value: 'https://www.youtube.com/channel/UCVfMv5xEfrafk1rSthJ0t9g',
                displayValue: 'Pleb Underground',
                type: 'link',
                tooltipText: 'A YouTube channel focused on Bitcoin news and events',
            },
            {
                label: 'Bitcoin Glossary API',
                value: 'https://btclexicon.com/api/v2/terms',
                displayValue: 'BTCLexicon API v2',
                type: 'link',
                tooltipText: 'A glossary of Bitcoin terms and definitions available as a REST API',
            },
            {
                label: 'Quotes by Satoshi Nakamoto',
                value: 'https://nakamotoinstitute.org/',
                displayValue: 'Nakamoto Institute',
                type: 'link',
                tooltipText: 'A collection of writings and quotes by Bitcoin creator Satoshi Nakamoto',
            },
        ]
        : [];

    const bitcoinFixesThisData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Bitcoin fixes Gold',
                value: 'By making it digital',
                tooltipText: "Gold cannot move freely around the world; it is heavy, cannot be backed-up or encrypted, it's hard to verify that it's real, and it's difficult, expensive, and slow to move around. Bitcoin fixes all of these problems, plus provides programmability and other features necessary for the modern electronic world.",
            },
            {
                label: 'Bitcoin fixes Bank Holidays',
                value: 'By being open 24/7',
                tooltipText: "Bitcoin never closes. Bitcoin users can transact whenever they want, wherever they want, without anyone else's permission.",
            },
            {
                label: 'Bitcoin fixes Bailouts',
                value: 'Harder to print money',
                tooltipText: "Currently, most governments can print as much money as they want, and they use this capability to bail out financial institutions when their risky bets don't pan out. Ultimately taxpayers and ordinary citizens pay for this via inflation (see the Cantillon Effect). In a world where bitcoin adoption is high, governments would not have the ability to print money at anywhere near the scale they can now, thus large-scale financial bailouts would be impossible.",
            },
            {
                label: 'Bitcoin fixes Inflation',
                value: 'Hard-capped supply',
                tooltipText: "Since governments can currently print more money whenever they want, politicians will always promise things to the electorate and rely on central banks to fund the spending through inflation. The citizenry will pay for this via the Cantillon Effect, which is one reason why inflation is bad. In a world where bitcoin adoption is high, governments couldn't print more money at will and therefore excessive inflation would be impossible.",
            },
            {
                label: 'Bitcoin fixes Volatility',
                value: 'Low time preference',
                tooltipText: "A theme in the fiat economy is artificial suppression of risk and volatility by central banks and politicians, which inevitably leads to massive harmful volatility later on. Bitcoin forces people to accept natural short term volatility in order to forgo the harmful much larger long-term black-swan volatility events. This leads to greater long-run prosperity.",
            },
        ]
        : [];

    const walletsData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Cold Storage',
                value: 'https://sparrowwallet.com/docs/coldcard-wallet.html',
                displayValue: 'Coldcard + Sparrow',
                type: 'link',
                tooltipText: 'A hardware wallet that offers the highest level of security by keeping your private keys offline, while using an air-gapped computer to sign transactions.',
            },
            {
                label: 'Hardware Wallet',
                value: 'https://coldcardwallet.com/',
                displayValue: 'Coldcard',
                type: 'link',
                tooltipText: 'A hardware wallet that offers advanced security features, including the ability to use multi-sig and the option to store your seed phrase offline.',
            },
            {
                label: 'Mobile Wallet',
                value: 'https://breez.technology/',
                displayValue: 'Breez Mobile',
                type: 'link',
                tooltipText: 'A mobile wallet that offers Lightning Network capabilities, making it easy to send and receive bitcoin quickly and cheaply.',
            },
            {
                label: 'Desktop Wallet',
                value: 'https://sparrowwallet.com/',
                displayValue: 'Sparrow Wallet',
                type: 'link',
                tooltipText: 'A desktop wallet that offers advanced security features, including the option to use multi-sig and to store your seed phrase offline.',
            },
            {
                label: 'Physical Wallet',
                value: 'https://seedplate.com/',
                displayValue: 'Seed Plate',
                type: 'link',
                tooltipText: 'A physical backup solution that allows you to store your seed phrase in a durable and tamper-resistant metal plate.',
            },
        ]
        : [];

    const visualizationsData = data && bitcoinPrice && conversionRates && selectedCurrency
        ? [
            {
                label: 'Live Node Map',
                value: 'https://bitnodes.io/nodes/live-map/',
                displayValue: 'Bitnodes.io',
                type: 'link',
                tooltipText: 'Visualize live Bitcoin nodes on a map',
            },
            {
                label: 'Block Explorer',
                value: 'https://bitfeed.live/',
                displayValue: 'Bitfeed.live',
                type: 'link',
                tooltipText: 'Explore Bitcoin blocks, transactions and addresses',
            },
            {
                label: 'Timechain History',
                value: 'https://timechaincalendar.com/',
                displayValue: 'TimechainCalendar.com',
                type: 'link',
                tooltipText: 'Visualize the history of the Bitcoin blockchain',
            },
            {
                label: 'Bitcoin Transactions',
                value: 'http://bitcoin.interaqt.nl/',
                displayValue: 'Bitcoin.interaqt.nl',
                type: 'link',
                tooltipText: 'Visualize Bitcoin transactions as they happen in real time',
            },
            {
                label: 'Chain Visualization',
                value: 'http://bitcoincity.info/',
                displayValue: 'BitcoinCity.info',
                type: 'link',
                tooltipText: 'Visualize Bitcoin blocks, transactions and network data in a 3D cityscape',
            },
        ]
        : [];

    // Function to filter content based on the current filter state
    const filterContent = (contentFilters, showOnAll = true) => {
        // If the current filter is 'All', return the showOnAll parameter
        if (filter === 'All') {
            return showOnAll;
        } else {
            // Otherwise, check if the contentFilters array includes the current filter
            return contentFilters.includes(filter);
        }
    };

    // // Helper function for calculating standard deviation
    // function calculateStandardDeviation(values) {
    //     const avg = values.reduce((a, b) => a + b) / values.length;
    //     const squareDiffs = values.map(value => Math.pow(value - avg, 2));
    //     const avgSquareDiff = squareDiffs.reduce((a, b) => a + b) / squareDiffs.length;
    //     return Math.sqrt(avgSquareDiff);
    // }

    // console.log({
    //     bitcoinPrice,
    //     data,
    //     lastMempoolBlockData,
    //     fees,
    //     mempoolInfo,
    //     da,
    //     blocks,
    // });

    if (!data || !bitcoinPrice || !lastMempoolBlockData || !fees || !mempoolInfo || !da || !conversionRates || !selectedCurrency) {
        return <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="85vh" ><CustomSpinner /><Text mt={4}>Building interface...</Text></Box>;
    }

    return (
        <ContentWrapper>
            {/* <TcsAlert /> */}


            <DataBanner
                data={data}
                formatNumber={formatNumber}
                formatLargeNumber={formatLargeNumber}
                bitcoinPrice={bitcoinPrice}
                lastMempoolBlockData={lastMempoolBlockData}
                fees={fees}
                mempoolInfo={mempoolInfo}
                da={da}
            />
            <div className="navbar-container">
                <Navbar filter={filter} setFilter={setFilter} />
            </div>

            {filterContent([''], true) && (
                <Text fontSize="xs" mb={2} color="gray.700" >LIVE BITCOIN PRICE DATA</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >
                {filterContent([''], true) && (
                    lastBlockTableData ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 24.5%' }}
                            className={animateLastBlock ? 'orange-border' : ''}
                        >
                            <DataTable
                                title={dataTableTitle}
                                subtitle='What happened in the last block?'
                                icon={<FontAwesomeIcon icon={faCube} fontSize="20px" />}
                                rows={lastBlockTableData}
                                animateLastBlock={animateLastBlock}
                                link="https://bitfeed.live/"
                                linkLabel='Explore Block'
                                footerIcon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
                                showIcon={true}
                                // specificTerm="Block"
                                onOpenLexicon={handleOpenLexicon}
                                rowsPerPage={9} // adjust for pagination                           
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent([''], true) && (
                    data && data.ts04 && data.ts04.hashrate && data.ts15 && data.ts15.price != null ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 46%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW="100%"
                        >
                            <HashRatePriceTile
                                title="Bitcoin Price & Hashrate"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                                uniqueChartId="chart-5"
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent([''], true) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 24%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                        >
                            <RewardsToFeesTile
                                uniqueChartId="RewardsToFeesTile-9"
                                title="Fees vs. Subsidy"
                                subtitle="What % of recent rewards were fees?"
                                icon={<FontAwesomeIcon icon={faChartPie} fontSize="20px" />}
                                data={data}
                                blocks={blocks}
                                lastMempoolBlockData={lastMempoolBlockData}
                                formatLargeNumber={formatLargeNumber}
                            />

                        </Flex>
                    ) : <CustomSpinner />
                )}


            </Flex>

            {filterContent(['Network'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >BITCOIN NETWORK</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Network'], false) && (
                    <Flex flex={{ base: '1 0 100%', md: '1 0 32.75%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <DataTable
                            title="BTC Network"
                            subtitle="Recommended BTC resources"
                            icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                            rows={recommendedResourcesData}
                            link=""
                            specificTerm="Network"
                            onOpenLexicon={handleOpenLexicon}
                        />
                    </Flex>
                )}

                {filterContent(['Network'], false) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 66.25%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <TransactionsAndHashRateTile
                                uniqueChartId="TransactionsAndHashRateTile-2"
                                title="Transactions / Hashrate"
                                subtitle="Logarithmic Scale"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : ''
                )}


            </Flex>

            {filterContent(['Mining'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >BITCOIN MINING</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >


                {filterContent(['Mining'], false) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 49.54%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <HashRatePriceTile
                                uniqueChartId="HashRatePriceTile-2"
                                title="Bitcoin Mining"
                                subtitle="Hashrate and Price"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Mining'], false) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 49.54%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <RewardsToFeesTile
                                uniqueChartId="RewardsToFeesTile-2"
                                title="Fees vs. Subsidy"
                                subtitle="Fee percentage of mining rewards over the last 144 blocks"
                                icon={<FontAwesomeIcon icon={faChartPie} fontSize="20px" />}
                                data={data}
                                blocks={blocks}
                                lastMempoolBlockData={lastMempoolBlockData}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}


            </Flex>

            {filterContent(['Market'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >BITCOIN MARKET</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Market'], false) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 24.5%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <VolumeTile
                                uniqueChartId="VolumeTile-5"
                                title="BTC Market"
                                subtitle="Trading volume on major bitcoin exchanges"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Market'], false) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 48.54%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <ChartTile
                                uniqueChartId="ChartTile-5"
                                title="Bitcoin Price Chart"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatNumber={formatNumber}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Market'], false) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 24%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <MarketCapTile
                                uniqueChartId="MarketCapTile-5"
                                title="Bitcoin Market Cap"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}


            </Flex>

            {filterContent(['Charts'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >BITCOIN CHARTS</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 48.5%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <PoolsTopTenTile
                                uniqueChartId="PoolsTopTenTile-2"
                                title="Bitcoin Charts"
                                subtitle="Top pools by block count (3y)"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatNumber={formatNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 48.5%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <HashRateMempoolSizeTile
                                uniqueChartId="HashRateMempoolSizeTile-2"
                                title="Hashrate / Mempool"
                                subtitle="1-hour rolling average"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}


            </Flex>

            {filterContent(['Resources'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >BITCOIN RESOURCES</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Resources'], false) && (
                    <Flex
                        flex={{ base: '1 0 100%', md: '1 1 24.5%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <DataTable
                            title="Bitcoin Resources"
                            subtitle="Popular Bitcoin resources"
                            icon={<FontAwesomeIcon icon={faFireAlt} fontSize="20px" />}
                            rows={popularResourcesData}
                            link=""
                        />
                    </Flex>
                )}

                {filterContent(['Resources'], false) && (
                    <Flex flex={{ base: '1 0 100%', md: '1 0 49.54%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <DataTable
                            title="New to Bitcoin?"
                            subtitle="Recommended BTC resources"
                            icon={<FontAwesomeIcon icon={faPlay} fontSize="20px" />}
                            rows={recommendedResourcesData}
                            link=""
                        />
                    </Flex>
                )}

                {filterContent(['Resources'], false) && (
                    <Flex
                        flex={{ base: '1 0 100%', md: '1 1 24%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <DataTable
                            title="Writings of Satoshi"
                            subtitle="Satoshi's writings on various topics"
                            icon={<FontAwesomeIcon icon={faPenFancy} fontSize="20px" />}
                            rows={satoshiResourcesData}
                            link=""
                        />
                    </Flex>
                )}

            </Flex>

            {/* {filterContent(['Mining'], true) && (
                <Text fontSize="xs" mb={2} color="gray.700" >LATEST BITCOIN BLOCKS</Text>
            )} */}

            {filterContent(['Mining'], true) && (
                data && bitcoinPrice ? ( // ensure the check for correct data
                    <Flex
                        flexDirection={{ base: 'column', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 0, md: 3 }}
                    >

                        <RecentBlocks animate={animateLastBlock} bitcoinPrice={bitcoinPrice} data={data} />


                    </Flex>
                ) : <CustomSpinner />
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Mining', 'Charts'], true) && (
                    lastMempoolBlockData ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                        >
                            <DifficultyAdjustmentTile
                                title="Next Difficulty Adjust."
                                subtitle="When is the next diff. adjustment?"
                                icon={<FontAwesomeIcon icon={faGears} fontSize="20px" />}
                                lastMempoolBlockData={lastMempoolBlockData}
                                da={da}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Mining', 'Charts'], true) && (
                    lastMempoolBlockData && da ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                        >
                            <ProgressTile
                                title="Next Bitcoin Halving"
                                subtitle="When is the next Bitcoin halving?"
                                icon={<FontAwesomeIcon icon={faScissors} fontSize="20px" />}
                                lastMempoolBlockData={lastMempoolBlockData}
                                da={da}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

            </Flex>


            {filterContent([''], true) && (
                data ? (
                    <Flex
                        flexDirection={{ base: 'row', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 3, md: 3 }}
                    >
                        <DataTileTargetBlockTime data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTileRealBlockTime data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTileCirculatingSupply data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTileMaximumSupply data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTileLeftToMine data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTilePercentMined data={data} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    </Flex>
                ) : <CustomSpinner />
            )}


            {filterContent(['Network'], false) && (
                data ? (
                    <Flex
                        flexDirection={{ base: 'row', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 3, md: 3 }}
                    >
                        <NetworkTileTimechainReachable data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <NetworkTileAge data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <NetworkTileAvgUtxoAge data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <NetworkTileBlocks24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <NetworkTileBtcPerCountry data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <DataTileRealBlockTime data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />

                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent(['Market'], false) && (
                data ? (
                    <Flex
                        flexDirection={{ base: 'row', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 3, md: 3 }}
                    >
                        <MarketTileExchangeFlowIn data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MarketTileExchangeFlowOut data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MarketTileAllTimeHigh data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MarketTileAllTimeLow data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MarketTileAthPercentage data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MarketTileBtcToGold data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />

                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent(['Mining'], false) && (
                data && bitcoinPrice ? (
                    <Flex
                        flexDirection={{ base: 'row', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 3, md: 3 }}
                    >
                        <MiningTileHashRate data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MiningTileDifficulty data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MiningTileRewardBtc data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MiningTileRewardFees data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MiningTileMempoolTxs mempoolInfo={mempoolInfo} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <MiningTileMempoolSize formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} mempoolInfo={mempoolInfo} />
                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent([''], false) && (
                data ? (
                    <Flex
                        flexDirection={{ base: 'row', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 3, md: 3 }}
                    >
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                        <ChartTileTransactions24h data={data} formatNumber={formatNumber} formatLargeNumber={formatLargeNumber} flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent(['Resources'], false) && (
                <Flex
                    flexDirection={{ base: 'row', md: 'row' }}
                    flexWrap="wrap"
                    columnGap={{ base: 3, md: 3 }}
                >
                    <ResourceTileWhitepaper flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    <ResourceTileShellingOut flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    <ResourceTileCypherpunkManifesto flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    <ResourceTileDevGuides flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    <ResourceTileBips flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                    <ResourceTileWiki flex={{ base: '1 0 100%', md: '1 0 49.54%' }} animate={animateLastBlock} />
                </Flex>
            )}

            {filterContent(['Charts', 'Mining'], true) && (
                blocks ? (
                    <Flex
                        flexDirection={{ base: 'column', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 0, md: 3 }}
                    >
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                            mb={{ base: 3, md: 0 }}
                        >
                            <BitcoinBlocksChart
                                uniqueChartId="BitcoinBlocksChart-1"
                                title="Bitcoin Block Time vs. Size"
                                subtitle="Block size and time for recent blocks"
                                icon={<FontAwesomeIcon icon={faChartBar} fontSize="20px" />}
                                blocks={blocks}
                                lastMempoolBlockData={lastMempoolBlockData}
                                formatLargeNumber={formatLargeNumber}
                                formatNumber={formatNumber}
                            />
                        </Flex>

                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <BitcoinTxVsTotalFeesChart
                                uniqueChartId="BitcoinTxVsTotalFeesChart-1"
                                title="Bitcoin Transactions vs. Fees"
                                subtitle="Transactions and fees for recent blocks"
                                icon={<FontAwesomeIcon icon={faClock} fontSize="20px" />}
                                blocks={blocks}
                                formatNumber={formatNumber}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent([''], true) && (
                <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    flexWrap="wrap"
                    columnGap={{ base: 0, md: 3 }}
                >
                    <Flex flex={{ base: '1 0 100%', md: '1 1 39.25%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <WordTile
                            title="What is Bitcoin?"
                            subtitle="A peer-to-peer electronic cash system"
                            icon={<FontAwesomeIcon icon={faCircleQuestion} fontSize="20px" />}
                            body="Bitcoin is a unique, decentralized, peer-to-peer transaction network featuring a finite, programmable native digital currency. Bitcoin (BTC), the intrinsic asset of the Bitcoin blockchain, operates autonomously, free from central authorities or administrators. The Bitcoin network's stability is achieved through the collaborative efforts of full nodes, miners, and developers. Its foundation lies in a user-established and user-agreed social contract, which is strengthened by the strategic use of game theory and sophisticated cryptography. Bitcoin's design ensures censorship-resistance, permissionless access, and a reliable store of value, making it an innovative and secure form of digital money."
                        />
                    </Flex>
                    <Flex flex={{ base: '1 0 100%', md: '1 0 20%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <ImageTile
                            src="https://timechainstats.com/img/img-11d.png"
                            width="240"
                            height="240"
                            link="https://bitcoincore.org"
                        />
                    </Flex>
                    <Flex flex={{ base: '1 0 100%', md: '1 1 39.25%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <QuoteTile
                            title="Satoshi Nakamoto Said..."
                            subtitle="Random quote from the creator(s) of Bitcoin"
                            icon={<FontAwesomeIcon icon={faUserSecret} fontSize="20px" />}
                        />
                    </Flex>
                </Flex>
            )}

            {filterContent(['Market'], true) && (
                data && bitcoinPrice ? (
                    <Flex
                        flexDirection={{ base: 'column', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 0, md: 3 }}
                    >


                        <DataTable
                            title="Bitcoin vs Fiat"
                            subtitle="Live Bitcoin price in various currencies***"
                            icon={<FontAwesomeIcon icon={faMedal} fontSize="20px" />}
                            // highlightedData={highlightedDataMarket}
                            rows={currencies1Data}
                            link=""
                            animate={animateLastBlock}
                            specificTerm="Fiat Currency"
                            onOpenLexicon={handleOpenLexicon}
                        />


                        <DataTable
                            title=""
                            subtitle=""
                            icon=""
                            // highlightedData={highlightedDataMarket}
                            rows={currencies2Data}
                            link=""
                            animate={animateLastBlock}
                        />



                        <DataTable
                            title=""
                            subtitle=""
                            icon=""
                            rows={currencies3Data}
                            link=""
                            animate={animateLastBlock}
                        />


                        <DataTable
                            title=""
                            subtitle=""
                            icon=""
                            rows={currencies4Data}
                            link=""
                            animate={animateLastBlock}
                        />

                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent([''], true) && (
                <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    flexWrap="wrap"
                    columnGap={{ base: 0, md: 3 }}
                >
                    <DataTable
                        title="What is a Sat Worth?"
                        subtitle="How much fiat can a sat buy?"
                        icon={<FontAwesomeIcon icon={faRightLeft} fontSize="20px" />}
                        rows={whatIsASatWorthData}
                        animate={animateLastBlock}
                        onOpenLexicon={handleOpenLexicon}
                    />

                    <DataTable
                        title="What is Fiat Worth?"
                        subtitle="How much BTC can fiat currency buy?"
                        icon={<FontAwesomeIcon icon={faDollar} fontSize="20px" />}
                        rows={whatIsAUsdWorthData}
                        animate={animateLastBlock}
                        onOpenLexicon={handleOpenLexicon}
                    />

                    <DataTable
                        title="Bitcoin Breakdown"
                        subtitle="Dividing Bitcoin's total supply by group"
                        icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                        rows={bitcoinBreakdownData}
                        animate={animateLastBlock}
                        onOpenLexicon={handleOpenLexicon}
                    />
                </Flex>
            )}

            {filterContent([''], true) && (
                <Text fontSize="xs" mb={2} color="gray.700" >LATEST BITCOIN METRICS</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Charts'], true) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 1 24.5%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <VolumeTile
                                uniqueChartId="VolumeTile-1"
                                title="Exchange Volume"
                                subtitle="Trading volume on major bitcoin exchanges"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Charts'], true) && (
                    data ? (
                        <Flex
                            flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <ChartTile
                                uniqueChartId="ChartTile-2"
                                title="Bitcoin Price Chart"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatNumber={formatNumber}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}


                {filterContent(['Charts'], true) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 24.5%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <MarketCapTile
                                uniqueChartId="MarketCapTile-1"
                                title="Bitcoin Market Cap"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                formatLargeNumber={formatLargeNumber}
                                data={data}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Network'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <LightningNodesTile
                                uniqueChartId="LightningNodesTile-2"
                                title="Lightning Nodes"
                                subtitle="Breakdown of Lightning nodes"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <LightningNodesByCountryTile
                                uniqueChartId="LightningNodesByCountryTile-2"
                                title="Lightning by Country"
                                subtitle="Top 12 by node count or capacity"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent([''], true) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <TransactionsTile
                                title="Transactions"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 100%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <HashRateTile
                                title="Hash Rate"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

            </Flex>

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Network'], true) && (
                    <DataTable
                        title="Bitcoin Network"
                        subtitle="Information about the BTC network"
                        icon={<FontAwesomeIcon icon={faBitcoinSign} fontSize="20px" />}
                        rows={bitcoinNetworkData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Bitcoin Network"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={8}

                    />
                )}

                {filterContent(['Network', 'Mining'], true) && (
                    <DataTable
                        title="Network Security"
                        subtitle="Protecting the Bitcoin timechain"
                        icon={<FontAwesomeIcon icon={faLock} fontSize="20px" />}
                        rows={networkSecurityData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Network Security"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={8}
                    />
                )}

                {filterContent(['Network'], false) && (
                    data ? (
                        <DataTable
                            title="Lightning Network"
                            subtitle="Bitcoin Lightning Network stats"
                            icon={<FontAwesomeIcon icon={faBoltLightning} fontSize="20px" />}
                            rows={lightningData}
                            link=""
                            animate={animateLastBlock}
                            specificTerm="Lightning Network"
                            onOpenLexicon={handleOpenLexicon}
                            rowsPerPage={9}
                        />
                    ) : <CustomSpinner />
                )}

                {filterContent(['Market', 'Network'], false) && (
                    data ? (
                        <DataTable
                            title="Network Fees"
                            subtitle="Data about fees across the network"
                            icon={<FontAwesomeIcon icon={faCoins} fontSize="20px" />}
                            rows={feesData}
                            link=""
                            animate={animateLastBlock}
                            specificTerm="Fees"
                            onOpenLexicon={handleOpenLexicon}
                        // rowsPerPage={9}
                        />
                    ) : <CustomSpinner />
                )}

                {filterContent(['Market'], true) && (
                    <DataTable
                        title="Market Data"
                        subtitle="BTC~USD exchange price data"
                        icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                        // highlightedData={highlightedDataMarket}
                        rows={marketStatsData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Market Data"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={8}
                    />
                )}

                {filterContent(['Market'], true) && (
                    <DataTable
                        title="Change Percentages"
                        subtitle="Bitcoin price change percentages"
                        icon={<FontAwesomeIcon icon={faPercent} fontSize="20px" />}
                        rows={changePercentageData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Change Percentages"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={8}
                    />
                )}

                {filterContent(['Mining'], true) && (
                    <DataTable
                        title="Mining Info"
                        subtitle="Informative datapoints: BTC mining"
                        icon={<FontAwesomeIcon icon={faGem} fontSize="20px" />}
                        rows={miningInfoData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Mining"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={9}
                    />
                )}

                {filterContent(['Network', 'Mining'], true) && (
                    <DataTable
                        title="Bitcoin Supply"
                        subtitle="Distribution schedule and other details"
                        icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                        rows={bitcoinSupplyData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Bitcoin Supply"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={9}
                    />
                )}

                {filterContent(['Network', 'Mining'], true) && (
                    <DataTable
                        title="Bitcoin Halving"
                        subtitle="Details about Bitcoin halvings"
                        icon={<FontAwesomeIcon icon={faScissors} fontSize="20px" />}
                        rows={bitcoinHalvingData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Halving"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={9}
                    />
                )}

                {filterContent(['Network', 'Mining'], true) && (
                    <DataTable
                        title="Future Bitcoin Halvings"
                        subtitle="Track upcoming BTC halving events"
                        icon={<FontAwesomeIcon icon={faCalendarAlt} fontSize="20px" />}
                        rows={futureBitcoinHalvingData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Distribution Schedule"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={9}
                    />
                )}

                {filterContent(['Market'], true) && data && velocityData && velocityData.length > 0 && !velocityData.some(item => item.value === "Loading...") && (
                    <Flex
                        flex={{ base: '1 0 100%', md: '1 1 48.5%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                        maxW={'100%'}
                    >
                        <DataTable
                            title="Velocity"
                            subtitle="Circulation speed of Bitcoin in the economy"
                            icon={<FontAwesomeIcon icon={faFastForward} fontSize="20px" />}
                            rows={velocityData}
                            link=""
                            animate={animateLastBlock}
                            specificTerm="Velocity of Money"
                            onOpenLexicon={handleOpenLexicon}
                            rowsPerPage={6}
                        />
                    </Flex>
                )}

                {filterContent(['Network'], true) && data && mempoolData && mempoolData.length > 0 && !mempoolData.some(item => item.value === "Loading...") && (
                    <Flex
                        flex={{ base: '1 0 100%', md: '1 1 48.5%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                        maxW={'100%'}
                    >
                        <DataTable
                            title="Mempool"
                            subtitle="Pending transactions awaiting confirmation"
                            icon={<FontAwesomeIcon icon={faFastForward} fontSize="20px" />}
                            rows={mempoolData}
                            link=""
                            animate={animateLastBlock}
                            specificTerm="Mempool"
                            onOpenLexicon={handleOpenLexicon}
                            rowsPerPage={6}
                        />
                    </Flex>
                )}

                {filterContent(['Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <AvgRewardsOverTimeTile
                                title="Average Rewards"
                                subtitle="Average mining rewards"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <RewardsToFeesTile
                                uniqueChartId="RewardsToFeesTile-1"
                                title="Mining Reward vs. Fees"
                                subtitle="Fee percentage of reward over the last 144 blocks"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                blocks={blocks}
                                lastMempoolBlockData={lastMempoolBlockData}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}


                {/* {filterContent(['Charts'], false) && (
                    blocks ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <BitcoinMiningChart
                                uniqueChartId="BitcoinMiningChart-1"
                                title="Bitcoin Mining Statistics"
                                subtitle="Mining difficulty, block rewards, and total fees per block"
                                icon={<FontAwesomeIcon icon={faCube} fontSize="20px" />}
                                blocks={blocks}
                                formatNumber={formatNumber}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )} */}

                {/* {filterContent(['Charts'], false) && (
                    blocks ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <BitcoinBlockIntervalChart
                                uniqueChartId="BitcoinBlockIntervalChart-1"
                                title="Bitcoin Block Intervals"
                                subtitle="Block size, transaction count, and block time intervals"
                                icon={<FontAwesomeIcon icon={faClock} fontSize="20px" />}
                                blocks={blocks}
                                formatNumber={formatNumber}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )} */}

                {/* {filterContent(['Charts'], false) && (
                    blocks ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <BitcoinBlockTimeDistributionChart
                                uniqueChartId="BitcoinBlockTimeDistributionChart-1"
                                title="Block Time Distribution"
                                subtitle="Recent Blocks"
                                icon={<FontAwesomeIcon icon={faClock} fontSize="20px" />}
                                blocks={blocks}
                                formatNumber={formatNumber}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )} */}

                {/* {filterContent(['Charts'], false) && (
                    blocks ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <BitcoinTransactionDensityChart
                                uniqueChartId="BitcoinTransactionDensityChart-1"
                                title="Transaction Density"
                                subtitle="Recent Blocks"
                                icon={<FontAwesomeIcon icon={faClock} fontSize="20px" />}
                                blocks={blocks}
                                formatNumber={formatNumber}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )} */}



                {filterContent(['Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <RewardsToFeesBarTile
                                title="Reward to Fees"
                                subtitle="Fee percentage of reward over the last 144 blocks"
                                icon={<FontAwesomeIcon icon={faBarChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network', 'Mining'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <PoolsTopTenTile
                                uniqueChartId="PoolsTopTenTile-1"
                                title="Mining Pools Top 10"
                                subtitle="Top pools by block count (3y)"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatNumber={formatNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network', 'Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <PoolsTopTenHashPieTile
                                uniqueChartId="PoolsTopTenHashPieTile-1"
                                title="Mining Pools Top 10 Hash"
                                subtitle="Top pools by hashpower"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network', 'Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <PoolsTopTenPieTile
                                uniqueChartId="PoolsTopTenPieTile-1"
                                title="Mining Pools Top 10 Blocks"
                                subtitle="Top pools by block count (3y)"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatNumber={formatNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Mining', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            width="100%"
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <AvgFeesOverTimeTile
                                title="Avg Fees Value (USD)"
                                subtitle="Average value of mining fees over time"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Mining', 'Market'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <HashRateMempoolSizeTile
                                uniqueChartId="HashRateMempoolSizeTile-1"
                                title="Hashrate / Mempool"
                                subtitle="1-hour rolling average"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network', 'Mining'], true) && (
                    <DataTable
                        title="Difficulty Adjustment"
                        subtitle="Stats: Bitcoin mining difficulty"
                        icon={<FontAwesomeIcon icon={faSliders} fontSize="20px" />}
                        rows={difficultyAdjustmentData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Difficulty Adjustment"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={9}
                    />
                )}

                {filterContent(['Network', 'Market'], true) && (
                    <DataTable
                        title="API Providers"
                        subtitle="BTC~USD powered by top APIs"
                        icon={<FontAwesomeIcon icon={faDatabase} fontSize="20px" />}
                        rows={ApiProvidersData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Application Programming Interface (API)"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={9}
                    />
                )}

                {filterContent(['Market'], true) && (
                    data || bitcoinPrice ? (
                        <DataTable
                            title="1 BTC Can Buy..."
                            subtitle="What can you buy with 1 Bitcoin?"
                            icon={<FontAwesomeIcon icon={faBasketShopping} fontSize="20px" />}
                            rows={oneBtcCanBuyData}
                            link=""
                            animate={animateLastBlock}
                            specificTerm="Purchasing Power"
                            onOpenLexicon={handleOpenLexicon}
                            rowsPerPage={9}
                        />
                    ) : <CustomSpinner />
                )}

                {filterContent(['Network', 'Mining', 'Market'], true) && (
                    <DataTable
                        title="Ownership Economics"
                        subtitle="Dividing BTC's total supply by world population"
                        icon={<FontAwesomeIcon icon={faGlobeAsia} fontSize="20px" />}
                        rows={ownershipEconomicsData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="21M Divided By Everything"
                        onOpenLexicon={handleOpenLexicon}
                        rowsPerPage={9}
                    />
                )}

                {filterContent(['Network', 'Market', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <TransactionsTile
                                title="Transactions"
                                subtitle="Historical data delayed"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Mining', 'Market', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <TransactionsAndHashRateTile
                                uniqueChartId="TransactionsAndHashRateTile-1"
                                title="Transactions / Hashrate"
                                subtitle="Logarithmic Scale"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Mining', 'Market', 'Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <HashRatePriceTile
                                title="Price / Hashrate"
                                subtitle="Logarithmic Scale"
                                icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                                uniqueChartId="chart-1"
                            />
                        </Flex>
                    ) : ''
                )}

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 0 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <LightningNodesTile
                                uniqueChartId="LightningNodesTile-1"
                                title="Lightning Nodes"
                                subtitle="Breakdown of Lightning nodes"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <LightningNodesByCountryTile
                                uniqueChartId="LightningNodesByCountryTile-1"
                                title="Lightning by Country"
                                subtitle="Top 12 by node count or capacity"
                                icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

                {filterContent(['Charts'], false) && (
                    data ? (
                        <Flex flex={{ base: '1 0 100%', md: '1 1 48.6%' }}
                            className={animateLastBlock ? 'gray-border' : ''}
                            maxW={'100%'}
                        >
                            <LightningCapacityByCountryTile
                                title="Lightning by Country"
                                subtitle="Top 12 by node count or capacity"
                                icon={<FontAwesomeIcon icon={faTimeline} fontSize="20px" />}
                                data={data}
                                formatLargeNumber={formatLargeNumber}
                            />
                        </Flex>
                    ) : <CustomSpinner />
                )}

            </Flex>

            {filterContent(['Network', 'Market'], false) && (
                data ? (
                    <Flex
                        flexDirection={{ base: 'column', md: 'row' }}
                        flexWrap="wrap"
                        columnGap={{ base: 0, md: 3 }}
                    >
                        <DataTable
                            title="Distribution (BTC)"
                            subtitle="Address grouped by HODL totals in BTC"
                            icon={<FontAwesomeIcon icon={faBtc} fontSize="20px" />}
                            rows={btcAddressData}
                            animate={animateLastBlock}
                            specificTerm=""
                            onOpenLexicon={handleOpenLexicon}
                        />

                        <DataTable
                            title="Distribution (USD)"
                            subtitle="Address grouped by HODL totals in USD"
                            icon={<FontAwesomeIcon icon={faDollar} fontSize="20px" />}
                            rows={usdAddressData}
                            animate={animateLastBlock}
                            specificTerm=""
                            onOpenLexicon={handleOpenLexicon}
                        />

                    </Flex>
                ) : <CustomSpinner />
            )}

            {filterContent(['Resources'], false) && (
                <Text fontSize="xs" mb={2} color="gray.700" >MORE PUBLICATIONS</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Privacy"
                        subtitle="Info about personal privacy"
                        icon={<FontAwesomeIcon icon={faBtc} fontSize="20px" />}
                        rows={privacyResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Privacy"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Security"
                        subtitle="Bitcoin security tips"
                        icon={<FontAwesomeIcon icon={faLock} fontSize="20px" />}
                        rows={securityResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Security"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

            </Flex>

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Money"
                        subtitle="Learn about money"
                        icon={<FontAwesomeIcon icon={faCoins} fontSize="20px" />}
                        rows={moneyResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Money"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Economics"
                        subtitle="Bitcoin & Economics"
                        icon={<FontAwesomeIcon icon={faMoneyBill} fontSize="20px" />}
                        rows={economicsResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Economics"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="History"
                        subtitle="Bitcoin History"
                        icon={<FontAwesomeIcon icon={faHistory} fontSize="20px" />}
                        rows={historyResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Bitcoin History"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Freedom"
                        subtitle="Personal and financial freedom"
                        icon={<FontAwesomeIcon icon={faHandFist} fontSize="20px" />}
                        rows={freedomResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Freedom"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Run a Node"
                        subtitle="Learn how to setup a node"
                        icon={<FontAwesomeIcon icon={faComputer} fontSize="20px" />}
                        rows={nodeResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Node"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Programming"
                        subtitle="About BTC programming"
                        icon={<FontAwesomeIcon icon={faCode} fontSize="20px" />}
                        rows={programmingResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Bitcoin Programming"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Mining"
                        subtitle="Bitcoin mining resources"
                        icon={<FontAwesomeIcon icon={faMicrochip} fontSize="20px" />}
                        rows={miningResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Mining"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="BIPs"
                        subtitle="List of Bitcoin BIPs"
                        icon={<FontAwesomeIcon icon={faCodePullRequest} fontSize="20px" />}
                        rows={bipsResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Bitcoin Improvement Proposal (BIP)"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Education"
                        subtitle="Informative BTC resources"
                        icon={<FontAwesomeIcon icon={faGraduationCap} fontSize="20px" />}
                        rows={educationResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Education"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Trading"
                        subtitle="Bitcoin trading resources"
                        icon={<FontAwesomeIcon icon={faChartLine} fontSize="20px" />}
                        rows={tradingResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Trading"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Investing"
                        subtitle="Learn about investing"
                        icon={<FontAwesomeIcon icon={faMoneyBills} fontSize="20px" />}
                        rows={investingResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Investing"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Legal"
                        subtitle="Bitcoin legal links"
                        icon={<FontAwesomeIcon icon={faLegal} fontSize="20px" />}
                        rows={legalResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Legal"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

            </Flex>

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Bullishness"
                        subtitle="Reasons to be bullish"
                        icon={<FontAwesomeIcon icon={faSmile} fontSize="20px" />}
                        rows={bullishnessResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Bullish"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Resources'], false) && (
                    <DataTable
                        title="Game Theory"
                        subtitle="Bitcoin's game theory"
                        icon={<FontAwesomeIcon icon={faDiceD20} fontSize="20px" />}
                        rows={gameTheoryResourcesData}
                        link=""
                        animate={animateLastBlock}
                        specificTerm="Game Theory"
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

            </Flex>

            {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], false) && (
                <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    flexWrap="wrap"
                    columnGap={{ base: 0, md: 3 }}
                >
                    <Flex flex={{ base: '1 0 100%', md: '1 1 39.25%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <WordTile
                            title="What is Bitcoin?"
                            subtitle="A peer-to-peer electronic cash system"
                            icon={<FontAwesomeIcon icon={faCircleQuestion} fontSize="20px" />}
                            body="Bitcoin is a unique, decentralized, peer-to-peer transaction network featuring a finite, programmable native digital currency. Bitcoin (BTC), the intrinsic asset of the Bitcoin blockchain, operates autonomously, free from central authorities or administrators. The Bitcoin network's stability is achieved through the collaborative efforts of full nodes, miners, and developers. Its foundation lies in a user-established and user-agreed social contract, which is strengthened by the strategic use of game theory and sophisticated cryptography. Bitcoin's design ensures censorship-resistance, permissionless access, and a reliable store of value, making it an innovative and secure form of digital money."
                        />
                    </Flex>
                    <Flex flex={{ base: '1 0 100%', md: '1 0 20%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <ImageTile
                            src="https://timechainstats.com/img/img-11d.png"
                            width="240"
                            height="240"
                            link="https://bitcoincore.org"
                        />
                    </Flex>
                    <Flex flex={{ base: '1 0 100%', md: '1 1 39.25%' }}
                        className={animateLastBlock ? 'gray-border' : ''}
                    >
                        <QuoteTile
                            title="Satoshi Nakamoto Said..."
                            subtitle="Random quote from the creator(s) of Bitcoin"
                            icon={<FontAwesomeIcon icon={faUserSecret} fontSize="20px" />}
                        />
                    </Flex>
                </Flex>
            )}

            {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], false) && (
                <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    flexWrap="wrap"
                    columnGap={{ base: 0, md: 3 }}
                >
                    <DataTable
                        title="What is a Sat Worth?"
                        subtitle="How many USD can a sat buy?"
                        icon={<FontAwesomeIcon icon={faRightLeft} fontSize="20px" />}
                        rows={whatIsASatWorthData}
                        animate={animateLastBlock}
                        specificTerm="Purchasing Power"
                        onOpenLexicon={handleOpenLexicon}
                    />

                    <DataTable
                        title="What is a USD Worth?"
                        subtitle="How much bitcoin can a dollar buy?"
                        icon={<FontAwesomeIcon icon={faDollar} fontSize="20px" />}
                        rows={whatIsAUsdWorthData}
                        animate={animateLastBlock}
                        specificTerm="Purchasing Power"
                        onOpenLexicon={handleOpenLexicon}
                    />

                    <DataTable
                        title="Bitcoin Breakdown"
                        subtitle="Dividing Bitcoin's total supply by group"
                        icon={<FontAwesomeIcon icon={faPieChart} fontSize="20px" />}
                        rows={bitcoinBreakdownData}
                        animate={animateLastBlock}
                        specificTerm="21M Divided By Everything"
                        onOpenLexicon={handleOpenLexicon}
                    />
                </Flex>
            )}


            {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                <Text fontSize="xs" mb={2} color="gray.700" >TOP RESOURCES</Text>
            )}

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="FAQ"
                        subtitle="Frequently asked questions about BTC"
                        icon={<FontAwesomeIcon icon={faQuestionCircle} fontSize="20px" />}
                        rows={faqData}
                        link=""
                        animate={animateLastBlock}
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="Reading"
                        subtitle="Learn more about Bitcoin"
                        icon={<FontAwesomeIcon icon={faBook} fontSize="20px" />}
                        rows={readingData}
                        link=""
                        animate={animateLastBlock}
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="Resources"
                        subtitle="Helpful Bitcoin websites"
                        icon={<FontAwesomeIcon icon={faUpRightFromSquare} fontSize="20px" />}
                        rows={resourcesData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

            </Flex>

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title='"Bitcoin Fixes This"'
                        subtitle="Problems solved by BTC"
                        icon={<FontAwesomeIcon icon={faWrench} fontSize="20px" />}
                        rows={bitcoinFixesThisData}
                        link=""
                        animate={animateLastBlock}
                    />
                )}

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="Wallets"
                        subtitle="How to store your Bitcoin safely"
                        icon={<FontAwesomeIcon icon={faKey} fontSize="20px" />}
                        rows={walletsData}
                        link=""
                        animate={animateLastBlock}
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

                {filterContent(['Network', 'Mining', 'Market', 'Charts', 'Resources'], true) && (
                    <DataTable
                        title="Bitcoin Visualizations"
                        subtitle="Visual representations of BTC data"
                        icon={<FontAwesomeIcon icon={faEye} fontSize="20px" />}
                        rows={visualizationsData}
                        link=""
                        animate={animateLastBlock}
                        onOpenLexicon={handleOpenLexicon}
                    />
                )}

            </Flex>

            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                flexWrap="wrap"
                columnGap={{ base: 0, md: 3 }}
            >
                {filterContent(['Tv'], false) && (
                    <Flex
                        flex={{ base: '1 0 100%', md: '1 0 100%' }}
                    >
                        <TradingViewChart2
                            title="Stocks vs. Bitcoin"
                            subtitle="Stock performance vs BTC on a 1:1 basis"
                            icon={<FontAwesomeIcon icon={faMedal} fontSize="20px" />}
                        />
                    </Flex>
                )}
            </Flex>

            <BtcLexiconModal
                isOpen={isLexiconOpen}
                onClose={() => setIsLexiconOpen(false)}
                glossaryData={glossaryData}
                initialTerm={selectedLexiconTerm}
            />

            <BitcoinDivider color="orange.500" />

            {/* <NotificationManager
                lastMempoolBlockData={lastMempoolBlockData}
                bitcoinPrice={bitcoinPrice}
            /> */}

            {/* <NotificationSystem 
                bitcoinPrice={bitcoinPrice}
                lastMempoolBlockData={lastMempoolBlockData}
                onOpenLexicon={handleOpenLexicon}
            /> */}

        </ContentWrapper>

    )
}
