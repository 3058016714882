// src/App.js

import { useState, useEffect, useCallback, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { useMempoolWebSocket } from './utils/useMempoolWebSocket';
import { useTimechainWebSocket } from './utils/useTimechainWebSocket';
import { useCoinCapWebSocket } from './utils/useCoinCapWebSocket';
import {
  MasterWrap,
  AppHeader,
  AppFooter,
  AppContent,
} from './components/layout';
import { Preloader } from './Preloader';
import ScrollToTopButton from './components/decorations/ScrollToTopButton';
import { useVisibilityChange } from './utils/useVisibilityChange';
import { useRecoilState } from 'recoil';
import { formatNumber } from './utils';

import { recentBlocksState } from './recoilState';
// import Arcade from './components/games/Arcade';
import CustomDashboard from './components/custom/CustomDashboard';

// Use an environment variable to toggle between data sources
const USE_TIMECHAIN = process.env.REACT_APP_USE_TIMECHAIN === 'false';

function App() {
  const [apiStatus, setApiStatus] = useState(null);
  const [data, setData] = useState(null);
  const [bitcoinPrice, setBitcoinPrice] = useState(null);

  const [glossaryData, setGlossaryData] = useState(null);

  // Initialize both websocket hooks
  const { bitcoinPrice: timechainBitcoinPrice, isTimechainActive } = useTimechainWebSocket();
  const { message: coincapMessage } = useCoinCapWebSocket();

  const { message: mempoolMessage, reconnect: reconnectMempoolWebSocket } = useMempoolWebSocket();
  const [lastMempoolBlockData, setLastMempoolBlockData] = useState(null);
  const [conversions, setConversions] = useState(null);
  const [da, setDa] = useState(null);
  const [fees, setFees] = useState(null);
  const [mempoolBlocks, setMempoolBlocks] = useState(null);
  const [blocks, setBlocks] = useState(null);
  const [mempoolInfo, setMempoolInfo] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [vBytesPerSecond, setVBytesPerSecond] = useState(null);

  const [recentBlocks, setRecentBlocks] = useRecoilState(recentBlocksState);

  const [animateLastBlock, setAnimateLastBlock] = useState(false);
  const prevBlockHeightRef = useRef(null);

  useEffect(() => {
    if (lastMempoolBlockData && (!prevBlockHeightRef.current || prevBlockHeightRef.current < lastMempoolBlockData.height)) {
      setAnimateLastBlock(true);

      setTimeout(() => {
        setAnimateLastBlock(false);
      }, 20000); // Reset after animation duration

      prevBlockHeightRef.current = lastMempoolBlockData.height;
    }
  }, [lastMempoolBlockData]);

  useEffect(() => {
    if (USE_TIMECHAIN) {
      setBitcoinPrice(timechainBitcoinPrice);
    } else {
      if (coincapMessage) {
        const myObj = JSON.parse(coincapMessage);
        const num = parseFloat(myObj['bitcoin']);
        if (!isNaN(num)) {
          const btcTicker = num.toFixed(2);
          setBitcoinPrice(btcTicker);
        }
      }
    }
  }, [timechainBitcoinPrice, coincapMessage]);

  useEffect(() => {
    const hasLatestBlock = (blockHeight) => recentBlocks.find((item) => item.height === blockHeight)
    const updateCondition = lastMempoolBlockData && lastMempoolBlockData.height && !hasLatestBlock(lastMempoolBlockData.height)

    if (updateCondition) {
      const blocksWithoutFirst = recentBlocks.slice(1)
      const newRecentBlocks = [
        ...blocksWithoutFirst,
        lastMempoolBlockData,
      ]
      setRecentBlocks(newRecentBlocks);
    }
  }, [lastMempoolBlockData, recentBlocks, setRecentBlocks])

  useEffect(() => {
    if (mempoolMessage) {
      const myObj = JSON.parse(mempoolMessage);
      // console.log("Mempool.Space data:", myObj);
      const {
        conversions,
        da,
        fees,
        mempoolInfo,
        transactions,
        vBytesPerSecond,
        block,
        blocks,
      } = myObj

      if (conversions) setConversions(conversions);
      if (da) setDa(da);
      if (fees) setFees(fees);
      if (myObj['mempool-blocks']) setMempoolBlocks(myObj['mempool-blocks']);

      if (blocks) {
        const latestBlock = blocks[blocks.length - 1];

        setBlocks(blocks);
        setRecentBlocks(blocks);
        setLastMempoolBlockData(latestBlock);

        // console.log("Blocks state:", blocks);
      }
      if (mempoolInfo) setMempoolInfo(mempoolInfo);
      if (transactions) setTransactions(transactions);
      if (vBytesPerSecond) setVBytesPerSecond(vBytesPerSecond);

      if (block) {
        setLastMempoolBlockData(block);
      }
    }
  }, [mempoolMessage, setRecentBlocks]);

  useVisibilityChange(() => {
    if (document.visibilityState === "visible") {
      reconnectMempoolWebSocket();
    }
  });

  useEffect(() => {
    if (bitcoinPrice) {
      const formattedPrice = parseFloat(bitcoinPrice).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      document.title = `${formattedPrice} Live Bitcoin Price`;
    } else {
      document.title = 'TimechainStats.com';
    }
  }, [bitcoinPrice]);

  const fetchDataFromAPI = useCallback((urls) => {
    return new Promise(async (resolve, reject) => {
      try {
        const responsePromises = urls.map((url) => axios.get(url));
        const responses = await Promise.all(responsePromises);

        const combinedData = responses.reduce((acc, response) => {
          return { ...acc, ...response.data };
        }, {});

        resolve(combinedData);
      } catch (error) {
        console.error("Error fetching data from API:", error);
        reject();
      }
    });
  }, []);

  useEffect(() => {
    // console.log('App component mounted');
    const fetchData = async () => {
      const urls = [
        "https://2140data.net/api/ts04/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts05/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts06/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts07/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts08/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts09/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts10/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts11/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts13/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts14/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts15/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/ts16/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s01/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s02/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s03/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s04/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s06/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s07/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s08/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s09/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s11/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s12/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",
        "https://2140data.net/api/s15/latest?api_key=EUL9E54951LU3L5FA65M2D9KZQUH7LL05",


      ];

      try {
        const combinedData = await fetchDataFromAPI(urls);
        setData(combinedData);
        setApiStatus(true);
        // console.log('Data fetched successfully:', combinedData);
      } catch {
        setApiStatus(false);
        console.error('Failed to fetch data');
      }
    };

    const startIntervalAtNextFiveMinuteMark = () => {
      const now = new Date();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const milliseconds = now.getMilliseconds();

      const timeToNextFiveMinuteMark = ((5 - ((minutes - 1) % 5)) * 60 * 1000) - (seconds * 1000) - milliseconds;

      fetchData();

      setTimeout(() => {
        fetchData();
        setInterval(fetchData, 5 * 60 * 1000);
      }, timeToNextFiveMinuteMark);
    };

    startIntervalAtNextFiveMinuteMark();

  }, [fetchDataFromAPI]);

  useEffect(() => {
    return () => {
      // console.log('App component unmounted');
    };
  }, []);

  useEffect(() => {
    const fetchGlossaryData = async () => {
      try {
        const response = await axios.get('https://btclexicon.com/api/v2/terms');
        setGlossaryData(response.data);
        // console.log('Glossary data fetched successfully:', response.data);
      } catch (error) {
        console.error('Error fetching glossary data:', error);
      }
    };

    fetchGlossaryData();
  }, []);

  useEffect(() => {
    // console.log('Data updated:', data);
  }, [data]);

  useEffect(() => {
    // console.log('Glossary data updated:', glossaryData);
  }, [glossaryData]);

  return (
    <Router>
      <>
        <Preloader
          data={data}
          bitcoinPrice={bitcoinPrice}
          lastMempoolBlockData={lastMempoolBlockData}
          fees={fees}
          mempoolInfo={mempoolInfo}
          da={da}
          formatNumber={formatNumber}
        />
        <MasterWrap>
          <AppHeader
            glossaryData={glossaryData}
            setApiStatus={setApiStatus}
            bitcoinPrice={bitcoinPrice}
            data={data}
            lastMempoolBlockData={lastMempoolBlockData}
            formatNumber={formatNumber}
          />
          <Routes>
            <Route path="/" element={
              <AppContent
                glossaryData={glossaryData}
                data={data}
                bitcoinPrice={bitcoinPrice}
                lastMempoolBlockData={lastMempoolBlockData}
                conversions={conversions}
                da={da}
                fees={fees}
                mempoolBlocks={mempoolBlocks}
                blocks={blocks}
                mempoolInfo={mempoolInfo}
                transactions={transactions}
                vBytesPerSecond={vBytesPerSecond}
                isTimechainActive={isTimechainActive}
              />
            } />

            {/* <Route path="/arcade" element={
              <Arcade
                bitcoinPrice={bitcoinPrice}
                lastMempoolBlockData={lastMempoolBlockData}
                glossaryData={glossaryData}
              />
            } /> */}

            <Route
              path="/donate"
              element={
                <AppContent
                  glossaryData={glossaryData}
                  data={data}
                  bitcoinPrice={bitcoinPrice}
                  lastMempoolBlockData={lastMempoolBlockData}
                  conversions={conversions}
                  da={da}
                  fees={fees}
                  mempoolBlocks={mempoolBlocks}
                  blocks={blocks}
                  mempoolInfo={mempoolInfo}
                  transactions={transactions}
                  vBytesPerSecond={vBytesPerSecond}
                  isTimechainActive={isTimechainActive}
                />
              }
            />

            <Route
              path="/custom"
              element={
                <CustomDashboard
                  data={data}
                  bitcoinPrice={bitcoinPrice}
                  lastMempoolBlockData={lastMempoolBlockData}
                  conversions={conversions}
                  da={da}
                  fees={fees}
                  mempoolBlocks={mempoolBlocks}
                  blocks={blocks}
                  mempoolInfo={mempoolInfo}
                  transactions={transactions}
                  vBytesPerSecond={vBytesPerSecond}
                  isTimechainActive={isTimechainActive}
                  animate={animateLastBlock}
                />
              }
            />

          </Routes>
          <ScrollToTopButton />
          <AppFooter
            apiStatus={apiStatus}
            isTimechainActive={isTimechainActive}
          />
        </MasterWrap>
      </>
    </Router>
  );
}

export default App;